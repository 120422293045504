import { useState, useEffect, useContext } from 'react';

import { toast } from 'react-toastify';

import {
  Grid,
  Divider,
  Typography,
  Paper,
  IconButton,
  Grow,
} from '@mui/material';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import api from '../../services/api';
import { AuthContext } from '../../contexts/auth';
import Spinner from '../../components/Spinner';

export default function Notas() {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('cliente/notas', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, []);
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: 'Nota',
      headerName: 'NFe',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data do Pedido ',
    },
    {
      field: 'Emissao',
      headerName: 'Emissão',
      width: 175,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'Chave',
      headerName: 'Chave de Acesso',
      width: 450,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Chave de Acesso da Nota',
    },
    {
      field: 'Total',
      headerName: 'Valor R$',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total da Nota ',
    },
    {
      field: 'XML',
      headerName: 'XML',
      width: 0,
      hide: true,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'XML ',
    },

    {
      field: 'acao',
      headerName: 'Ações',
      width: 150,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes n01_xml={params.row.XML} />
          </div>
        );
      },
    },
  ];

  const rows = lista.map((item, index) => {
    const data = moment(item.Emissao).format('DD/MM/YYYY');
    return {
      id: `${index}`,
      Nota: `${item.nfe}`,
      Chave: `${item.numero_nfe}`,
      Emissao: `${data.toLocaleString('pt-BR', {
        timeZone: 'UTC',
        timeZoneName: 'short',
      })}`,
      XML: `${item.n01_xml}`,
      Total: `${item.valor_produto.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
    };
  });

  const Acoes = (XML, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          onClick={() => getXml(XML.n01_xml)}
        >
          <FileDownloadTwoToneIcon />
        </IconButton>
      </>
    );
  };
  function getXml(xml) {
    var data = new Blob([xml], { type: 'text/xml' });
    var xmlURL = window.URL.createObjectURL(data);
    var tempLink = document.createElement('a');
    tempLink.href = xmlURL;
    tempLink.setAttribute('download', 'nota.xml');
    tempLink.click();
  }
  return (
    <>
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Notas Fiscais</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={9}
                rowsPerPageOptions={[9]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
