import { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../contexts/auth';

export function Roteador({ component: Component, isPrivate, ...rest }) {
  const { signed, user } = useContext(AuthContext);

  if (!signed && isPrivate) {
    return <Redirect to="/login" />;
  }

  if (signed && !isPrivate) {
    if (user.upa_senha == '001') {
      return <Redirect to="/resetpassword" />;
    } else if (user.upa_senha != '001') {
      if (user.upa_tipo == 'CLIENTE') {
        return <Redirect to="/" />;
      } else {
        return <Redirect to="/vendedor/principal" />;
      }
    } else {
      return <Redirect to="/login" />;
    }
  }

  return (
    <Route {...rest} render={({ location }) => <Component {...location} />} />
  );
}
