import { useState, useEffect, useContext } from 'react';

import { toast } from 'react-toastify';

import Spinner from '../../components/Spinner';
import {
  Grid,
  Typography,
  Divider,
  Paper,
  IconButton,
  Button,
  Grow,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { AuthContext } from '../../contexts/auth';

import api from '../../services/api';
import Newpedido from '../../views/cliente/NewPedido';
import Viewpedido from '../../views/cliente/ViewPedido';

export default function Pedidos() {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [openModalNewPedido, setOpenModalNewPedido] = useState(false);
  const [openModalViewPedido, setOpenModalViewPedido] = useState(false);
  const [id, setId] = useState(0);
  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('cliente/pedidorealizados', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, [loading]);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: 'acao',
      headerName: '',
      width: 50,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',

      renderCell: (params) => {
        return (
          <IconButton color="primary" aria-label="Visualizar Pedido ">
            <Acoes Codigo={params.row.Codigo} />
          </IconButton>
        );
      },
    },
    {
      field: 'Codigo',
      headerName: 'Codigo',
      width: 80,
      disableColumnMenu: true,
      sortable: false,
      description: 'Codigo da Operacao ',
    },
    {
      field: 'Pedido',
      headerName: 'Num. Pedido',
      width: 120,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'Emissao',
      headerName: 'Emissao',
      width: 140,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data de Emissao do Pedido ',
    },
    {
      field: 'Entrega',
      headerName: 'Entrega ',
      width: 140,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data de Entrega do Pedido',
    },
    {
      field: 'Status',
      headerName: 'Status ',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Status do Pedido ',
    },
    {
      field: 'Observacao',
      headerName: 'Observacao ',
      width: 350,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Observacao ',
    },
  ];

  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Codigo: `${item.pve_pedido}`,
      Pedido: `${item.pve_pd_cliente}`,
      Emissao: `${item.pve_emissao}`,
      Entrega: `${item.pve_entrega}`,
      Status: `${item.pve_status}`,
      Observacao: `${item.pve_obs}`,
    };
  });

  const Acoes = ({ index, Codigo }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          onClick={() => handleModalViewPedido(Codigo)}
        >
          <PreviewOutlinedIcon />
        </IconButton>
      </>
    );
  };
  function handleModalNewPedido() {
    setLoading(true);
    setId(0);
    setOpenModalNewPedido(true);
    setLoading(false);
  }
  function handleModalNewPedidoFalse() {
    setLoading(true);
    setOpenModalNewPedido(false);
    setId(0);
    setLoading(false);
  }
  function handleModalViewPedido(item) {
    setLoading(true);
    setId(item);
    setOpenModalViewPedido(true);
    setLoading(false);
  }

  function handleModalViewPedidoFalse() {
    setLoading(true);
    setOpenModalViewPedido(false);
    setId(0);
    setLoading(false);
  }

  return (
    <>
      <Newpedido
        open={openModalNewPedido}
        onClose={handleModalNewPedidoFalse}
      />
      <Viewpedido
        open={openModalViewPedido}
        onClose={handleModalViewPedidoFalse}
        id={id}
      />
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Meus Pedidos</Typography>
          </Grid>
          <Divider />
          <Grid xs={12} sm={12} md={12} lg={10} mt={1} mb={1}>
            <Button
              variant="contained"
              size="large"
              fullWidth
              onClick={() => handleModalNewPedido()}
            >
              <AddBoxTwoToneIcon />{' '}
              <Typography variant="p" ml={1}>
                Novo Pedido
              </Typography>
            </Button>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '65vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[8]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
