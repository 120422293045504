import { useState, useEffect, useContext } from "react";

import {
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
  Grow,
} from "@mui/material";

import { toast } from "react-toastify";

import { AuthContext } from "../../contexts/auth";
import api from "../../services/api";
import Spinner from "../../components/Spinner";
import ViewCliente from "../../views/vendedor/ViewCliente";
import ManageSearchTwoToneIcon from "@mui/icons-material/ManageSearchTwoTone";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
export default function Clientes() {
  const [openModalView, setOpenModalView] = useState(false);
  const [id, setId] = useState(0);
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post("vendedor", {
          codigo: user.upa_vendedor,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error("Falha na operação. (" + err + ")");
        });
      setLoading(false);
    }
    loadLista();
  }, [id]);
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: "acao",
      headerName: "",
      width: 100,
      disableClickEventBubbling: true,
      sortable: false,
      align: "center",

      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Acoes id={params.row.Codigo} />
          </div>
        );
      },
    },
    {
      field: "Codigo",
      headerName: "Código",
      width: 80,
      disableColumnMenu: true,
      sortable: false,
      description: "Código do Cliente ",
    },
    {
      field: "RazaoSocial",
      headerName: "Razão Social",
      width: 500,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Numero do Pedido ",
    },
    {
      field: "Endereco",
      headerName: "Endereço",
      width: 650,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Total do Pedido ",
    },
  ];

  function handleModalView(item) {
    setId(item);
    setOpenModalView(true);
    setLoading(false);
  }

  function handleModalViewFalse() {
    setOpenModalView(false);
    setId(0);
  }
  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Codigo: `${item.codigo}`,
      RazaoSocial: `${item.razaoSocial}`,
      Endereco: `${item.endereco}`,
    };
  });
  const Acoes = ({ index, id }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          onClick={() => handleModalView(id)}
        >
          <ManageSearchTwoToneIcon />
        </IconButton>
      </>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <ViewCliente
        open={openModalView}
        onClose={handleModalViewFalse}
        id={id}
      />
      <Grow in>
        <Grid container sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: "center" }}
          >
            <Typography variant="h4">Meus Clientes</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={"center"}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: "flex",
                flexDirection: "column",
                height: "75vh",
              }}
            >
              <DataGrid
                rows={rows}
                density={"compact"}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[8]}
                disableSelectionOnClick
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
