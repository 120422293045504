import { useState, useEffect, useContext } from 'react';

import {
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
  Grow,
  Box,
  Button,
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import Spinner from '../../components/Spinner';

import ManageSearchTwoToneIcon from '@mui/icons-material/ManageSearchTwoTone';
import ViewConsultaPedido from '../../views/vendedor/ViewPedidos';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import { Link } from 'react-router-dom';
import AddTwoTone from '@mui/icons-material/AddTwoTone';

export default function Consultapedido() {
  const [id, setId] = useState(0);
  const [id2, setId2] = useState(0);
  const [openModalView, setOpenModalView] = useState(false);
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  const confirm = useConfirm();
  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('vendedor/consultapedido', {
          codigo: user.upa_vendedor,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, [loading]);
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: 'acao',
      headerName: '',
      width: 100,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes
              id={params.row.Pedido}
              id2={params.row.Codigo}
              vend={user.upa_vendedor}
              std={params.row.Status}
            />
          </div>
        );
      },
    },
    {
      field: 'Codigo',
      headerName: 'Cliente',
      width: 80,
      disableColumnMenu: true,
      sortable: false,
      description: 'Código do Cliente ',
    },

    {
      field: 'Pedido',
      headerName: 'Pedido',
      width: 80,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'RazaoSocial',
      headerName: 'Razão Social',
      width: 400,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'Emissao',
      headerName: 'Emissao',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
    {
      field: 'Entrega',
      headerName: 'Entrega',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
    {
      field: 'Total',
      headerName: 'Total',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Status do Pedido ',
    },
    {
      field: 'OBS',
      headerName: 'Observação',
      width: 450,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
  ];

  function handleModalView(item, item2) {
    setId2(item2);
    setId(item);
    setOpenModalView(true);
    setLoading(false);
  }

  function handleModalViewFalse() {
    setOpenModalView(false);
    setId(0);
    setId2(0);
  }

  function deleteOrder(id, id2, vend, std) {
    if (std == ' Recebido') {
      confirm({
        title: 'Confirme a Operação',
        description: 'Deseja APAGAR o pedido ?',
        cancellationText: 'Cancelar',
        confirmationText: 'Aceitar',
      })
        .then(() => {
          setLoading(true);

          api
            .post('vendedor/pedidoapaga', {
              pedido: id,
              cliente: id2,
              vendedor: vend,
            })
            .then((response) => {
              toast.success('Item apagado com sucesso.');
              setLoading(false);
            })
            .catch((err) => {
              toast.error('Falha na operação. - ' + err);
            });
        })
        .catch(() => {
          /* */
        });
    } else if (std != 'Recebido') {
      confirm({
        title: 'Operação Não Permitida !',
        description: 'A situação do pedido não permite ser apagado',
        cancellationText: false,
        confirmationText: 'Aceitar',
      })
        .then(() => {
          /* */
        })
        .catch(() => {
          /* */
        });
    }
  }
  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Codigo: `${item.pve_cliente}`,
      Pedido: `${item.pve_pedido}`,
      RazaoSocial: `${item.razao_social}`,
      Emissao: `${item.pve_emissao}`,
      Entrega: `${item.pve_entrega}`,
      Total: `${item.pve_total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      Status: ` ${item.pve_status}`,
      OBS: `${item.pve_obs}`,
    };
  });
  const Acoes = ({ index, id, id2, vend, std }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          onClick={() => handleModalView(id, id2)}
        >
          <ManageSearchTwoToneIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="apagar visita"
          onClick={() => deleteOrder(id, id2, vend, std)}
        >
          <DeleteForeverTwoToneIcon />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <ViewConsultaPedido
        open={openModalView}
        onClose={handleModalViewFalse}
        id={id}
        id2={id2}
      />
      <Grow in>
        <Grid mt={10} container sx={{ justifyContent: 'center' }}>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Meus Pedidos</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Button
              component={Link}
              size="large"
              variant="contained"
              to={'/vendedor/pedidos'}
              style={{ margin: '5px' }}
            >
              <AddTwoTone /> Novo Pedido
            </Button>
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={9}
                rowsPerPageOptions={[9]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
