import { useState, createContext, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
export const AuthContext = createContext({});

function AuthProvider({ children }) {
  const [user, setUser] = useState(null);

  const [loadingAuth, setLoadingAuth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    function loadStorage() {
      const storageUser = localStorage.getItem('SistemaUser');

      if (storageUser) {
        setUser(JSON.parse(storageUser));
        setLoading(false);
      }

      setLoading(false);
    }
    loadStorage();
  }, [loading]);

  function storageUser(data) {
    localStorage.setItem('SistemaUser', JSON.stringify(data));
  }

  //Fazendo logout do usuario
  async function signOut() {
    localStorage.removeItem('SistemaUser');
    setUser(null);
    toast.info('Ate mais.');
  }

  //Fazendo login do usuario

  async function signIn(email, password) {
    setLoadingAuth(true);

    const response = await api
      .post('usuario', {
        email: email,
        senha: password,
      })
      .then((response) => {
        if (response.data[0].upa_codigo !== 0) {
          if (response.data[0].nome_cliente !== null) {
            let data = {
              upa_codigo: response.data[0].upa_codigo,
              upa_cliente: response.data[0].upa_cliente,
              upa_vendedor: response.data[0].upa_vendedor,
              upa_senha: response.data[0].upa_senha,
              upa_tipo: response.data[0].upa_tipo,
              upa_nome: response.data[0].nome_cliente,
              upa_email: response.data[0].upa_email,
            };

            setUser(data);
            storageUser(data);
          } else {
            let data = {
              upa_codigo: response.data[0].upa_codigo,
              upa_cliente: response.data[0].upa_cliente,
              upa_vendedor: response.data[0].upa_vendedor,
              upa_senha: response.data[0].upa_senha,
              upa_tipo: response.data[0].upa_tipo,
              upa_nome: response.data[0].nome_vendedor,
              upa_email: response.data[0].upa_email,
            };

            setUser(data);
            storageUser(data);
          }

          setLoadingAuth(false);

          setLoading(false);

          toast.success('Bem vindo ao sistema.');
        } else {
          throw new Error('Usuario ou Senha Inválido');
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Falha ao entrar no sistema ' + err);
        setLoadingAuth(false);
        setLoading(false);
      });
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loadingAuth,
        loading,
        setLoading,
        signOut,
        signIn,
        storageUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
