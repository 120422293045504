import { useState, useEffect, useContext } from 'react';

import {
  Grid,
  Paper,
  Typography,
  IconButton,
  Divider,
  Grow,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';

import moment from 'moment';

import { toast } from 'react-toastify';

import api from '../../services/api';

import { AuthContext } from '../../contexts/auth';

import Spinner from '../../components/Spinner';
import ViewProducao from '../../views/cliente/ViewProducao';

export default function Producao() {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState(0);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('cliente/producao', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: 'acao',
      headerName: '',
      width: 50,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes pedido={params.row.Pedido} />
          </div>
        );
      },
    },
    {
      field: 'Data',
      headerName: 'Data',
      width: 120,
      disableColumnMenu: true,
      sortable: false,
      description: 'Descrição da Referencia do documento ',
    },
    {
      field: 'Pedido',
      headerName: 'Numero do Pedido',
      width: 180,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data de Vencimento do Documento ',
    },
    {
      field: 'Total',
      headerName: 'Total do Pedido ',
      width: 180,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Condição da Venda ',
    },
    {
      field: 'Vendedor',
      headerName: 'Vendedor ',
      width: 450,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Documento ',
    },
    {
      field: 'Status',
      headerName: 'Status ',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Documento ',
    },
  ];

  const rows = lista.map((item, index) => {
    const data = moment(item.emissao).format('DD/MM/YYYY');
    return {
      id: `${index}`,
      Data: `${data.toLocaleString('pt-BR', {
        timeZone: 'UTC',
        timeZoneName: 'short',
      })}`,
      Pedido: `${item.pedido_n}`,
      Total: `${item.total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      Vendedor: `${item.nome_funcionario}`,
      Status: `${(item.status_descricao = 'null'
        ? 'Não Encontrado'
        : item.status_descricao)}`,
    };
  });

  const Acoes = ({ index, pedido }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Visualizar Pedido "
          onClick={() => handleModalView(pedido)}
        >
          <PreviewOutlinedIcon />
        </IconButton>
      </>
    );
  };

  function handleModalView(item) {
    setId(item);
    setOpenModal(true);
    setLoading(false);
  }

  function handleModalViewFalse() {
    setOpenModal(false);
    setId(0);
  }

  return (
    <>
      <ViewProducao open={openModal} onClose={handleModalViewFalse} id={id} />
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Pedidos em Produção</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
