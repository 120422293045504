import React from 'react';
import '../styles/spinner.css';
function Spinner() {
  return (
    <div className="main">
      <div className="loader">Loading...</div>
    </div>
  );
}

export default Spinner;
