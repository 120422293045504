import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';
import api from '../../services/api';
import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Stack,
  CircularProgress,
} from '@mui/material';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import Spinner from '../../components/Spinner';

export default function ViewPedidoFaturado(props) {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const [codigoVenda, setCodigoVenda] = useState(0);
  const [emissao, setEmissao] = useState(0);
  const [observacao, setObservacao] = useState(0);
  const [funcionario, setFuncionario] = useState(0);
  const [vendasTotal, setVendasTotal] = useState(0);
  const [fechadoEm, setFechadoEm] = useState(0);
  const { id } = props;
  const componentPrint = useRef();

  useEffect(() => {
    async function loadLista() {
      if (id) {
        let response = await api
          .post('cliente/buscapedido', {
            codigo: id,
          })
          .then((response) => {
            let dataEmissao = '';
            let dataFechadoEm = '';
            response.data[0].emissao = ''
              ? (dataEmissao = 'Data não Localizada')
              : (dataEmissao = moment(response.data[0].emissao).format(
                  'DD/MM/YYYY'
                ));

            response.data[0].fechado_em = ''
              ? (dataFechadoEm = 'Data não Localizada')
              : (dataFechadoEm = moment(response.data[0].fechado_em).format(
                  'DD/MM/YYYY'
                ));

            setCodigoVenda(response.data[0].codigo_venda);
            setEmissao(dataEmissao);
            setFuncionario(response.data[0].nome_funcionario);
            setObservacao(response.data[0].observacao);
            setVendasTotal(response.data[0].vendas);
            setFechadoEm(dataFechadoEm);
            setLista(response.data);
          })
          .catch((err) => {
            toast.error('Falha na operação. (' + err + ')');
          });
      }
      setLoading(false);
    }
    loadLista();
  }, [id]);

  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });
  async function handSair(e) {
    e.preventDefault();
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Modal
        sx={{ overflow: 'scroll' }}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={props.onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2}
              mb={5}
              ref={componentPrint}
            >
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ textAlign: ' center' }}
              >
                <Typography variant="h3">Dados do Pedido</Typography>
              </Grid>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                {loading ? (
                  <Stack spacing={20} direction="row" size={400}>
                    <CircularProgress color="primary" disableShrink />
                  </Stack>
                ) : (
                  <form onSubmit={handSair}>
                    <Grid
                      sm={12}
                      md={12}
                      lg={12}
                      mt={2}
                      mb={5}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid sm={12} md={3} lg={1} mt={1} mr={2}>
                        <TextField
                          fullWidth
                          label="Pedido"
                          size="small"
                          type="text"
                          value={codigoVenda}
                        />
                      </Grid>
                      <Grid sm={12} md={3} lg={1.5} mt={1} mr={2}>
                        <TextField
                          fullWidth
                          label="Emissao"
                          size="small"
                          type="text"
                          value={emissao}
                        />
                      </Grid>
                      <Grid sm={12} md={3} lg={1.5} mt={1} mr={2}>
                        <TextField
                          fullWidth
                          label="Data Faturamento"
                          size="small"
                          type="text"
                          value={fechadoEm}
                        />
                      </Grid>
                      <Grid sm={12} md={3} lg={1.5} mt={1} mr={0}>
                        <TextField
                          fullWidth
                          label="Valor"
                          size="small"
                          type="text"
                          value={vendasTotal.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        />
                      </Grid>
                      <Grid sm={12} md={12} lg={5.75} mt={1} ml={1.5} mr={1}>
                        <TextField
                          fullWidth
                          label="Vendedor"
                          size="small"
                          type="text"
                          value={funcionario}
                        />
                      </Grid>
                      <Grid sm={12} md={12} lg={12} mt={1} mr={1}>
                        <TextField
                          fullWidth
                          label="Observacao"
                          size="small"
                          type="text"
                          InputLabelProps={{ shrink: true }}
                          value={observacao}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                    <Grid
                      sm={12}
                      md={12}
                      lg={12}
                      mt={2}
                      mb={5}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Caixa</TableCell>
                            <TableCell>Quantidade</TableCell>
                            <TableCell>Venda</TableCell>
                            <TableCell>Largura</TableCell>
                            <TableCell>Comprimento</TableCell>
                            <TableCell>Altura</TableCell>
                            <TableCell>Corte</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lista.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell>{item.descricao}</TableCell>
                                <TableCell>
                                  {item.qtde.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                                <TableCell>
                                  {item.venda_unitario.toLocaleString('pt-BR', {
                                    style: 'currency',
                                    currency: 'BRL',
                                  })}
                                </TableCell>
                                <TableCell>
                                  {item.largura.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                                <TableCell>
                                  {item.comprimento.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                                <TableCell>
                                  {item.altura.toLocaleString('pt-BR', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  })}
                                </TableCell>
                                <TableCell>{item.corte}</TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Grid>
                  </form>
                )}
              </Grid>
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}
