import { useState, useEffect, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import { useReactToPrint } from 'react-to-print';
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';

import api from '../../services/api';
import { AuthContext } from '../../contexts/auth';
import Spinner from '../../components/Spinner';

export default function ViewConsultaPedido(props) {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, id2 } = props;
  const componentPrint = useRef();

  const { user } = useContext(AuthContext);
  useEffect(() => {
    async function loadLista() {
      let response = await api
        .post('cliente/pedidorealizadosnum', {
          pedido: id,
          vendedor: user.upa_vendedor,
          cliente: id2,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        })
        .finally(() => {
          setLoading(false);
        });
    }
    loadLista();
  }, [id]);

  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };

  return (
    <>
      <Modal
        sx={{ overflow: 'scroll' }}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={props.onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid sm={12} md={12} lg={12} mt={2} mb={5} ref={componentPrint}>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ textAlign: ' center' }}
              >
                <Typography variant="h3">Dados do Pedido</Typography>
              </Grid>

              {loading || lista.length == 0 ? (
                <Spinner />
              ) : (
                <>
                  <form>
                    <Grid
                      sm={12}
                      md={12}
                      lg={12}
                      mt={2}
                      mb={5}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                      }}
                    >
                      <Grid sm={12} md={2} lg={12} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Cliente"
                          size="small"
                          type="text"
                          value={lista[0].razao_social}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={1.25} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Código"
                          size="small"
                          type="text"
                          value={lista[0].pve_pedido}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={1.75} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Pedido"
                          size="small"
                          type="text"
                          value={lista[0].pve_pd_cliente}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={1.75} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Emissão"
                          size="small"
                          type="text"
                          value={lista[0].pve_pedido_em}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={1.75} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Entrega"
                          size="small"
                          type="text"
                          value={lista[0].pve_entrega}
                        />
                      </Grid>

                      <Grid sm={12} md={2} lg={3} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Status"
                          size="small"
                          type="text"
                          value={lista[0].pve_status}
                        />
                      </Grid>

                      <Grid sm={12} md={2} lg={2} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Total R$"
                          size="small"
                          type="text"
                          value={lista[0].total_geral.toLocaleString('pt-BR', {
                            minimumFractionDigits: 2,
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        />
                      </Grid>

                      <Grid sm={12} md={2} lg={12} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Observação"
                          size="small"
                          type="text"
                          value={lista[0].pve_obs}
                        />
                      </Grid>
                    </Grid>
                    <Divider />
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell scope="col">Caixa</TableCell>
                          <TableCell scope="col">Quantidade</TableCell>
                          <TableCell scope="col">Venda</TableCell>
                          <TableCell scope="col">Total</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lista.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell data-label="Caixa">
                                {item.pve_descricao}
                              </TableCell>
                              <TableCell data-label="Quantidade">
                                {item.pve_qtde.toLocaleString('pt-BR', {
                                  minimumFractionDigits: 2,
                                })}
                              </TableCell>
                              <TableCell data-label="Venda">
                                {item.pve_unitario.toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </TableCell>
                              <TableCell data-label="Total">
                                {item.pve_total.toLocaleString('pt-BR', {
                                  style: 'currency',
                                  currency: 'BRL',
                                })}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </form>
                </>
              )}
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}
