import { useState, useEffect, useRef } from 'react';

import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  CircularProgress,
} from '@mui/material';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';

import moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';

import api from '../../services/api';
import Spinner from '../../components/Spinner';

export default function NewProducao(props) {
  const componentPrint = useRef();
  const [loading, setLoading] = useState(true);
  const [lista, setLista] = useState([]);
  const [codigoVenda, setCodigoVenda] = useState(0);
  const [emissao, setEmissao] = useState(0);
  const [observacao, setObservacao] = useState(0);
  const [funcionario, setFuncionario] = useState(0);
  const [vendasTotal, setVendasTotal] = useState(0);
  const [fechadoEm, setFechadoEm] = useState(0);
  const { id } = props;

  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });

  useEffect(() => {
    async function loadLista() {
      if (id) {
        const response = await api.post('cliente/buscapedido', {
          codigo: id,
        });
        if (response.data.length === 0) {
          return toast.error('Nenhum pedido encontrado');
        }
        let dataEmissao = '';
        let dataFechadoEm = '';
        response.data[0].emissao = ''
          ? (dataEmissao = 'Data não Localizada')
          : (dataEmissao = moment(response.data[0].emissao).format(
              'DD/MM/YYYY'
            ));

        response.data[0].fechado_em = '0'
          ? (dataFechadoEm = 'Data não Localizada')
          : (dataFechadoEm = moment(response.data[0].fechado_em).format(
              'DD/MM/YYYY'
            ));

        setCodigoVenda(response.data[0].codigo_venda);
        setEmissao(dataEmissao);
        setFuncionario(response.data[0].nome_funcionario);
        setObservacao(response.data[0].observacao);
        setVendasTotal(response.data[0].vendas);
        setFechadoEm(dataFechadoEm);
        setLista(response.data);
      }
      setLoading(false);
    }
    loadLista();
  }, [id]);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <Modal
      sx={{ overflow: 'scroll' }}
      hideBackdrop
      aria-labelledby="Pedido em Producao"
      aria-describedby="Pedido em Producao"
      {...props}
    >
      <Grid sx={style}>
        <Grid mb={3}>
          <IconButton onClick={props.onClose}>
            <CancelSharpIcon color="warning" />
          </IconButton>
          <IconButton onClick={handlePrint}>
            <LocalPrintshopRoundedIcon />
          </IconButton>
        </Grid>
        <Grow in>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={12}
            mt={2}
            mb={5}
            ref={componentPrint}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2}
              mb={5}
              sx={{ textAlign: ' center' }}
            >
              <Typography variant="h3">Dados do Pedido</Typography>
            </Grid>

            <form>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'center',
                }}
              >
                {loading ? (
                  <Stack spacing={20} direction="row" size={400}>
                    <CircularProgress color="primary" disableShrink />
                  </Stack>
                ) : (
                  <>
                    <Grid sm={12} md={2} lg={1} mr={1}>
                      <TextField
                        size="small"
                        label="Pedido"
                        fullWidth
                        type="text"
                        value={codigoVenda}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={(e) => setCodigoVenda(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid sm={12} md={2} lg={2} mr={1}>
                      <TextField
                        size="small"
                        label="Emissao"
                        type="text"
                        value={emissao}
                        fullWidth
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={(e) => setEmissao(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid sm={12} md={2} lg={2} mr={1}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Faturado em"
                        type="text"
                        value={fechadoEm}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={(e) => setFechadoEm(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid sm={12} md={2} lg={2} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Valor"
                        type="text"
                        InputProps={{
                          readOnly: true,
                        }}
                        value={vendasTotal.toLocaleString('pt-BR', {
                          style: 'currency',
                          currency: 'BRL',
                        })}
                        onChange={(e) => setVendasTotal(e.target.value)}
                        margin="normal"
                      />
                    </Grid>

                    <Grid sm={12} md={2} lg={3} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Vendedor"
                        type="text"
                        value={funcionario}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={(e) => setFuncionario(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid sm={12} md={2} lg={10.35} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Observacao"
                        type="text"
                        value={observacao}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          readOnly: true,
                        }}
                        onChange={(e) => setObservacao(e.target.value)}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </form>
            <Divider />
            <Grid
              sx={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Grid sm={3} md={3} lg={10} mt={2} mb={5}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Caixa</TableCell>
                      <TableCell align="center">Quantidade</TableCell>
                      <TableCell align="center">Venda</TableCell>
                      <TableCell align="center">Largura</TableCell>
                      <TableCell align="center">Comprimento</TableCell>
                      <TableCell align="center">Altura</TableCell>
                      <TableCell align="center">Corte</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lista.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{item.descricao}</TableCell>
                          <TableCell align="center">
                            {item.qtde.toLocaleString('pt-BR')}
                          </TableCell>
                          <TableCell align="center">
                            {item.venda_unitario.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell align="center">
                            {item.largura.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell align="center">
                            {item.comprimento.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell align="center">
                            {item.altura.toLocaleString('pt-BR', {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </TableCell>
                          <TableCell align="center">{item.corte}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </Grid>
        </Grow>
      </Grid>
    </Modal>
  );
}
