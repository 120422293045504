import React from 'react';

function Principal() {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '0',
        backgroundColor: 'white',
      }}
    >
      <img
        style={{ borderRadius: '5%', width: '100%', height: '100%' }}
        src="/images/cocito_logo.png"
        alt="C.A Cocito Embalagens  "
      />
    </div>
  );
}

export default Principal;
