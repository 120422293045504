import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { useConfirm } from 'material-ui-confirm';
import { toast } from 'react-toastify';
import api from '../../services/api';
import moment from 'moment';
import {
  Button,
  Paper,
  Grid,
  Divider,
  Typography,
  IconButton,
  Grow,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Spinner from '../../components/Spinner';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import NewVisita from '../../views/cliente/NewVisita';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import PlaylistAddCheckTwoToneIcon from '@mui/icons-material/PlaylistAddCheckTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';

export default function Visitas(props) {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [id, setId] = useState();
  const [openModalViewVisita, setOpenModalViewVisita] = useState(false);

  const confirm = useConfirm();

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('cliente/visitas', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, [loading]);
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const columns = [
    {
      field: 'Data',
      headerName: 'Data',
      width: 125,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data da Visita ',
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data de Emissao do Pedido ',
    },
    {
      field: 'Solicitante',
      headerName: 'Solicitante ',
      width: 250,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data de Entrega do Pedido',
    },

    {
      field: 'Observacao',
      headerName: 'Observacao ',
      width: 450,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Observacao ',
    },
    {
      field: 'acao',
      headerName: '',
      width: 150,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes codigo={params.row.Codigo} />
          </div>
        );
      },
    },
  ];

  const rows = lista.map((item, index) => {
    const dataEmissao = moment(item.visData).format('DD/MM/YYYY');

    return {
      id: `${index}`,
      Codigo: `${item.visCodigo}`,
      Status: `${item.visStatus ? item.visStatus : 'Status Não Definido'}`,
      Data: `${dataEmissao}`,
      Observacao: `${item.visObs}`,
      Solicitante: `${item.visSolicitante}`,
    };
  });

  function handleModalViewVisita() {
    setLoading(true);
    setOpenModalViewVisita(true);
    setLoading(false);
  }

  function handleModalViewVisitaFalse() {
    setLoading(true);
    setOpenModalViewVisita(false);
    setLoading(false);
  }

  function cancelarVisita(codigo) {
    let item = codigo;
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja Cancelar a Visita ?',
      cancellationText: 'Cancelar',
      confirmationText: 'Aceitar',
    })
      .then(() => {
        cancelaVisita(item);
      })
      .catch(() => {
        /* */
      });
  }

  function deletarVisita(codigo) {
    let item = codigo;
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja APAGAR a Visita ?',
      cancellationText: 'Cancelar',
      confirmationText: 'Aceitar',
    })
      .then(() => {
        deletaVisita(item);
      })
      .catch(() => {
        /* */
      });
  }

  function baixarVisita(codigo) {
    let item = codigo;
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja Baixar a Visita ?',
      cancellationText: 'Cancelar',
      confirmationText: 'Aceitar',
    })
      .then(() => {
        baixaVisita(item);
      })
      .catch(() => {
        /* */
      });
  }

  async function deletaVisita(item) {
    const response = await api
      .post('vendedor/visitaapaga', {
        codigo: item,
      })
      .then((response) => {
        toast.success('Item apagado com sucesso.');
        setLoading(true);
      })
      .catch((err) => {
        toast.error('Falha na operação. - ' + err);
      });
  }

  async function cancelaVisita(item) {
    const response = await api
      .post('vendedor/visitacancela', {
        codigo: item,
      })
      .then((response) => {
        toast.success('Visita cancelada com sucesso.');
        setLoading(true);
      })
      .catch((err) => {
        toast.error('Falha na operação. - ' + err);
      });
  }

  async function baixaVisita(item) {
    const response = await api
      .post('vendedor/visitabaixa', {
        codigo: item,
      })
      .then((response) => {
        toast.success('Visita baixada com sucesso.');
        setLoading(true);
      })
      .catch((err) => {
        toast.error('Falha na operação. - ' + err);
      });
  }

  const Acoes = ({ index, codigo }, props) => {
    return (
      <>
        <IconButton
          color="secondary"
          aria-label="cancelar visita"
          onClick={() => cancelarVisita(codigo)}
        >
          <CancelPresentationTwoToneIcon />
        </IconButton>
        <IconButton
          color="sucesso"
          aria-label="baixar visita"
          onClick={() => baixarVisita(codigo)}
        >
          <PlaylistAddCheckTwoToneIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="apagar visita"
          onClick={() => deletarVisita(codigo)}
        >
          <DeleteForeverTwoToneIcon />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <NewVisita
        open={openModalViewVisita}
        onClose={handleModalViewVisitaFalse}
        id={id}
      />
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Minhas Visitas</Typography>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Button
              onClick={handleModalViewVisita}
              fullWidth
              size="large"
              variant="contained"
            >
              {' '}
              <AddTwoToneIcon />{' '}
              <Typography variant="h6"> Nova Visita</Typography>
            </Button>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '65vh',
              }}
            >
              <DataGrid
                density={'compact'}
                rows={rows}
                columns={columns}
                pageSize={7}
                rowsPerPageOptions={[7]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
