import React, { useEffect, useState, useContext } from "react";
import api from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import {
  Grid,
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
} from "@mui/material";
import { Link } from "react-router-dom";
import moment from "moment";
import Spinner from "../../components/Spinner";
import { DataGrid } from "@mui/x-data-grid";

export default function Principal() {
  const [lista, setlista] = useState([]);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadLista() {
      await api
        .post("imagens/lista", {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setlista(response.data);
        })
        .catch((err) => {
          alert("Erro ao buscar dados.");
        });
    }
    loadLista();
    setLoading(false);
  }, [loading]);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: "Codigo",
      headerName: "Codigo",
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      description: "Data do Pedido ",
      hideable: true,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 100,
      disableColumnMenu: true,
      sortable: false,
      description: "Data do Pedido ",
    },
    {
      field: "Data",
      headerName: "Data Inicial",
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Numero do Pedido ",
    },

    {
      field: "Inicial",
      headerName: "Historico Inicial",
      width: 325,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Chave de Acesso da Nota",
    },
    {
      field: "Descricao",
      headerName: "Descrição da Caixa",
      width: 250,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Chave de Acesso da Nota",
    },

    {
      field: "Alteracao",
      headerName: "Data Alteração",
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Chave de Acesso da Nota",
    },
    {
      field: "Historico",
      headerName: "Historico Alteração",
      width: 325,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Chave de Acesso da Nota",
    },
    {
      field: "Usuario",
      headerName: "Usuario",
      width: 250,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Chave de Acesso da Nota",
    },
    {
      field: "acao",
      headerName: "",
      width: 150,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Acoes Status={params.row.Status} imgCodigo={params.row.Codigo} />
          </div>
        );
      },
    },
  ];
  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Codigo: `${item.img_codigo}`,
      Descricao: `${item.img_des_caixa}`,
      Data: `${item.img_data}`,
      Status: `${item.img_status}`,
      Inicial: `${item.img_historico_inicial}`,
      Alteracao: `${item.dt_altera}`,
      Historico: `${item.historico}`,
      Usuario: `${item.img_email}`,
    };
  });

  const Acoes = (params, props) => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          to={`/cliente/imageview/${params.imgCodigo}`}
          component={Link}
          sx={
            params.Status == "APROVADO"
              ? { background: "#009966", width: "155px" }
              : params.Status == "REPROVADO"
              ? { background: "#ff1100", width: "155px" }
              : { background: "#565656", width: "155px" }
          }
        >
          {params.Status}
        </Button>
      </>
    );
  };

  return (
    <Grid
      container
      sx={{ mt: 15, textAlign: "center", justifyContent: "center" }}
    >
      <Grid lg={12}>
        <Typography variant="h3">Meus Layouts</Typography>
      </Grid>

      <Grid
        xs={12}
        sm={12}
        md={12}
        lg={11}
        mt={1}
        mb={1}
        justifyContent={"center"}
      >
        <Paper
          sx={{
            p: 1,
            m: 0,
            display: "flex",
            flexDirection: "column",
            height: "75vh",
          }}
        >
          <DataGrid
            rows={rows}
            density={"compact"}
            columns={columns}
            pageSize={9}
            loading={loading}
            rowsPerPageOptions={[9]}
            disableSelectionOnClick
            columnVisibilityModel={{
              Codigo: false,
              Status: false,
            }}
          />
        </Paper>
      </Grid>
      {/*
      {lista.map((item) => {
        return (
          <>
            <Grid
              display="flex"
              flexDirection="row"
              flexWrap="wrap"
              justifyContent="center"
              lg={10}
            >
              <Grid
                lg={3}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Status do Layout"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgStatus}
                />
              </Grid>
              <Grid
                lg={1.5}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Codigo"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgCodigo}
                />
              </Grid>

              <Grid
                lg={4}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Razão Social"
                  variant="outlined"
                  value={item.imgRazaoSocial}
                  readOnly
                  fullWidth
                />
              </Grid>

              <Grid
                lg={1.5}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Data Inclusão"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgData}
                />
              </Grid>

              <Grid
                lg={4}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Historico Inicial"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgHistoricoInicial}
                />
              </Grid>

              <Grid
                lg={2}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Data da Aprovação"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgDataAprovacao}
                />
              </Grid>

              <Grid
                lg={4}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Historico da Aprovação"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgHistoricoAprovado}
                />
              </Grid>

              <Grid
                lg={4}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Data da Reprovação"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgDataReprovado}
                />
              </Grid>

              <Grid
                lg={4}
                sx={{
                  border: 'solid 5px',
                  background: '#FFFF',
                  color: '#fafafa',
                  padding: '0.3%',
                }}
              >
                <TextField
                  label="Histórico da Reprovação"
                  variant="outlined"
                  fullWidth
                  readOnly
                  value={item.imgHistoricoReprovado}
                />
              </Grid>

              {item.imgStatus !== 'AGUARDANDO' ? (
                <></>
              ) : (
                <Grid
                  lg={2}
                  sx={{
                    border: 'solid 5px',
                    color: '#fafafa',
                    padding: '0.3%',
                  }}
                >
                  <Button
                    component={Link}
                    to={`/cliente/imageview/`}
                    fullWidth
                    sx={{ height: '100%' }}
                    variant="contained"
                  >
                    Aprovar / Reprovar
                  </Button>
                </Grid>
              )}
            </Grid>
          </>
        );
                )}*/}
    </Grid>
  );
}
