import { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import Moment from 'moment';
import 'moment/locale/pt-br';
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Divider,
} from '@mui/material';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import Spinner from '../../components/Spinner';

Moment.locale('pt-br');
export default function NewVisita(props) {
  const ptBR = Moment.locale('pt-br');

  const [visdata, setVisData] = useState();
  const [vissolicitante, setVissolicitante] = useState('');
  const [visobs, setVisobs] = useState('');
  const [viscliente, setViscliente] = useState('');
  const [pessoas, setPessoas] = useState([
    { codigo: -1, razaoSocial: 'Indefinido' },
  ]);

  const [disabled, setDisabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function carregaclientes() {
      await api
        .post('vendedor', {
          codigo: user.upa_vendedor,
        })
        .then((response) => {
          setPessoas(response.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error('Erro ao buscar dados.');
        });
    }
    carregaclientes();
  }, [loading]);

  async function handSalve(e) {
    e.preventDefault();
    setDisabled(true);
    var datanew = Moment(visdata, 'DD/MM/YYYY');
    await api
      .post('vendedor/visita', {
        visCliente: '0',
        visData: datanew,
        visObs: visobs,
        visSolicitante: vissolicitante,
        visStatus: 'Pendente',
        visNome: pessoas[viscliente].razaoSocial,
        visVendedor: user.upa_vendedor,
      })
      .then((response) => {
        toast.success('Agenda salva com sucesso.');
        props.onClose(false);
        setDisabled(false);
      })
      .catch((err) => {
        const { data } = err.response;
        toast.error('Falha na operação (' + data.error + ').');
        setDisabled(false);
      });
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Modal
          sx={{ overflow: 'scroll' }}
          hideBackdrop
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          {...props}
        >
          <Grid sx={style}>
            <Grid mb={3}>
              <IconButton onClick={props.onClose}>
                <CancelSharpIcon color="warning" />
              </IconButton>
            </Grid>
            <Grow in>
              <Grid sm={12} md={12} lg={12} mt={2} mb={5}>
                <Grid
                  sm={12}
                  md={12}
                  lg={12}
                  mt={2}
                  mb={5}
                  sx={{ textAlign: ' center' }}
                >
                  <Typography variant="h3">
                    Cadastrar Novo Agendamento
                  </Typography>
                  <Divider />
                </Grid>

                <>
                  <form onSubmit={handSalve}>
                    <Grid
                      sm={12}
                      md={12}
                      lg={12}
                      mr={1}
                      mt={1}
                      mb={1}
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Grid sm={12} md={12} lg={2.75} mr={1} mt={1} mb={1}>
                        <LocalizationProvider
                          dateAdapter={DateAdapter}
                          locale={ptBR}
                        >
                          <DatePicker
                            label="Data da Visita"
                            value={visdata}
                            onChange={(e) => setVisData(e)}
                            renderInput={(params) => (
                              <TextField
                                required
                                size="small"
                                autoFocus
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                      <Grid sm={12} md={12} lg={9} mr={1} mt={1} mb={1}>
                        <FormControl sx={{ m: 0, width: '18rem' }}>
                          <InputLabel id="selectCliente">
                            Selecione o Cliente
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId="labelSelectCliente"
                            id="selectCliente"
                            value={viscliente}
                            onChange={(e) => setViscliente(e.target.value)}
                            label="Selecione o Cliente"
                            size={'small'}
                            autoFocus
                            required
                          >
                            {pessoas.map((item, index) => {
                              return (
                                <MenuItem key={item.key} value={index}>
                                  {item.razaoSocial}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid sm={12} md={12} lg={12} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Solicitante"
                          size="small"
                          type="text"
                          value={vissolicitante}
                          onChange={(e) => setVissolicitante(e.target.value)}
                        />
                      </Grid>
                      <Grid sm={12} md={12} lg={12} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Observação"
                          size="small"
                          type="text"
                          value={visobs}
                          onChange={(e) => setVisobs(e.target.value)}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={12} mr={1} mt={1} mb={1}></Grid>
                      <Button
                        size="large"
                        variant="contained"
                        fullWidth
                        type="submit"
                        disabled={disabled}
                      >
                        <SaveTwoToneIcon /> Cadastrar
                      </Button>
                    </Grid>
                  </form>
                </>
              </Grid>
            </Grow>
          </Grid>
        </Modal>
      )}
    </>
  );
}
