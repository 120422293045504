import React from 'react';

export default function Boleto(props) {
  return (
    <div>
      <table>
        <tr>
          <th rowSpan="1" colSpan="4">
            LOGO
          </th>
          <th colSpan="1">341</th>
          <th colSpan="8">LINHA DIGITAVEL</th>
        </tr>
        <tr>
          <th colSpan="10">Local de Pagamamento</th>
          <th colSpan="2">Vencimento</th>
        </tr>
        <tr>
          <th colSpan="10"> Beneficiario</th>
          <th colSpan="2">Agencia / Código Beneficiário</th>
        </tr>
        <tr>
          <th colSpan="1">Data Documento</th>
          <th colSpan="1">N.Documento</th>
          <th colSpan="1">Espécie Doc</th>
          <th colSpan="1">Aceite</th>
          <th colSpan="1">Data Processamento</th>
          <th colSpan="1">Nosso Número</th>
        </tr>
        <tr>
          <th colSpan="1">Uso do Banco</th>
          <th colSpan="1">Carteira</th>
          <th colSpan="1">Espécie</th>
          <th colSpan="1">Quantidade</th>
          <th colSpan="1">Valor</th>
          <th colSpan="1">Valor Documento</th>
        </tr>
        <tr rowSpan="2">
          <th colSpan="10">Instruções</th>
          <tr colSpan="1">
            <th colSpan="1">(-) Desconto</th>
            <th colSpan="1">(-) Desconto</th>
          </tr>
        </tr>
      </table>
    </div>
  );
}
