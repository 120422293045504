import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../contexts/auth';

import { toast } from 'react-toastify';
import api from '../../services/api';
import Moment from 'moment';

import { useReactToPrint } from 'react-to-print';
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  CircularProgress,
} from '@mui/material';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import Spinner from '../../components/Spinner';

export default function Consultapedido(props) {
  const componentPrint = useRef();
  const [lista, setLista] = useState([]);

  const [loading, setLoading] = useState(true);
  const { id } = props;

  const [codigo, setCodigo] = useState();
  const [pedido, setPedido] = useState();
  const [datapedido, setDatapedido] = useState();
  const [dataentrega, setDataentrega] = useState();
  const [observacao, setObservacao] = useState();

  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function loadLista() {
      if (id) {
        const response = await api.post('cliente/pedidorealizadosnum', {
          pedido: id,
          vendedor: '0',
          cliente: user.upa_cliente,
        });

        if (response.data.length === 0) {
          return toast.error('Nenhum pedido encontrado');
        }
        setCodigo(response.data[0].pve_pedido);
        setPedido(response.data[0].pve_pd_cliente);
        setDatapedido(response.data[0].pve_pedido_em);
        setDataentrega(response.data[0].pve_entrega);
        setObservacao(response.data[0].pve_obs);
        setLista(response.data);
      }

      setLoading(false);
    }
    loadLista();
  }, [id]);

  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };

  return (
    <>
      <Modal
        sx={{ overflow: 'scroll' }}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={props.onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid sm={12} md={12} lg={12} mt={2} mb={5} ref={componentPrint}>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ textAlign: ' center' }}
              >
                <Typography variant="h3">Dados do Pedido</Typography>
              </Grid>
              <Divider />
              {loading ? (
                <Stack spacing={20} direction="row" size={400}>
                  <CircularProgress color="primary" disableShrink />
                </Stack>
              ) : (
                <form>
                  <Grid
                    sm={12}
                    md={12}
                    lg={12}
                    mt={5}
                    mb={5}
                    sx={{ display: 'flex' }}
                  >
                    <Grid sm={12} md={2} lg={1} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Codigo"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        value={codigo}
                      />
                    </Grid>
                    <Grid sm={12} md={4} lg={1.5} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Pedido N."
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        value={pedido}
                      />
                    </Grid>
                    <Grid sm={12} md={4} lg={1.5} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Data Emissão"
                        InputLabelProps={{ shrink: true }}
                        type="text"
                        value={datapedido}
                      />
                    </Grid>
                    <Grid sm={12} md={4} lg={1.5} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Data Entrega"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        value={dataentrega}
                      />
                    </Grid>
                    <Grid sm={12} md={6} lg={6} mr={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Observação "
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        value={observacao}
                      />
                    </Grid>
                  </Grid>
                  <Divider />

                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Caixa</TableCell>
                        <TableCell>Quantidade</TableCell>
                        <TableCell>Venda</TableCell>
                        <TableCell>Total</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {lista.map((item, index) => {
                        return (
                          <TableRow key={index}>
                            <TableCell>{item.pve_descricao}</TableCell>
                            <TableCell>
                              {item.pve_qtde.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </TableCell>
                            <TableCell>
                              {item.pve_unitario.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item.pve_total.toLocaleString('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </form>
              )}
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}
