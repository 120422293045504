import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Moment from "moment";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
  Grow,
  Box,
  Button,
} from "@mui/material";
import ManageSearchTwoToneIcon from "@mui/icons-material/ManageSearchTwoTone";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { darken, lighten } from "@mui/material/styles";
import Spinner from "../../components/Spinner";
import api from "../../services/api";
import { AuthContext } from "../../contexts/auth";

import { Link } from "react-router-dom";

import {
  AddTwoTone,
  DeleteForeverTwoTone,
  PrintTwoTone,
} from "@material-ui/icons";

import { useConfirm } from "material-ui-confirm";

export default function ListaPedidos() {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const confirm = useConfirm();
  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.8);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.8) : lighten(color, 0.7);

  useEffect(() => {
    async function loadLista() {
      await api
        .post("pedido/telalista", {
          vendedor: user.upa_vendedor,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error("Falha na operação. (" + err + ")");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    loadLista();
  }, [loading]);

  function handleDeleteOrder(id, status) {
    if (status != "Orçamento") {
      confirm({
        title: "Operação NÃO PERMITIDA",
        description: "O Status do Orçamentonao permite alterações apa",
        cancellationText: "Cancelar",
        confirmationText: "Aceitar",
      })
        .then(() => {
          setLoading(true);
          toast.info("Operacão Cancelada");
        })
        .catch(() => {
          toast.info("Operação Cancelada");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      confirm({
        title: "Confirme a Operação",
        description: "Deseja APAGAR o Orçamento ?",
        cancellationText: "Cancelar",
        confirmationText: "Aceitar",
      })
        .then(() => {
          setLoading(true);
          api
            .post("/pedido/telaapaga", { id: id })
            .then(() => toast.success("Orçamento Apagado com sucesso"))
            .catch(() => toast.error("Orçamento não pode ser apagado "));
        })
        .catch(() => {
          toast.info("Operação Cancelada");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const columns = [
    {
      field: "acao",
      headerName: "",
      width: 200,
      disableClickEventBubbling: true,
      sortable: false,
      align: "center",

      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Acoes id={params.row.Pedido} status={params.row.Status} />
          </div>
        );
      },
    },
    {
      field: "Pedido",
      headerName: "Pedido",
      width: 100,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: false,
      description: "Numero do Pedido ",
    },
    {
      field: "Status",
      headerName: "Status",
      width: 100,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: false,
      description: "Status do Pedido ",
    },
    {
      field: "Emissao",
      headerName: "Emissão",
      width: 150,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Data ",
    },
    {
      field: "Vendedor",
      headerName: "Vendedor",
      width: 150,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Vendedor ",
    },
    {
      field: "RazaoSocial",
      headerName: "Razão Social",
      width: 300,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Nome do Cliente ",
    },
    {
      field: "Valor",
      headerName: "Valor",
      width: 150,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Total do Pedido ",
    },
  ];

  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Pedido: `${item.pve_pedido}`,
      Status: `${item.pve_status}`,
      Emissao: `${item.pve_emissao}`,
      RazaoSocial: `${item.pve_clientenome}`,
      Vendedor: `${item.nome_funcionario}`,
      Valor: `${item.pve_total.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}`,
    };
  });
  const Acoes = ({ index, id, status }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          component={Link}
          to={`/vendedor/orcamentonovo/${id}`}
        >
          <ManageSearchTwoToneIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="Imprimir Orçamento"
          component={Link}
          to={`/vendedor/PrintViewPedido/${id}`}
        >
          <PrintTwoTone />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="Apagar Orçamento"
          onClick={() => handleDeleteOrder(id, status)}
        >
          <DeleteForeverTwoTone />
        </IconButton>
      </>
    );
  };
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: "center" }}
          >
            <Typography variant="h4">Consulta Orçamentos</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={"center"}
          >
            <Button
              variant="contained"
              p={5}
              component={Link}
              to="/vendedor/orcamentonovo/0"
            >
              <AddTwoTone /> Novo Orçamento
            </Button>
            <Paper
              sx={{
                p: 1,
                mt: 1,
                display: "flex",
                flexDirection: "column",
                height: "65vh",
              }}
            >
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  "& .super-app-theme--SIM": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.success.main,
                          theme.palette.mode
                        ),
                    },
                  },
                }}
              >
                <DataGrid
                  rows={rows}
                  density={"compact"}
                  columns={columns}
                  pageSize={9}
                  rowsPerPageOptions={[9]}
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.Faturado}`
                  }
                  disableSelectionOnClick
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
