import { useState, useEffect, useContext } from 'react';

import {
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
  Grow,
} from '@mui/material';

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from '@mui/x-data-grid';
import { toast } from 'react-toastify';

import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import Spinner from '../../components/Spinner';
import ViewNewPedido from '../../views/vendedor/ViewNewPedido';

import AddCircleOutlineTwoToneIcon from '@mui/icons-material/AddCircleOutlineTwoTone';

export default function Pedidos() {
  const [id, setId] = useState(0);
  const [razao, setRazaoSocial] = useState('');
  const [openModalView, setOpenModalView] = useState(false);
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('vendedor', {
          codigo: user.upa_vendedor,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, []);
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: 'acao',
      headerName: '',
      width: 50,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <Acoes id={params.row.Codigo} razao={params.row.RazaoSocial} />
          </div>
        );
      },
    },
    {
      field: 'Codigo',
      headerName: 'Código',
      width: 80,
      disableColumnMenu: true,
      sortable: false,
      description: 'Código do Cliente',
    },
    {
      field: 'RazaoSocial',
      headerName: 'Razão Social',
      width: 550,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'Endereco',
      headerName: 'Endereço',
      width: 650,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
    {
      field: 'acao',
      headerName: '',
      width: 50,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: 'pointer',
            }}
          >
            <Acoes id={params.row.Codigo} razao={params.row.RazaoSocial} />
          </div>
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }
  function handleModalView(id, razao) {
    setRazaoSocial(razao);
    setId(id);
    setOpenModalView(true);
    setLoading(false);
  }

  function handleModalViewFalse() {
    setOpenModalView(false);
    setId(0);
  }
  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Codigo: `${item.codigo}`,
      RazaoSocial: `${item.razaoSocial}`,
      Endereco: `${item.endereco}`,
    };
  });
  const Acoes = ({ index, id, razao }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Visualizar o Pedido"
          onClick={() => handleModalView(id, razao)}
        >
          <AddCircleOutlineTwoToneIcon />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <ViewNewPedido
        open={openModalView}
        id={id}
        razao={razao}
        onClose={handleModalViewFalse}
      />
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={0}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Selecione o cliente</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={0}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={8}
                rowsPerPageOptions={[8]}
                disableSelectionOnClick
                components={{
                  Toolbar: CustomToolbar,
                }}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
