import { useState, useEffect, useContext } from 'react';

import {
  Grid,
  Paper,
  Typography,
  Divider,
  Grow,
  Button,
  IconButton,
} from '@mui/material';

import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import Spinner from '../../components/Spinner';
import { DataGrid } from '@mui/x-data-grid';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import PlaylistAddCheckTwoToneIcon from '@mui/icons-material/PlaylistAddCheckTwoTone';
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import NewVisita from '../../views/vendedor/ViewNewVisita';

import Moment from 'moment';

export default function Visitas() {
  const [openModalView, setOpenModalView] = useState(false);
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const confirm = useConfirm();

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('vendedor/visitas', {
          codigo: user.upa_vendedor,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, [loading]);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const columns = [
    {
      field: 'Data',
      headerName: 'Data',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data',
    },
    {
      field: 'Status',
      headerName: 'Status',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Status',
    },
    {
      field: 'Nome',
      headerName: 'Nome',
      width: 250,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Nome',
    },

    {
      field: 'Solicitante',
      headerName: 'Solicitante',
      width: 120,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Solicitante',
    },

    {
      field: 'OBS',
      headerName: 'Observação',
      width: 300,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Observacao',
    },
    {
      field: 'acao',
      headerName: '',
      width: 150,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes codigo={params.row.Codigo} />
          </div>
        );
      },
    },
  ];

  function handleModalView(item) {
    setLoading(true);
    setOpenModalView(true);
    setLoading(false);
  }

  function handleModalViewFalse() {
    setLoading(true);
    setOpenModalView(false);
    setLoading(false);
  }

  function cancelarVisita(codigo) {
    let item = codigo;
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja Cancelar a Visita ?',
      cancellationText: 'Cancelar',
      confirmationText: 'Aceitar',
    })
      .then(() => {
        cancelaVisita(item);
      })
      .catch(() => {
        /* */
      });
  }

  function deletarVisita(codigo) {
    let item = codigo;
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja APAGAR a Visita ?',
      cancellationText: 'Cancelar',
      confirmationText: 'Aceitar',
    })
      .then(() => {
        deletaVisita(item);
      })
      .catch(() => {
        /* */
      });
  }

  function baixarVisita(codigo) {
    let item = codigo;
    confirm({
      title: 'Confirme a Operação',
      description: 'Deseja Baixar a Visita ?',
      cancellationText: 'Cancelar',
      confirmationText: 'Aceitar',
    })
      .then(() => {
        baixaVisita(item);
      })
      .catch(() => {
        /* */
      });
  }

  async function deletaVisita(item) {
    const response = await api
      .post('vendedor/visitaapaga', {
        codigo: item,
      })
      .then((response) => {
        toast.success('Item apagado com sucesso.');
        setLoading(true);
      })
      .catch((err) => {
        toast.error('Falha na operação. - ' + err);
      });
  }

  async function cancelaVisita(item) {
    const response = await api
      .post('vendedor/visitacancela', {
        codigo: item,
      })
      .then((response) => {
        toast.success('Visita cancelada com sucesso.');
        setLoading(true);
      })
      .catch((err) => {
        toast.error('Falha na operação. - ' + err);
      });
  }

  async function baixaVisita(item) {
    const response = await api
      .post('vendedor/visitabaixa', {
        codigo: item,
      })
      .then((response) => {
        toast.success('Visita baixada com sucesso.');
        setLoading(true);
      })
      .catch((err) => {
        toast.error('Falha na operação. - ' + err);
      });
  }

  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,

      Data: `${Moment(item.visData).format('DD/MM/YYYY')}`,
      Status: `${item.visStatus}`,
      Solicitante: `${item.visSolicitante}`,
      Nome: `${item.visNome}`,
      OBS: `${item.visObs}`,
      Codigo: `${item.visCodigo}`,
    };
  });

  const Acoes = ({ index, codigo }, props) => {
    return (
      <>
        <IconButton
          color="secondary"
          aria-label="cancelar visita"
          onClick={() => cancelarVisita(codigo)}
        >
          <CancelPresentationTwoToneIcon />
        </IconButton>
        <IconButton
          color="sucesso"
          aria-label="baixar visita"
          onClick={() => baixarVisita(codigo)}
        >
          <PlaylistAddCheckTwoToneIcon />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="apagar visita"
          onClick={() => deletarVisita(codigo)}
        >
          <DeleteForeverTwoToneIcon />
        </IconButton>
      </>
    );
  };

  return (
    <>
      <NewVisita open={openModalView} onClose={handleModalViewFalse} />
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Visitas Agendadas</Typography>
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            sx={{ textAlign: 'center' }}
          >
            <Button
              variant="contained"
              onClick={handleModalView}
              size="large"
              fullWidth
            >
              <AddTwoToneIcon /> Nova Visita
            </Button>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '65vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={9}
                rowsPerPageOptions={[9]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
