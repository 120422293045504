import { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import Moment from "moment";
import {
  Grid,
  Paper,
  Typography,
  Divider,
  IconButton,
  Grow,
  Box,
} from "@mui/material";
import ManageSearchTwoToneIcon from "@mui/icons-material/ManageSearchTwoTone";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { darken, lighten } from "@mui/material/styles";
import Spinner from "../../components/Spinner";
import api from "../../services/api";
import { AuthContext } from "../../contexts/auth";
import ViewPedidoNaoFaturado from "../../views/vendedor/ViewPedidonaoFaturado";

export default function Consultapedido() {
  const [openModalView, setOpenModalView] = useState(false);
  const [id, setId] = useState(0);
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.8);

  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.8) : lighten(color, 0.7);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post("vendedor/pedidoscliente", {
          codigo: user.upa_vendedor,
        })
        .then((response) => {
          setLista(response.data);
          console.log(response.data);
        })
        .catch((err) => {
          toast.error("Falha na operação. (" + err + ")");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    loadLista();
  }, []);

  const columns = [
    {
      field: "acao",
      headerName: "",
      width: 50,
      disableClickEventBubbling: true,
      sortable: false,
      align: "center",

      renderCell: (params) => {
        return (
          <div style={{ cursor: "pointer" }}>
            <Acoes id={params.row.Pedido} />
          </div>
        );
      },
    },
    {
      field: "Pedido",
      headerName: "Pedido",
      width: 100,
      headerClassName: "super-app-theme--header",
      disableColumnMenu: true,
      sortable: false,
      description: "Código do Cliente ",
    },
    {
      field: "RazaoSocial",
      headerName: "Razão Social",
      width: 600,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Numero do Pedido ",
    },
    {
      field: "Emissao",
      headerName: "Emissão",
      width: 150,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Total do Pedido ",
    },
    {
      field: "Faturado",
      headerName: "Faturado",
      width: 150,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Pedido Faturado Sim ou Nao ",
    },
    {
      field: "Valor",
      headerName: "Valor",
      width: 150,
      headerClassName: "super-app-theme--header",
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: "Total do Pedido ",
    },
  ];

  function handleModalView(item) {
    setId(item);
    setOpenModalView(true);
    setLoading(false);
  }

  function handleModalViewFalse() {
    setOpenModalView(false);
    setId(0);
  }
  const rows = lista.map((item, index) => {
    const emissao = Moment(item.emissao).format("DD/MM/YYYY");
    return {
      id: `${index}`,
      Pedido: `${item.pedido_n}`,
      RazaoSocial: `${item.razao_social}`,
      Emissao: `${emissao}`,
      Faturado: `${item.faturado}`,
      Valor: `${item.total.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })}`,
    };
  });
  const Acoes = ({ index, id }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          onClick={() => handleModalView(id)}
        >
          <ManageSearchTwoToneIcon />
        </IconButton>
      </>
    );
  };
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <>
      <ViewPedidoNaoFaturado
        open={openModalView}
        onClose={handleModalViewFalse}
        id={id}
      />
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: "center" }}
          >
            <Typography variant="h4">Consulta Pedidos</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={"center"}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: "flex",
                flexDirection: "column",
                height: "75vh",
              }}
            >
              <Box
                sx={{
                  height: 400,
                  width: "100%",
                  "& .super-app-theme--SIM": {
                    bgcolor: (theme) =>
                      getBackgroundColor(
                        theme.palette.success.main,
                        theme.palette.mode
                      ),
                    "&:hover": {
                      bgcolor: (theme) =>
                        getHoverBackgroundColor(
                          theme.palette.success.main,
                          theme.palette.mode
                        ),
                    },
                  },
                }}
              >
                <DataGrid
                  rows={rows}
                  density={"compact"}
                  columns={columns}
                  pageSize={9}
                  rowsPerPageOptions={[9]}
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.Faturado}`
                  }
                  disableSelectionOnClick
                  components={{ Toolbar: GridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                    },
                  }}
                />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
