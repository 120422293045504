import { useState, useEffect, useContext } from 'react';

import { Grid, Typography, Divider, Paper, Grow } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import { toast } from 'react-toastify';

import { AuthContext } from '../../contexts/auth';

import api from '../../services/api';
import Spinner from '../../components/Spinner';

export default function Caixas() {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('cliente/caixas', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: 'Grupo',
      headerName: 'Grupo',
      width: 350,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data do Pedido ',
    },
    {
      field: 'Descricao',
      headerName: 'Descrição',
      width: 500,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'Venda',
      headerName: 'Preço',
      width: 100,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
  ];

  const rows = lista.map((item, index) => {
    return {
      id: `${index}`,
      Grupo: `${item.grupo_produto}`,
      Descricao: `${item.descricao}`,
      Venda: `${item.venda1.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      Vendedor: `${item.nome_funcionario}`,
    };
  });

  return (
    <>
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={10}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Minhas Caixas</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={10}
            lg={10}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={7}
                rowsPerPageOptions={[7]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
