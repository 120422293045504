import React from "react";
import { useState, useEffect } from "react";
import api from "../../services/api";
import Moment from "moment";
import {
  TextField,
  Divider,
  Grid,
  Typography,
  Modal,
  Box,
} from "@mui/material";

import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";

import Spinner from "../../components/Spinner";
import { toast } from "react-toastify";

function ImageViewVendas(props) {
  const moment = Moment;
  Moment.locale("pt-br");

  const [data, setData] = useState();
  const [status, setStatus] = useState();
  const [historico, setHistorico] = useState();
  const [codigo, setCodigo] = useState();
  const [loadingImage, setLoadingImage] = useState(true);

  const [imageBlob, setImageBlob] = useState();

  const { id, cxs, openModalImage, closeModalImage } = props;
  useEffect(() => {
    console.log("2." + id + " - " + cxs);
    if (id && cxs) {
      async function handBuscar() {
        await api
          .post("/imagens/imagemproduto", {
            cliente: id,
            caixa: cxs,
          })
          .then((response) => {
            if (response.data.length == 0) {
              toast.warning("Layout nao disponivel para visualizacao ");
            } else {
              setCodigo(response.data[1][0].img_codigo);
              setData(moment().format());
              setStatus(response.data[1][0].img_status);
              if (response.data[1][0].img_status === "APROVADO") {
                setHistorico(response.data[1][0].img_historico_aprovado);
              } else if (response.data[1][0].img_status === "REPROVADO") {
                setHistorico(response.data[1][0].img_historico_reprovado);
              } else {
                setHistorico(response.data[1][0].img_historico_inicial);
              }

              setImageBlob(response.data[0]);
            }
            setLoadingImage(false);
          })

          .catch((err) => {
            toast.error("Erro ao Buscar Dados " + err);
            setLoadingImage(false);
          });
      }

      handBuscar();
    } else {
      toast.warning("Nao existe dados a serem mostrados");
    }
  }, [cxs]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
  return (
    <>
      <Modal
        hideBackdrop
        open={openModalImage}
        onClose={closeModalImage}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        disablePortal
      >
        <Box sx={{ ...style, width: "80%" }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",

              mt: 15,
            }}
            container
            spacing={1}
          >
            {loadingImage ? (
              <Spinner />
            ) : codigo ? (
              <>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={7}
                  id="container"
                  container
                  item
                  spacing={1}
                >
                  <InnerImageZoom
                    zoomScale="2"
                    zoomType="click"
                    width="100%"
                    height="100%"
                    src={`data:image/jpeg;base64,${imageBlob}`}
                  />
                </Grid>
                <Grid xs={12} sm={12} md={12} lg={4} container item spacing={1}>
                  <Typography variant="h4"> Dados do Layout</Typography>
                  <Divider />
                  <Grid lg={12} item spacing={1}>
                    <TextField
                      label="Data"
                      type="text"
                      fullWidth
                      variant="outlined"
                      value={moment(data).format("DD/MM/YYYY")}
                      sx={{ background: "#FFFF", padding: "1%", margin: " 1%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid lg={12} item spacing={1}>
                    <TextField
                      label="Status"
                      variant="outlined"
                      type="text"
                      fullWidth
                      readOnly
                      value={status}
                      sx={{ background: "#FFFF", padding: "1%", margin: " 1%" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid lg={12} item spacing={1}>
                    <TextField
                      sx={{
                        backgroundColor: "#FFFF",
                        padding: "1%",
                        margin: " 1%",
                      }}
                      label="Historico"
                      type="text"
                      fullWidth
                      multiline
                      rows={5}
                      readOnly
                      variant="outlined"
                      value={historico}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <button
                      style={{
                        width: "40%",
                        height: "70px",
                        borderRadius: "15px",
                        border: "none",
                        background: "#009966",
                        color: "#fafafa",
                        fontSize: "1.5rem",
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      type="button"
                      onClick={closeModalImage}
                    >
                      Voltar
                    </button>
                  </div>
                </Grid>
              </>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h1> Layout não encontrado </h1>
                <button
                  style={{
                    width: "40%",
                    height: "70px",
                    borderRadius: "15px",
                    border: "none",
                    background: "#009966",
                    color: "#fafafa",
                    fontSize: "1.5rem",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  type="button"
                  onClick={closeModalImage}
                >
                  Voltar
                </button>
              </div>
            )}
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default ImageViewVendas;
