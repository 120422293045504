import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useReactToPrint } from "react-to-print";
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";

import api from "../../services/api";
import Spinner from "../../components/Spinner";
import ImageViewVendas from "./ImageViewVendas";

export default function Caixas(props) {
  const componentPrint = useRef();

  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalImage, setOpenModalImage] = useState(false);
  const [cxs, setCxs] = useState();

  function handleCloseModalImage() {
    setCxs("");
    setOpenModalImage(!openModalImage);
  }
  const { id } = props;

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post("vendedor/caixas", {
          codigo: id,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error("Falha na operação. (" + err + ")");
        });
      setLoading(false);
    }
    loadLista();
  }, [id]);

  function functionVerificaCaixa(cxs) {
    console.log("2." + id + " - " + cxs);
    setCxs(cxs);
    setOpenModalImage(true);
  }
  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "85vw",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    height: "90vh",
    p: 2,
    zIndex: 11111,
  };

  return (
    <>
      <Modal
        sx={{ overflow: "scroll" }}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disablePortal
        {...props}
      >
        <Grid sx={style}>
          {cxs ? (
            <ImageViewVendas
              openModalImage={openModalImage}
              closeModalImage={handleCloseModalImage}
              id={id}
              cxs={cxs}
            />
          ) : (
            <></>
          )}
          <Grid mb={3}>
            <IconButton onClick={props.onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid sm={12} md={12} lg={12} mt={2} mb={5} ref={componentPrint}>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ textAlign: " center" }}
              >
                <Typography variant="h3">Dados do Cliente</Typography>
              </Grid>

              {loading || lista.length == 0 ? (
                <Spinner />
              ) : (
                <>
                  <Grid
                    sm={12}
                    md={12}
                    lg={12}
                    mt={2}
                    mb={5}
                    sx={{ display: "flex", flexWrap: "wrap" }}
                  >
                    <Grid sm={12} md={12} lg={12} mr={1} mt={1} mb={1}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Cliente"
                        type="text"
                        value={lista[0].razao_social}
                      />
                    </Grid>
                    <Grid sm={12} md={1} lg={4} mr={1} mt={1} mb={1}>
                      <TextField
                        size="small"
                        fullWidth
                        label="Endereço"
                        type="text"
                        value={lista[0].endereco}
                      />
                    </Grid>
                    <Grid sm={12} md={1} lg={1} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Número"
                        type="text"
                        value={lista[0].numero}
                      />
                    </Grid>
                    <Grid sm={12} md={3} lg={3} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Bairro"
                        type="text"
                        value={lista[0].bairro}
                      />
                    </Grid>
                    <Grid sm={12} md={3} lg={3.65} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Cidade"
                        type="text"
                        value={lista[0].cidade}
                      />
                    </Grid>
                    <Grid sm={12} md={1} lg={1} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Estado"
                        type="text"
                        value={lista[0].estado}
                      />
                    </Grid>
                    <Grid sm={12} md={4} lg={4} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Contato"
                        type="text"
                        value={lista[0].contato}
                      />
                    </Grid>
                    <Grid sm={12} md={2} lg={2.5} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Telefone"
                        type="text"
                        value={lista[0].telefone}
                      />
                    </Grid>
                    <Grid sm={12} md={2} lg={2} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="Inscrição Estadual"
                        type="text"
                        value={lista[0].ins_estadual}
                      />
                    </Grid>
                    <Grid sm={12} md={2} lg={2} mr={1} mt={1} mb={1}>
                      <TextField
                        fullWidth
                        size="small"
                        label="CNPJ"
                        type="text"
                        value={lista[0].cnpj_cpf}
                      />
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid sm={12} md={12} lg={12} mr={1} mt={1}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography variant="h6">Descricao</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">Comprimento</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">Largura</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">Altura</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">Tipo</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">Venda</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="h6">Layout</Typography>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {lista.map((item, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell>{item.grupo_produto}</TableCell>
                              <TableCell>{item.comprimento}</TableCell>
                              <TableCell>{item.largura}</TableCell>
                              <TableCell>{item.altura}</TableCell>
                              <TableCell>{item.obs}</TableCell>

                              <TableCell>
                                {item.venda1.toLocaleString("pt-BR", {
                                  style: "currency",
                                  currency: "BRL",
                                })}
                              </TableCell>
                              <TableCell>
                                <Button
                                  onClick={() =>
                                    functionVerificaCaixa(item.codigo_sequencia)
                                  }
                                >
                                  Visualizar
                                </Button>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </Grid>
                </>
              )}
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}
