import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  Stack,
  CircularProgress,
  Typography,
  Modal,
  Grid,
  IconButton,
  Grow,
  TextField,
  Button,
} from '@mui/material';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import { useReactToPrint } from 'react-to-print';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Moment from 'moment';

Moment.locale('pt-br');

function NewVisita(props) {
  const ptBR = Moment.locale('pt-br');
  const componentPrint = useRef();
  let dataHoje = Moment().add(1, 'days');
  let codigo = 0;
  const { user } = useContext(AuthContext);
  const [viscodigo, setVisCodigo] = useState(1);
  const [visdata, setVisData] = useState(dataHoje);
  const [vissolicitante, setVissolicitante] = useState('');
  const [visobs, setVisobs] = useState('');
  const [visvendedor, setVisvendedor] = useState('0');
  const { id } = props;
  const [loading, setLoading] = useState(true);
  const { onClose } = props;
  useEffect(() => {
    async function loadVisita() {
      if (id) {
        const response = await api.get(`cliente/newvisita/${id}`);
        if (response.data.length === 0) {
          return;
        }

        let dataHoje = Moment().add(7, 'days');
        setVisCodigo(response.data.visCliente);
        setVisData(dataHoje);

        setVissolicitante(response.data.visSolicitante);
        setVisvendedor(response.data.visVendedor);
        setLoading(false);
      }
      setLoading(false);
    }
    loadVisita();
  }, [id]);

  async function handSalve(e) {
    e.preventDefault();
    var datanew = moment(visdata, 'DD/MM/YYYY');
    var datainicio2 = datanew.format('YYYY-MM-DD');
    if (codigo === 0) {
      await api
        .post('cliente/visita', {
          visCliente: user.upa_cliente,
          visData: datanew,
          visSolicitante: vissolicitante,
          visObs: visobs,
          visStatus: 'Pendente',
          visVendedor: '0',
        })
        .then((response) => {
          toast.success('Agenda salva com sucesso.');
          onClose(true);
        })
        .catch((err) => {
          const { data } = err.response;

          toast.error('Falha na operação (' + data.error + ').');
        });
    } else {
      await api
        .put('cliente/visita', {
          visCodigo: '1',
          visCliente: user.upa_cliente,
          visData: datanew,
          visSolicitante: vissolicitante,
          visObs: visobs,
          visNome: '',
          visStatus: 'ANALISE',
          visVendedor: '0',
        })
        .then((response) => {
          toast.success('Agenda alterada com sucesso.');
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error('Falha na operação (' + data.error + ').');
        });
    }
  }
  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });
  if (loading) {
    return (
      <div className="filme-info">
        <h1>Carregando Agenda ...</h1>
      </div>
    );
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '85vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };
  return (
    <>
      <Modal
        sx={{ overflow: 'scroll' }}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid sm={12} md={12} lg={12} mt={2} mb={5} ref={componentPrint}>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ textAlign: ' center' }}
              >
                <Typography variant="h4">Solicitar Visita</Typography>
              </Grid>

              {loading ? (
                <Stack spacing={20} direction="row" size={400}>
                  <CircularProgress color="primary" disableShrink />
                </Stack>
              ) : (
                <form onSubmit={handSalve}>
                  <Grid
                    sm={12}
                    md={12}
                    lg={12}
                    mt={2}
                    mb={5}
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Grid sm={12} md={12} lg={3} mt={1} mr={1}>
                      <LocalizationProvider
                        dateAdapter={DateAdapter}
                        locale={ptBR}
                      >
                        <DatePicker
                          label="Data da Visita"
                          value={visdata}
                          onChange={(e) => setVisData(e)}
                          renderInput={(params) => (
                            <TextField size="small" fullWidth {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid sm={12} md={12} lg={8.75} mt={1} mr={1}>
                      <TextField
                        fullWidth
                        label="Solicitante"
                        size="small"
                        type="text"
                        value={vissolicitante}
                        onChange={(e) => setVissolicitante(e.target.value)}
                      />
                    </Grid>
                    <Grid sm={12} md={12} lg={12} mt={1} mr={1}>
                      <TextField
                        fullWidth
                        label="Observação"
                        size="small"
                        type="text"
                        value={visobs}
                        onChange={(e) => setVisobs(e.target.value)}
                      />
                    </Grid>

                    <Grid sm={12} md={12} lg={12} mt={5} mr={0}>
                      <Button fullWidth variant="contained" type="submit">
                        {codigo == 0 ? (
                          <>
                            <SaveTwoToneIcon />
                            <Typography variant="h6" fullWidth>
                              Cadastrar
                            </Typography>
                          </>
                        ) : (
                          'Alterar'
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}

export default NewVisita;
