import React from 'react';
import { useState, useEffect } from 'react';
import api from '../../services/api';
import ReactDOM from 'react-dom';
import Moment from 'moment';
import {
  TextField,
  Divider,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';

import ModalReject from '../../components/ModalReject/ModalReject';
import ModalConfirm from '../../components/ModalConfirm/ModalConfirm';
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import { useParams } from 'react-router-dom';
import Spinner from '../../components/Spinner';
import { toast } from 'react-toastify';

function ImageView() {
  const moment = Moment;
  Moment.locale('pt-br');

  const [data, setData] = useState();
  const [status, setStatus] = useState();
  const [historico, setHistorico] = useState();
  const [codigo, setCodigo] = useState();

  const [modalRejectOpen, setModalRejectOpen] = useState(false);
  const [modalConfirmOpen, setModalConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  let { id } = useParams();

  useEffect(() => {
    async function handBuscar() {
      await api
        .post('imagens', {
          codigo: id,
        })
        .then((response) => {
          setCodigo(response.data[1][0].img_codigo);
          setData(moment().format());
          setStatus(response.data[1][0].img_status);
          if (response.data[1][0].img_status === 'APROVADO') {
            setHistorico(response.data[1][0].img_historico_aprovado);
          } else if (response.data[1][0].img_status === 'REPROVADO') {
            setHistorico(response.data[1][0].img_historico_reprovado);
          } else {
            setHistorico(response.data[1][0].img_historico_inicial);
          }

          const data = response.data[0];

          const Imagem = ({ data }) => (
            <InnerImageZoom
              zoomScale="2"
              zoomType="click"
              width="100%"
              height="100%"
              src={`data:image/jpeg;base64,${data}`}
            />
          );
          ReactDOM.render(
            <Imagem data={data} />,
            document.getElementById('container')
          );
        })

        .catch((err) => {
          toast.error('Erro ao Buscar Dados ' + err);
        });
    }

    handBuscar();

    setLoading(false);
  }, [loading]);

  if (loading) {
    return <Spinner />;
  }

  function btnaprova() {
    setLoading(true);
    setModalConfirmOpen(true);
    setLoading(false);
  }

  function btnreprova() {
    setLoading(true);
    setModalRejectOpen(true);
    setLoading(false);
  }
  function modalRejectClose() {
    setLoading(true);
    setModalRejectOpen(false);
    setLoading(false);
  }
  function modalConfirmClose() {
    setLoading(true);
    setModalConfirmOpen(false);
    setLoading(false);
  }

  return (
    <>
      <ModalConfirm
        open={modalConfirmOpen}
        codigo={codigo}
        historico={historico}
        onClose={modalConfirmClose}
      />
      <ModalReject
        open={modalRejectOpen}
        codigo={codigo}
        historico={historico}
        onClose={modalRejectClose}
      />
      <Grid
        sx={{
          display: 'flex',
          justifyContent: 'center',

          mt: 15,
        }}
        container
        spacing={1}
      >
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={7}
          id="container"
          container
          item
          spacing={1}
        >
          <CircularProgress />
          <Typography variant="h5">Carregando Imagem...</Typography>
        </Grid>
        <Grid xs={12} sm={12} md={12} lg={4} container item spacing={1}>
          <Typography variant="h4"> Dados do Layout</Typography>
          <Divider />
          <Grid lg={12} item spacing={1}>
            <TextField
              label="Data"
              type="text"
              fullWidth
              variant="outlined"
              value={moment(data).format('DD/MM/YYYY')}
              sx={{ background: '#FFFF', padding: '1%', margin: ' 1%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid lg={12} item spacing={1}>
            <TextField
              label="Status"
              variant="outlined"
              type="text"
              fullWidth
              readOnly
              value={status}
              sx={{ background: '#FFFF', padding: '1%', margin: ' 1%' }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid lg={12} item spacing={1}>
            <TextField
              sx={{ backgroundColor: '#FFFF', padding: '1%', margin: ' 1%' }}
              label="Historico"
              type="text"
              fullWidth
              multiline
              rows={5}
              readOnly
              variant="outlined"
              value={historico}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          {status !== 'AGUARDANDO' ? (
            <></>
          ) : (
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <button
                  style={{
                    width: '40%',
                    height: '70px',
                    borderRadius: '15px',
                    border: 'none',
                    background: '#009966',
                    color: '#fafafa',
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                  type="button"
                  onClick={btnaprova}
                >
                  APROVA
                </button>
                <button
                  style={{
                    width: '40%',
                    height: '70px',
                    borderRadius: '15px',
                    border: 'none',
                    background: '#ff1100',
                    color: '#fafafa',
                    fontSize: '1.5rem',
                    fontWeight: '600',
                    cursor: 'pointer',
                  }}
                  type="button"
                  onClick={btnreprova}
                >
                  REPROVA
                </button>
              </div>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default ImageView;
