import { useState, useContext } from 'react';
import {
  Paper,
  Grid,
  TextField,
  Button,
  Typography,
  Link,
  Box,
  Avatar,
} from '@mui/material';
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { AuthContext } from '../contexts/auth';
import Spinner from '../components/Spinner';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [nomeBotao, setNomeBotao] = useState('Entrar');
  const { signIn } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  function handleEmailChange(e) {
    if (e.target.value) {
      setLoginError(false);
      setEmail(e.target.value);
    } else {
      setLoginError(true);
    }
  }

  function handlePasswordChange(e) {
    if (e.target.value) {
      setPasswordError(false);
      setPassword(e.target.value);
    } else {
      setPasswordError(true);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if (email && password) {
      await signIn(email, password);
      setLoading(false);
    } else {
      setLoginError(true);
      setPasswordError(true);
      setLoading(false);
    }
  }

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Grid
            container
            component="main"
            sx={{
              height: '97vh',

              margin: 0,
            }}
          >
            <Grid
              item
              xs={false}
              sm={4}
              md={7}
              sx={{
                backgroundImage: 'url(/images/restricted_area.png)',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={8}
              md={5}
              component={Paper}
              elevation={6}
              square
            >
              <Box
                sx={{
                  my: 8,
                  mx: 4,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'main.blue' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Entrar no sistema
                </Typography>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleSubmit}
                  sx={{ mt: 1 }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    type="email"
                    id="login"
                    onChange={handleEmailChange}
                    label="Digite  o seu Login"
                    name="login"
                    autoComplete="login"
                    autoFocus
                    error={loginError}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="senha"
                    onChange={handlePasswordChange}
                    label="Digite sua Senha"
                    type="password"
                    id="senha"
                    error={passwordError}
                    autoComplete="senha"
                  />

                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    sx={{ mt: 3, mb: 2 }}
                  >
                    <LockTwoToneIcon />
                    {nomeBotao}
                  </Button>
                  <Grid container>
                    <Grid item xs>
                      <Link href="#" variant="body2"></Link>
                    </Grid>
                    <Grid item>
                      <Link href="#" variant="body2"></Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}

export default Login;
