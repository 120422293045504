function calculoAreaCaixa(texto) {
    var { formula, variaveis, valores } = texto;
    variaveis = variaveis.map((e, i) => {
      formula = formula.replace(`${e}`, `${valores[i]}`);
    });

    let resultado = `${eval(`${formula}`)}`;

    if (resultado == "undefined") {
      return parseInt("0");
    } else {
      return parseInt(resultado);
    }
}

function calculoCorte(lista,caixaComprimento,caixaLargura,caixaAltura) {
    let modelCaixa = lista;
    const numRiscador = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    const numImpressor = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let somaRiscador = 0;
    let somaImpressor = 0;
    let nomeVariavel = ["COMP", "LARG", "ALT"];
    let nomeValores = [
    parseInt(caixaComprimento),
    parseInt(caixaLargura),
    parseInt(caixaAltura),
    ];

    for (let i = 0; i < numRiscador.length; i++) {
        const numero = numRiscador[i];
        const formula = modelCaixa[`mca_riscador${numero}`];
        somaRiscador += calculoAreaCaixa({
            variaveis: nomeVariavel,
            valores: nomeValores,
            formula: formula,
        });
    }

    for (let i = 0; i < numImpressor.length; i++) {
        const numero = numImpressor[i];
        const formula = modelCaixa[`mca_impresso${numero}`];
        somaImpressor += calculoAreaCaixa({
            variaveis: nomeVariavel,
            valores: nomeValores,
            formula: formula,
        });
    }

    return { comprimento: somaImpressor, largura: somaRiscador };
}

function calculoValor(comprimento,largura,gramatura,valor,coeficiente) {
    let calcValor = ((parseFloat(comprimento) / 1000) *
                    (parseFloat(largura) / 1000) *
                    (parseFloat(gramatura) / 1000)) * valor * coeficiente;
    return { valor: calcValor };                    
}

function calculoPeso(comprimento,largura,gramatura) {
    let calcPeso = ((parseFloat(comprimento) / 1000) *
                    (parseFloat(largura) / 1000) *
                    (parseFloat(gramatura) / 1000));
    return { peso: calcPeso };                    
}


export { calculoCorte,calculoValor,calculoPeso };