import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';
import api from '../../services/api';

import Spinner from '../../components/Spinner';
import {
  Grid,
  Typography,
  Paper,
  Divider,
  IconButton,
  Grow,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Moment from 'moment';
import ViewPedidoFaturado from '../../views/cliente/ViewPedidoFaturado';

import PreviewTwoToneIcon from '@mui/icons-material/PreviewTwoTone';
import Relogio from '../../components/Relogio';
export default function Ultimos() {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const [openModalViewPedido, setOpenModalViewPedido] = useState(false);
  const [id, setId] = useState(0);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('cliente/ultimos', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, [loading]);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const columns = [
    {
      field: 'acao',
      headerName: '',
      width: 50,
      disableClickEventBubbling: true,
      sortable: false,
      align: 'center',

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes id={params.row.Pedido} />
          </div>
        );
      },
    },
    {
      field: 'Data',
      headerName: 'Data.',
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data do Pedido ',
    },
    {
      field: 'Pedido',
      headerName: 'Pedido',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Numero do Pedido ',
    },
    {
      field: 'Total',
      headerName: 'Total',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Pedido ',
    },
    {
      field: 'Vendedor',
      headerName: 'Vendedor',
      width: 500,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Vendedor que efetuou o Pedido ',
    },
  ];
  const Acoes = ({ id }) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          onClick={() => handleModalViewPedido(id)}
        >
          <PreviewTwoToneIcon />
        </IconButton>
      </>
    );
  };
  function handleModalViewPedido(item) {
    setLoading(true);
    setId(item);
    setOpenModalViewPedido(true);
    setLoading(false);
  }

  function handleModalViewPedidoFalse() {
    setLoading(true);
    setOpenModalViewPedido(false);
    setId(0);
    setLoading(false);
  }
  const rows = lista.map((item, index) => {
    const DataEmissao = Moment(item.fechado_em).format('DD/MM/YYYY');
    return {
      id: `${index}`,
      Data: `${DataEmissao.toLocaleString('pt-BR', {
        weekday: 'numeric',
        year: 'numeric',
      })}`,
      Pedido: `${item.pedido}`,
      Total: `${item.total.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
      Vendedor: `${item.nome_funcionario}`,
    };
  });

  return (
    <>
      <ViewPedidoFaturado
        open={openModalViewPedido}
        onClose={handleModalViewPedidoFalse}
        id={id}
      />
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Ultimos Pedidos Faturados</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
              }}
            >
              <DataGrid
                density={'compact'}
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
