import { useState, useEffect, useRef, useContext } from "react";

import { toast } from "react-toastify";
import api from "../../services/api";
import Moment from "moment";
import "moment/locale/pt-br";
import { useReactToPrint } from "react-to-print";
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from "@mui/material";

import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import SaveTwoToneIcon from "@mui/icons-material/SaveTwoTone";

import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";

import { AuthContext } from "../../contexts/auth";
import Spinner from "../../components/Spinner";

Moment.locale("pt-br");

export default function ViewNewPedido(props) {
  const ptBR = Moment.locale("pt-br");

  let datadia1 = new Date();
  let datadia2 = new Date();
  let dataHoje = Moment().add(10, "days");

  datadia2.setDate(datadia1.getDate() + 10);

  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(false);
  const [entrega, setEntrega] = useState(dataHoje);
  const [disabled, setDisabled] = useState(false);
  const [pedidoNum, setPedidoNum] = useState("");
  const [obs, setObs] = useState("");

  const componentPrint = useRef();
  const { id, razao } = props;
  const { user } = useContext(AuthContext);
  console.log(user);
  useEffect(() => {
    async function loadLista() {
      let response = await api
        .post("cliente/caixas", {
          codigo: id,
        })
        .then((response) => {
          setLista(response.data);
          setObs("");
        })
        .catch((err) => {
          toast.error("Falha na operação. (" + err + ")");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    loadLista();
  }, [id]);

  async function alteraQtde(index, event) {
    const newLista = [...lista];
    let dataAtual = new Date().toLocaleDateString().substr(0, 10);

    newLista[index].qtde = event.target.value;
    newLista[index].pvePedido = 0;
    newLista[index].pveVendedor = user.upa_vendedor;
    newLista[index].pveCliente = id;
    newLista[index].pveProduto = lista[index].codigo_sequencia;
    newLista[index].pvePedidoEm = dataAtual;
    newLista[index].pveEmissao = dataAtual;
    newLista[index].pveEntrega = "";
    newLista[index].pvePdCliente = "";
    newLista[index].pveObs = "";
    newLista[index].pveQtde = event.target.value;
    newLista[index].pveUnitario = lista[index].venda1;
    newLista[index].pveTotal = lista[index].venda1;
    newLista[index].pveDescricao = lista[index].grupo_produto;
    newLista[index].pveStatus = "Recebido";
    setLista(newLista);
  }

  async function handValidar(val) {
    for (var i = 0; i < lista.length; i++) {
      let dataNew = entrega.format("DD/MM/YYYY");
      //
      lista[i].pvePedido = val;
      lista[i].pveEntrega = dataNew;
      lista[i].pvePdCliente = pedidoNum;
      lista[i].pveObs = obs;
    }
    let novaLista = lista.filter((Lista) => Lista.qtde != 0);

    await api
      .post("cliente/pedido", { novaLista })
      .then((resp) => {
        setDisabled(false);
        toast.success("Pedido salvo com sucesso.");
        props.onClose(false);
      })
      .catch((err) => {
        const { data } = err.response;

        toast.error("Falha ao salvar (" + data.error + ").");
        setDisabled(false);
      });
  }

  async function handSalvar(e) {
    e.preventDefault();
    setDisabled(true);
    let valida = false;
    for (var i = 0; i < lista.length; i++) {
      if (parseFloat(lista[i].pveQtde) > 0) {
        valida = true;
      }
    }

    if (valida == true) {
      let response = await api
        .post("cliente/ultpedido", {
          codigo: id,
        })
        .then((response) => {
          if (response.data.length > 0) {
            handValidar(response.data[0].npedido);
          } else {
            handValidar(1);
          }
        })
        .catch((err) => {
          toast.error("Falha na operação. (" + err + ")");
          setDisabled(false);
        });
    } else {
      toast.error("Operação impossível, não existe produto selecionado.");
      setDisabled(false);
    }
  }

  function mudarData(e) {
    var dataAtual = new Date().toLocaleDateString().substr(0, 10);
    var inicio = Moment(dataAtual, "DD/MM/YYYY");
    var fim = Moment(e, "DD/MM/YYYY");
    var calcDias = fim.diff(inicio, "days");
    if (calcDias < 10) {
      alert("Prazo de entrega não aceito, minimo 10 dias.");
    } else {
      setEntrega(fim);
    }
  }

  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    overflowY: "scroll",
    bgcolor: "background.paper",
    border: "0px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    height: "90vh",
    p: 2,
    zIndex: 11111,
  };

  return (
    <>
      <Modal
        sx={{ overflow: "scroll" }}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={props.onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid sm={12} md={12} lg={12} mt={2} mb={5} ref={componentPrint}>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={2}
                sx={{ textAlign: " center" }}
              >
                <Typography variant="h4">Emissão de Pedido de Venda</Typography>
                <Divider />
              </Grid>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={1}
                mb={2}
                sx={{ textAlign: " center" }}
              >
                <Typography variant="h5" color="primary">
                  {razao}
                </Typography>
              </Grid>

              {loading || lista.length == 0 ? (
                <Spinner />
              ) : (
                <>
                  <form onSubmit={handSalvar}>
                    <Grid
                      sm={12}
                      md={12}
                      lg={12}
                      mt={2}
                      mb={5}
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "row",
                      }}
                    >
                      <Grid sm={12} md={12} lg={2} mr={1} mt={1} mb={1}>
                        <LocalizationProvider
                          dateAdapter={DateAdapter}
                          locale={ptBR}
                        >
                          <DatePicker
                            label="Data de Entrega"
                            value={entrega}
                            onChange={(e) => mudarData(e)}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                size="small"
                                required
                                {...params}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>

                      <Grid sm={12} md={12} lg={2} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Pedido"
                          size="small"
                          type="text"
                          value={pedidoNum}
                          onChange={(e) => setPedidoNum(e.target.value)}
                        />
                      </Grid>

                      <Grid sm={12} md={2} lg={7.75} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Observação"
                          size="small"
                          type="text"
                          value={obs}
                          onChange={(e) => setObs(e.target.value)}
                        />
                      </Grid>
                    </Grid>
                    <Divider />

                    <Grid sm={12} md={2} lg={12} mr={1} mt={1} mb={1}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Descricao</TableCell>
                            <TableCell>Comprimento</TableCell>
                            <TableCell>Largura</TableCell>
                            <TableCell>Altura</TableCell>
                            <TableCell>Tipo</TableCell>
                            <TableCell>Venda</TableCell>
                            <TableCell>Quantidade</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {lista.map((item, index) => {
                            return (
                              <TableRow key={index}>
                                <TableCell data-label="Grupo">
                                  {item.grupo_produto}
                                </TableCell>
                                <TableCell data-label="altura">
                                  {item.comprimento}
                                </TableCell>
                                <TableCell data-label="altura">
                                  {item.largura}
                                </TableCell>
                                <TableCell data-label="altura">
                                  {item.altura}
                                </TableCell>
                                <TableCell data-label="altura">
                                  {item.obs}
                                </TableCell>
                                <TableCell data-label="Venda">
                                  {item.venda1.toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                  })}
                                </TableCell>
                                <TextField
                                  type="text"
                                  value={item.qtde}
                                  onChange={(e) => alteraQtde(index, e)}
                                />
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Grid>
                    <Button
                      fullWidth
                      variant="contained"
                      type="submit"
                      size="large"
                      disabled={disabled}
                    >
                      <SaveTwoToneIcon /> SALVAR OPERAÇÃO
                    </Button>
                  </form>
                </>
              )}
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}
