import React, { useState, useContext } from 'react';
import { colors } from '../styles/colors';
import { AuthContext } from '../contexts/auth';
//import bcrypt from 'bcrypt';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
//importacao dos estilos do material ui
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography, TextField } from '@mui/material/';
import api from '../services/api';
import { ptBR } from '@mui/x-data-grid';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';

const theme1 = {
  palette: {
    primary: {
      light: `${colors.main_blue_no_alpha}`,
      main: `${colors.main_blue_no_alpha}`,
      dark: `${colors.main_dark_text_color}`,
      contrastText: `${colors.white}`,
    },
    secondary: {
      light: `${colors.terraCota}`,
      main: `${colors.terraCota}`,
      dark: `${colors.chineseRed}`,
      contrastText: `${colors.white}`,
    },
    warning: {
      light: `${colors.mandarin}`,
      main: `${colors.cinnabar}`,
      dark: `${colors.chineseRed}`,
      contrastText: `${colors.white}`,
    },
    background: {
      default: `${colors.smokeWhite}`,
      paper: `${colors.ghostWhite}`,
      bgcolor: `${colors.ghostWhite}`,
      contrastText: `${colors.white}`,
    },
    sucesso: {
      light: `${colors.darkSpringGreen}`,
      main: `${colors.darkSpringGreen}`,
      dark: `${colors.darkSpringGreen}`,
      contrastText: `${colors.white}`,
    },
  },
};

const theme = createTheme(theme1, ptBR);

function ResetPassword() {
  const { user, signOut } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [values, setValues] = useState({
    user: user.upa_email,
    password: '',
    newPassword: '',
    vNewpassword: '',
  });

  const [error, setError] = useState({
    user: false,
    password: false,
    newPassword: false,
    vNewPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleBlur = (prop) => (event) => {
    if (
      values.newPassword !== values.vNewpassword ||
      values.newPassword == '001'
    ) {
      setError({ ...error, [prop]: true });
      setOpen(true);
    } else {
      setError({ [prop]: false });
      setOpen(false);
    }
  };

  async function handleSubmit(event) {
    event.preventDefault();

    if (
      values.newPassword != values.vNewpassword ||
      values.newPassword == '001'
    ) {
      alert(
        'Confira se os valores da Nova Senha estão iguais aos da confirmação ou se a nova senha digitada é igual a 001. As senhas no sistema não podem ser iguais a 001'
      );
    } else if (
      values.newPassword == values.vNewpassword &&
      values.newPassword != '001'
    ) {
      setOpen(false);
      setLoading(true);
      await api
        .post('/usuario/alter', {
          email: values.user,
          senha: values.password,
          newsenha: values.newPassword,
        })
        .then(() => {
          toast.success('Senha alterada com sucesso');
          signOut();
        })
        .catch((err) => {
          toast.error('Erro ao alterar senha.' + err);
          setLoading(false);
        });
    } else {
      alert('Erro ao Definir Senha. Confira se a senha atual está correta.');
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        component="main"
        id="main"
        sx={{
          flexDirection: 'column',
          display: 'flex',
          flexGrow: 1,
          height: '95vh',
          overflow: 'auto',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'background.paper',
        }}
      >
        {loading ? (
          <Spinner />
        ) : (
          <>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <img
                src="/images/cocito_logo.png"
                alt="Ca cocito embalagens"
                width={250}
              />
            </Box>
            <Box>
              <Typography variant="h4">Redefinir sua senha </Typography>
            </Box>
            <form onSubmit={handleSubmit}>
              <Box>
                <TextField
                  margin="normal"
                  id="user"
                  required
                  fullWidth
                  name="user"
                  value={values.user}
                  onChange=""
                  label="Seu Usuario"
                  type="text"
                  error=""
                  autoComplete="usuario"
                  readOnly
                />
                <TextField
                  margin="normal"
                  id="currentpassword"
                  required
                  fullWidth
                  name="currentpassword"
                  onChange={handleChange('password')}
                  label="Digite sua Senha Atual"
                  type="password"
                  error=""
                  value={values.password}
                  autoComplete="Senha Atual"
                  autoFocus
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="senha"
                  onChange={handleChange('newPassword')}
                  onBlur={handleBlur('newPassword')}
                  label="Digite sua Nova Senha"
                  type="password"
                  value={values.newPassword}
                  id="newPassword"
                  error={error.newPassword}
                  autoComplete="senha"
                />
                {error ? (
                  <Box sx={{ width: '100%' }}>
                    <Collapse in={open}>
                      <Alert
                        severity="error"
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setOpen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ mb: 2 }}
                      >
                        Os campos não são iguais, para prosseguir os campos Nova
                        Senha e Confirme a Nova Senha devem ser iguais!!!
                      </Alert>
                    </Collapse>
                  </Box>
                ) : (
                  <></>
                )}

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="senha"
                  onBlur={handleBlur('vNewpassword')}
                  onChange={handleChange('vNewpassword')}
                  label="Confirme sua  Nova Senha"
                  type="password"
                  id="vNewPassword"
                  value={values.vNewpassword}
                  error={error.vNewpassword}
                  autoComplete="senha"
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-evenly',
                  width: '100%',
                }}
              >
                <Button
                  sx={{ width: '20%', height: '60px' }}
                  size="large"
                  variant="contained"
                  type="submit"
                >
                  Alterar
                </Button>
                <Button
                  sx={{ width: '20%', height: '60px' }}
                  type="Button"
                  onClick={() => signOut()}
                  size="large"
                  variant="contained"
                >
                  Sair
                </Button>
              </Box>
            </form>
          </>
        )}
      </Box>
    </ThemeProvider>
  );
}

export default ResetPassword;
