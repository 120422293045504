import React from 'react';
import { useEffect, useState, useContext } from 'react';
import Printer, { print } from 'react-pdf-print';
import { useParams } from 'react-router-dom';
import Spinner from '../../../Spinner';
import api from '../../../../services/api';
import './Styles.css';

import { AuthContext } from '../../../../contexts/auth';
import { toast } from 'react-toastify';
const ids = ['1'];

function PrintViewOrcamento() {
  const { numberOrder } = useParams();
  const [order, setOrder] = useState();

  const [cliente, setCliente] = useState();
  const [cidade, setCidade] = useState();
  const [data, setData] = useState();
  const [caixaObs, setCaixaObs] = useState();
  const [listaCaixa, setlistaCaixa] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (numberOrder !== '0') {
      async function loadData() {
        await api
          .post('/pedido/telabusca', {
            pedido: numberOrder,
          })
          .then(({ data }) => {
            console.log(data);
            let json = [];
            if (data.length !== 0) {
              setCliente(data[0].pve_clientenome);
              setCidade(data[0].pve_clientecidade);
              setData(data[0].pve_emissao);
              setCaixaObs(data[0].pve_obs);
              for (let i = 0; i < data.length; i++) {
                json.push({
                  pveDescricao: data[i].pve_descricao,
                  pveCaixacomprimento: data[i].pve_caixacomprimento,
                  pveCaixalargura: data[i].pve_caixalargura,
                  pveCaixaaltura: data[i].pve_caixaaltura,
                  pveCaixaaba: data[i].pve_caixaaba,
                  pveChapaModelo: data[i].pve_chapa_modelo,
                  pveCodTipo: data[i].pve_cod_tipo,
                  pveCodChapa: data[i].pve_cod_chapa,
                  pveCaixariscadort: data[i].pve_caixariscadort,
                  pveCaixaimpressorat: data[i].pve_caixaimpressorat,
                  pveCaixagramatura: data[i].pve_caixagramatura,
                  pveUnitario: data[i].pve_unitario,
                  pveQtde: data[i].pve_qtde,
                  pveTotal: data[i].pve_total,
                  pveStatus: 'Orçamento',
                  pveVendedor: user.upa_vendedor,
                  pveChapaMinimo: data[i].pve_chapa_minimo,
                  pveEmissao: '',
                  pvePedidoEm: '',
                  pvePedido: '',
                  pveClientenome: '',
                  pveClientecidade: '',
                  pveObs: '',
                });
              }
              setlistaCaixa(json);
              setLoading(false);
            } else {
              setLoading(false);
              toast.error('Orcamento nao encontrado');
            }
          })
          .catch((err) => {
            setLoading(false);
            toast.error('Erro ao buscar dados.');
          });
      }
      loadData();
    }
  }, []);
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="column">
        {' '}
        <button
          type="button"
          className="printBtn"
          onClick={() => print(ids)}
          value="Stampa"
        >
          <img src="/images/printIcon.svg" alt="Imprimir" />
        </button>
      </div>

      <div className="pdf">
        <Printer>
          <div
            className="content"
            id={ids[0]}
            style={{ width: '210mm', height: '297mm' }}
          >
            <div className="header">
              <div className="logoImage">
                <img src="/images/logoRelat.jpg" width="100%" />
              </div>
              <div className="title">
                <h6>Orçamento </h6>
              </div>
            </div>

            <div className="row" style={{ height: '17mm' }}>
              <div className="column" style={{ width: ' 30mm' }}>
                <strong>DATA: </strong>
                {data}
              </div>
              <div className="column" style={{ width: ' 30mm' }}>
                <strong>ORCAMENTO: </strong> {numberOrder}
              </div>
              <div className="column" style={{ width: ' 110mm' }}>
                <strong>CLIENTE: </strong> {cliente}
              </div>
              <div className="column" style={{ width: ' 30mm' }}>
                <strong>TELEFONE</strong> {}
              </div>
            </div>

            <div className="row" style={{ height: '15mm' }}>
              <h3>Dados dos Produtos:</h3>
            </div>
            <div className="row" style={{ height: '10mm' }}>
              <div className="column titleLargeColumn">DESCRIÇÃO</div>
              <div className="column titleSmallColumn2">MODELO</div>
              <div className="column titleSmallColumn2">MEDIDAS</div>
              <div className="column titleSmallColumn2">GRAMATURA</div>

              <div className="column titleSmallColumn">QTDE</div>
              <div className="column titleSmallColumn">UNITÁRIO</div>
              <div className="column titleSmallColumn">TOTAL</div>
            </div>

            {listaCaixa.map((item, index) => {
              return (
                <div className="row" style={{ height: '10mm' }} key={index}>
                  <div className="column contentLargeColumn">
                    {item.pveDescricao}
                  </div>
                  <div className="column contentSmallColumn2">
                    {item.pveChapaModelo}
                  </div>

                  <div className="column contentSmallColumn2">
                    {item.pveCaixacomprimento} x {item.pveCaixalargura} x{' '}
                    {item.pveCaixaaltura}
                  </div>
                  <div className="column contentSmallColumn2">
                    {item.pveCaixagramatura}
                  </div>

                  <div className="column contentSmallColumn">
                    {item.pveQtde}
                  </div>
                  <div className="column contentSmallColumn">
                    {item.pveUnitario.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                  <div className="column contentSmallColumn">
                    {item.pveTotal.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                </div>
              );
            })}

            <div className="row">
              {/*<div className="column columnSpace140" />
              <div className="column columnSpace140" />
              <div className="column totalColumn">
                <strong>PRODUTO :</strong>
              </div>

              <div className="column totalColumn">
                 order[0].total_s_ipi.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              </div>
              <div className="column columnSpace140" />
              <div className="column totalColumn">
                {/*<strong>IPI - order[0].ipi_perc.toFixed(2) % :</strong>
              </div>

              <div className="column totalColumn">
                {/*order[0].ipi_real.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              </div>

              <div className="column columnSpace140" />
              <div className="column totalColumn">
                <strong>TOTAL :</strong>
              </div>

              <div className="column totalColumn">
                {/*order[0].total_c_ipi.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })
              </div>*/}
              <div className="row footer">
                <div style={{ width: '100%' }}>
                  <strong>OBSERVAÇÕES: </strong>
                </div>

                <div className="row">
                  {caixaObs ? `1-${caixaObs}` : <></>}
                  {/*    <div className="largeColumn">
                    1 - Sobre os preços acima incidirá o IPI conforme legislação
                    em vigor: %caixas de papelão, salvo quando comprovado a
                    isenção.
                    <br />
                    2- Em razão das técnicas de produção, consideramos completos
                    todos pedidos entregues com 10% superior ou inferior. <br />
                    3 - Preços inclusos ICMS (18%) <br />4 - Caixas impressas ou
                    corte e vinco: Sob análise custo ferramental (Clichê /
                    Matriz)
            <br />
                   
                  
                  
                  </div>
                  */}

                  <div
                    className="column"
                    style={{
                      padding: '1mm',
                      alignItems: 'center',
                      margin: '1mm',
                      fontSize: '8pt',
                      width: '35mm',
                      border: 'solid',
                      borderWidth: '0.25mm',
                    }}
                  >
                    <strong>VALIDADE:</strong> 5 DIAS
                    <strong>COND. PAGTO:</strong>{' '}
                    {/*order[0].pagamento ? order[0].pagamento : `À COMBINAR`*/}
                  </div>
                  <div
                    className="column"
                    style={{
                      padding: '1mm',
                      alignItems: 'center',
                      margin: '1mm',
                      fontSize: '8pt',
                      justifyContent: 'center',
                      width: '65mm',
                      border: 'solid',
                      borderWidth: '0.25mm',
                    }}
                  >
                    <br />
                    <strong>PRAZO DE ENTREGA: </strong>{' '}
                    {/* order[0].entrega
                      ? `${order[0].entrega} DIAS`
                  : `À COMBINAR`*/}
                    <br />
                    <strong>VENDEDOR:</strong>
                    {user.upa_vendedor + '-' + user.upa_nome}
                    <div
                      className="column"
                      style={{
                        fontSize: '8pt',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <strong> ORÇAMENTO NÚMERO</strong>
                      {numberOrder}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Printer>
      </div>
    </div>
  );
}

export default PrintViewOrcamento;
