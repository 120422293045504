import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/auth';

import { toast } from 'react-toastify';
import api from '../../services/api';

import { Grow, Grid, Divider, Typography } from '@mui/material';
import Spinner from '../../components/Spinner';

import { Paper, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import FileDownloadTwoToneIcon from '@mui/icons-material/FileDownloadTwoTone';
export default function Pagar() {
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function loadLista() {
      const response = await api
        .post('cliente/receber', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }
    loadLista();
  }, []);
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  const columns = [
    {
      field: 'Emissao',
      headerName: 'Emissao',
      width: 120,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data de Emissão do Documento ',
    },
    {
      field: 'Vencimento',
      headerName: 'Vencimento',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Data de Vencimento do Documento ',
    },
    {
      field: 'Referente',
      headerName: 'Referente',
      width: 400,
      disableColumnMenu: true,
      sortable: false,
      description: 'Descrição da Referencia do documento ',
    },
    {
      field: 'Condicao',
      headerName: 'Condição',
      width: 160,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Condição',
    },
    {
      field: 'Total',
      headerName: 'Valor R$ ',
      width: 150,
      editable: false,
      disableColumnMenu: true,
      sortable: false,
      description: 'Total do Documento ',
    },

    {
      field: 'acao',
      headerName: 'Baixar Boleto',
      width: 125,
      disableClickEventBubbling: true,
      sortable: false,

      renderCell: (params) => {
        return (
          <div style={{ cursor: 'pointer' }}>
            <Acoes index={params.row.id} />
          </div>
        );
      },
    },
  ];

  const rows = lista.map((item, index) => {
    const data = moment(item.Vencimento).format('DD/MM/YYYY');
    const emissao = moment(item.Emissao).format('DD/MM/YYYY');
    return {
      id: `${index}`,
      Referente: `${item.referente}`,
      Condicao: `${item.descricao}`,
      Vencimento: `${data.toLocaleString('pt-BR', {
        timeZone: 'UTC',
        timeZoneName: 'short',
      })}`,
      Emissao: `${emissao.toLocaleString('pt-BR', {
        timeZone: 'UTC',
        timeZoneName: 'short',
      })}`,
      Total: `${item.valor_acrescentar.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`,
    };
  });

  const Acoes = ({ index }, props) => {
    return (
      <>
        <IconButton
          color="primary"
          aria-label="Download da Nota fiscal"
          onClick={() => alert('ok')}
        >
          <FileDownloadTwoToneIcon />
        </IconButton>
      </>
    );
  };
  return (
    <>
      <Grow in>
        <Grid
          container
          mt={10}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Typography variant="h4">Contas a pagar</Typography>
          </Grid>
          <Divider />
          <Grid
            xs={12}
            sm={12}
            md={12}
            lg={11}
            mt={1}
            mb={1}
            justifyContent={'center'}
          >
            <Paper
              sx={{
                p: 1,
                m: 0,
                display: 'flex',
                flexDirection: 'column',
                height: '75vh',
              }}
            >
              <DataGrid
                rows={rows}
                density={'compact'}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableSelectionOnClick
              />
            </Paper>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}
