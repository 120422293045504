import * as React from 'react';
import { useState, useContext, useEffect } from 'react';

//importacao dos estilos do material ui
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material/';

import Spinner from '../components/Spinner';
//importacao dos componentes do material ui
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import { ptBR } from '@mui/x-data-grid';
import { Box, Typography, Divider, Toolbar } from '@mui/material';

//importacao dos icones do material ui
import MenuIcon from '@mui/icons-material/Menu';
import LockIcon from '@mui/icons-material/Lock';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

//importacao da lista de menus da barra lateral

import { colors } from '../styles/colors';
import { AuthContext } from '../contexts/auth';
import ItensListVendas from '../components/vendedor/ItensListVendas';
import ItensListCliente from '../components/cliente/ItensListCliente';
import { Link, Redirect } from 'react-router-dom';

const drawerWidth = 240;

const theme1 = {
  palette: {
    primary: {
      light: `${colors.main_blue_no_alpha}`,
      main: `${colors.main_blue_no_alpha}`,
      dark: `${colors.main_dark_text_color}`,
      contrastText: `${colors.white}`,
    },
    secondary: {
      light: `${colors.main_dark_text_color}`,
      main: `${colors.main_dark_text_color}`,
      dark: `${colors.chineseRed}`,
      contrastText: `${colors.white}`,
    },
    warning: {
      light: `${colors.main_blue_no_alpha}`,
      main: `${colors.cinnabar}`,
      dark: `${colors.chineseRed}`,
      contrastText: `${colors.white}`,
    },
    background: {
      default: `${colors.smokeWhite}`,
      paper: `${colors.ghostWhite}`,
      bgcolor: `${colors.ghostWhite}`,
      contrastText: `${colors.white}`,
    },
    sucesso: {
      light: `${colors.darkSpringGreen}`,
      main: `${colors.darkSpringGreen}`,
      dark: `${colors.darkSpringGreen}`,
      contrastText: `${colors.white}`,
    },
  },
};

const theme = createTheme(
  theme1,

  ptBR
);

export default function DashBoard({ children }) {
  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [nome, setNome] = useState(true);
  const [password, setPassword] = useState('');
  useEffect(() => {
    async function loadUser() {
      if (user) {
        setNome(user.upa_nome);
        setPassword(user.upa_senha);
      }

      setLoading(false);
    }

    loadUser();
  }, [user]);

  useEffect(() => {
    function checkPassword() {
      if (password == '001') {
        return <Redirect to="/resetpassword" />;
      }
    }

    checkPassword();
  }, [password]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  function RenderListItem({ user }) {
    if (!user) {
      return <ItensListCliente />;
    }
    if (user.upa_tipo != 'CLIENTE') {
      return <ItensListVendas />;
    } else return <ItensListCliente />;
  }
  theme.background = (theme) => theme.palette.mode === 'light';

  theme.typography.h3 = {
    fontSize: '1.5rem',
    '@media (min-width:600px)': {
      fontSize: '1.9rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '2.25rem',
    },
  };

  theme.typography.h4 = {
    fontSize: '1.25rem',
    '@media (min-width:600px)': {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.8rem',
    },
  };

  theme.typography.h5 = {
    fontSize: '1.15rem',
    '@media (min-width:600px)': {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.4rem',
    },
  };
  theme.typography.h6 = {
    fontSize: '1.0rem',
    '@media (min-width:600px)': {
      fontSize: '1.15rem',
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '1.15rem',
    },
  };

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }));

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          {password == '001' ? (
            <Redirect to="/resetpassword" />
          ) : (
            <ThemeProvider theme={theme}>
              <CssBaseline>
                <Box sx={{ display: 'flex' }}>
                  <AppBar position="absolute" open={open}>
                    <Toolbar
                      sx={{
                        pr: '30px',
                      }}
                    >
                      <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={toggleDrawer}
                        sx={{
                          marginRight: '30px',
                          ...(open && { display: 'none' }),
                        }}
                      >
                        <MenuIcon />
                      </IconButton>
                      <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        sx={{ flexGrow: 1 }}
                      >
                        BEM VINDO {nome}
                      </Typography>
                    </Toolbar>
                  </AppBar>

                  <Drawer variant="permanent" open={open}>
                    <Toolbar
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        px: [2],
                      }}
                    >
                      <IconButton onClick={toggleDrawer}>
                        <MenuOpenIcon />
                      </IconButton>
                    </Toolbar>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img
                        src="/images/cocito_logo.png"
                        alt="C.A Cocito Embalagens"
                        width={130}
                        style={{ ...(!open && { display: 'none' }) }}
                      />
                    </div>
                    <Divider />

                    <div
                      style={{
                        width: '100%',

                        display: 'flex',
                        flexDirection: 'column',

                        alignItems: 'center',

                        ...(!open && { display: 'none' }),
                      }}
                    >
                      <PermIdentityIcon fontSize={'large'} color={'warning'} />
                      <Typography component="span" fontSize={'small'}>
                        {' '}
                        {nome}
                      </Typography>
                      <div
                        style={{
                          width: '100%',
                          height: '50px',
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <LockIcon color={'warning'} />

                        <Button component={Link} to={'/resetpassword'}>
                          Trocar a senha
                        </Button>
                      </div>
                    </div>

                    <Divider />
                    <RenderListItem user={user} />
                    <Divider />
                  </Drawer>

                  <Box
                    component="main"
                    id="main"
                    sx={{
                      display: 'flex',
                      flex: 1,

                      height: '100vh',
                      overflow: 'auto',
                      background: theme.background.default,
                      marginTop: '70px',
                    }}
                  >
                    {children}
                  </Box>
                </Box>
              </CssBaseline>
            </ThemeProvider>
          )}
        </>
      )}
    </>
  );
}
