export const colors = {
  oxfordBlue: '#0b132bff',
  blueSapphire: '#1c6e8cff',
  charcoal: '#274156ff',
  primaryMain: '#1976d2',
  primaryLight: '#42a5f5',
  greenBlueCrayola: '#3e92ccff',
  babyBlueEyes: '#92BCEA',
  lapisLazuli: '#2a628fff',
  prussianBlue: '#13293dff',
  prussianBlue2: '#16324fff',
  indigoDye: '#18435aff',
  mediumBlue: '#2317c2ff',
  mediumBlue2: '#1104ccff',
  cosmicCobalt: '#3a3585ff',
  bluePigment: '#332c9cff',
  blue: '#190af2ff',
  spaceCadet: '#1c2541ff',
  spaceCPet: '#363459ff',

  independence: '#3a506bff',
  maximumBlueGreen: '#5bc0beff',
  white: '#ffffffff',
  smokeWhite: '#e8e8e8',
  lightGray: '#d0ccd0ff',
  ghostWhite: '#fbfcffff',
  davysGrey: '#605856ff',
  beige: '#F3FAE1',

  emerald: '#2ec763ff',
  dartmouthGreen: '#07782fff',
  darkSpringGreen: '#287a45ff',
  cinnabar: '#e04938ff',
  redRyb: '#e84d2eff',
  imperialRed: '#DC2E2E',
  fireBrick: '#B82C2C',
  rossoCorsa: '#D12D08 ',
  mandarin: '#F06D3A',
  terraCota: '#E87058',
  bitterSweet: '#ff7961',
  chineseRed: '#B5361D',
  bloodRed: '#630101',
  brandy: '#9C3E3E',
  upsdellRed: '#B0192D',

  main_white_no_alpha: '#ffff',
  main_background_white_color: '#fafafa',
  main_blue_no_alpha: '#0297db',
  main_dark_text_color: '#535353',
  main_blue_alpha: 'rgba(2, 151, 219, 0.8)',
  second_blue_no_alpha: '#52a0fc',
};
