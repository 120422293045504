import { Switch } from 'react-router-dom';

//Route personalizado para estabelecer a autenticacao tive que mudar o nome pois estava confundindo com o routes de react-router-dom
import { Roteador } from './Routes';

//Paginas  Clientes
import Principal from '../pages/cliente/Principal';
import clienteUltimos from '../pages/cliente/UltimosPedidos';
import clienteProducao from '../pages/cliente/PedidosProducao';
import clienteCaixa from '../pages/cliente/Caixas';
import clientePedidoRealizados from '../pages/cliente/PedidosRealizados';
import clienteReceber from '../pages/cliente/CPagar';
import clienteAgenda from '../pages/cliente/Visitas';
import clienteNotas from '../pages/cliente/Notas';
import PrintViewOrcamento from '../components/vendedor/PrintViews/PrintViewOrcamento/PrintViewOrcamento';
//Paginas Vendedores
import PrintViewPedido from '../components/vendedor/PrintViews/PrintViewPedido';

import PrincipalVendas from '../pages/vendedor/Principal';
import vendedorPedido from '../pages/vendedor/Pedidos';
import vendedorCliente from '../pages/vendedor/Clientes';
import vendedorAgenda from '../pages/vendedor/Visitas';
import vendedorConsultapedido from '../pages/vendedor/ConsultaPedido';
import vendedorNaofaturados from '../pages/vendedor/NaoFaturados';
import vendedorPedidoNew from '../pages/vendedor/PedidoNew/PedidoNew';
import vendedorPedidoNewLista from '../pages/vendedor/PedidoNewLista';

//Pagina Login
import Login from '../pages/Login';

//Layout da Dashboard
import DashBoard from '../layouts/DashBoard';
import Boleto from '../views/cliente/Boleto';
import ResetPassword from '../pages/ResetPassword';
import ImageView from '../pages/cliente/ImageView';
import HistoricoVendas from '../pages/vendedor/HistoricoVendas';

export default function Routes() {
  return (
    <>
      <Switch>
        <Roteador exact path="/login" component={Login} />

        <Roteador path="/boleto" component={Boleto} />
        <Roteador
          exact
          path="/resetpassword"
          component={ResetPassword}
          isPrivate
        />
        <DashBoard>
          <Switch>
            <Roteador exact path="/" component={Principal} isPrivate />
            <Roteador
              exact
              path="/vendedor/clientes"
              component={vendedorCliente}
              isPrivate
            />

            <Roteador
              exact
              path="/vendedor/principal"
              component={PrincipalVendas}
              isPrivate
            />
            <Roteador
              exact
              path="/vendedor/consultapedido"
              component={vendedorConsultapedido}
              isPrivate
            />
            <Roteador
              exact
              path="/vendedor/historicos"
              component={HistoricoVendas}
              isPrivate
            />
            <Roteador
              path="/vendedor/printviewpedido/:numberOrder"
              component={PrintViewPedido}
              isPrivate
            />

            <Roteador
              exact
              path="/vendedor/meuspedidos"
              component={vendedorNaofaturados}
              isPrivate
            />
            <Roteador
              path="/vendedor/printvieworcamento/:numberOrder"
              component={PrintViewOrcamento}
              isPrivate
            />
            <Roteador
              exact
              path="/vendedor/agenda"
              component={vendedorAgenda}
              isPrivate
            />
            <Roteador
              exact
              path="/vendedor/pedidos"
              component={vendedorPedido}
              isPrivate
            />
            <Roteador
              path="/vendedor/orcamentonovo/:numeroPedido"
              component={vendedorPedidoNew}
              isPrivate
            />
            <Roteador
              exact
              path="/vendedor/orcamentos"
              component={vendedorPedidoNewLista}
              isPrivate
            />
            <Roteador
              exact
              path="/cliente/Ultimos"
              component={clienteUltimos}
              isPrivate
            />
            <Roteador
              exact
              path="/cliente/producao"
              component={clienteProducao}
              isPrivate
            />
            <Roteador
              exact
              path="/cliente/caixas"
              component={clienteCaixa}
              isPrivate
            />
            <Roteador
              exact
              path="/cliente/pedidos"
              component={clientePedidoRealizados}
              isPrivate
            />
            <Roteador
              exact
              path="/cliente/pagar"
              component={clienteReceber}
              isPrivate
            />
            <Roteador
              exact
              path="/cliente/agenda"
              component={clienteAgenda}
              isPrivate
            />
            <Roteador
              exact
              path="/cliente/notas"
              component={clienteNotas}
              isPrivate
            />
            <Roteador
              path="/cliente/imageview/:id"
              component={ImageView}
              isPrivate
            />
          </Switch>
        </DashBoard>
      </Switch>
    </>
  );
}
