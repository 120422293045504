import React from 'react';
import { useEffect, useState, useContext } from 'react';
import Printer, { print } from 'react-pdf-print';
import { useParams } from 'react-router-dom';
import Spinner from '../../Spinner';
import api from '../../../services/api';
import './Styles.css';

import { AuthContext } from '../../../contexts/auth';
const ids = ['1'];

function PrintViewPedido() {
  const { numberOrder } = useParams();
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function searchOrder() {
      await api
        .post('pedido/buscapedido', { id: numberOrder })
        .then((res) => {
          console.log(res);
          setOrder(res.data);
          console.log(res.data);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    searchOrder();
  }, []);

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="container">
      <div className="column">
        {' '}
        <button
          type="button"
          className="printBtn"
          onClick={() => print(ids)}
          value="Stampa"
        >
          <img src="/images/printIcon.svg" alt="Imprimir" />
        </button>
      </div>

      <div className="pdf">
        <Printer>
          <div
            className="content"
            id={ids[0]}
            style={{ width: '210mm', height: '297mm' }}
          >
            <div className="header">
              <div className="logoImage">
                <img src="/images/rio_caixas_logo.jpg" width="100%" />
              </div>
              <div className="title">
                <h6>Pedido de Venda </h6>
              </div>
            </div>

            <div className="row" style={{ height: '17mm' }}>
              <div className="column" style={{ width: ' 30mm' }}>
                <strong>DATA: </strong>
                {order[0].emissao ? order[0].emissao : ''}
              </div>
              <div className="column" style={{ width: ' 30mm' }}>
                <strong>PEDIDO: </strong> {order[0].venda}
              </div>
              <div className="column" style={{ width: ' 110mm' }}>
                <strong>CLIENTE: </strong> {order[0].cliente}
              </div>
              <div className="column" style={{ width: ' 30mm' }}>
                <strong>TELEFONE</strong> {order[0].telefone}
              </div>
            </div>

            <div className="row" style={{ height: '15mm' }}>
              <h3>Dados dos Produtos:</h3>
            </div>
            <div className="row" style={{ height: '10mm' }}>
              <div className="column titleSmallColumn2">MEDIDAS</div>
              <div className="column titleSmallColumn2">PAPELÃO</div>
              <div className="column titleSmallColumn2">TIPO</div>
              <div className="column titleLargeColumn">DESCRIÇÃO</div>
              <div className="column titleSmallColumn">QTDE</div>
              <div className="column titleSmallColumn">UNITÁRIO</div>
              <div className="column titleSmallColumn">TOTAL</div>
            </div>

            {order[0].produto.map((item, index) => {
              return (
                <div className="row" style={{ height: '10mm' }} key={index}>
                  <div className="column contentSmallColumn2">
                    {item.largura} x {item.altura} x {item.comprimento}
                  </div>
                  <div className="column contentSmallColumn2">
                    {item.papelao}
                  </div>
                  <div className="column contentSmallColumn2">{item.tipo}</div>
                  <div className="column contentLargeColumn">{item.caixa}</div>
                  <div className="column contentSmallColumn">
                    {item.quantidade}
                  </div>
                  <div className="column contentSmallColumn">
                    {item.unitario.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                  <div className="column contentSmallColumn">
                    {item.total.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </div>
                </div>
              );
            })}

            <div className="row">
              <div className="column columnSpace140" />
              <div className="column columnSpace140" />
              <div className="column totalColumn">
                <strong>PRODUTO :</strong>
              </div>

              <div className="column totalColumn">
                {order[0].total_s_ipi.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
              <div className="column columnSpace140" />
              <div className="column totalColumn">
                <strong>IPI - {order[0].ipi_perc.toFixed(2)} % :</strong>
              </div>

              <div className="column totalColumn">
                {order[0].ipi_real.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
              <div className="column columnSpace140" />
              <div className="column totalColumn">
                <strong>TOTAL :</strong>
              </div>

              <div className="column totalColumn">
                {order[0].total_c_ipi.toLocaleString('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                })}
              </div>
              <div className="row footer">
                <div style={{ width: '100%' }}>
                  <strong>OBSERVAÇÕES: </strong>
                </div>

                <div className="row">
                  <div className="largeColumn">
                    1 - Sobre os preços acima incidirá o IPI conforme legislação
                    em vigor: 15% caixas de papelão, salvo quando comprovado a
                    isenção.
                    <br />
                    2- Em razão das técnicas de produção, consideramos completos
                    todos pedidos entregues com 10% superior ou inferior. <br />
                    3 - Preços inclusos ICMS (18%) <br />4 - Caixas impressas ou
                    corte e vinco: Sob análise custo ferramental (Clichê /
                    Matriz)
                    <br />
                    {order[0].obs ? `5 -${order[0].obs}` : <></>}
                  </div>

                  <div
                    className="column"
                    style={{
                      padding: '1mm',
                      alignItems: 'center',
                      margin: '1mm',
                      fontSize: '8pt',
                      width: '35mm',
                      border: 'solid',
                      borderWidth: '0.25mm',
                    }}
                  >
                    <strong>VALIDADE:</strong> 5 DIAS
                    <strong>COND. PAGTO:</strong>{' '}
                    {order[0].pagamento ? order[0].pagamento : `À COMBINAR`}
                  </div>
                  <div
                    className="column"
                    style={{
                      padding: '1mm',
                      alignItems: 'center',
                      margin: '1mm',
                      fontSize: '8pt',
                      justifyContent: 'center',
                      width: '65mm',
                      border: 'solid',
                      borderWidth: '0.25mm',
                    }}
                  >
                    <br />
                    <strong>PRAZO DE ENTREGA: </strong>{' '}
                    {order[0].entrega
                      ? `${order[0].entrega} DIAS`
                      : `À COMBINAR`}
                    <br />
                    <strong>VENDEDOR:</strong>
                    {user.upa_vendedor + '-' + user.upa_nome}
                    <div
                      className="column"
                      style={{
                        fontSize: '8pt',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <strong> ORÇAMENTO NÚMERO</strong>
                      {order[0].venda}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Printer>
      </div>
    </div>
  );
}

export default PrintViewPedido;
