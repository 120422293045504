import { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import { AuthContext } from "../../../contexts/auth";
import api from "../../../services/api";
import Spinner from "../../../components/Spinner";
import {
  Grid,
  TextField,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  NativeSelect,
  InputLabel,
  IconButton,
  Paper,
  Typography,
  Button,
} from "@mui/material";

import { Redirect, useParams, useHistory } from "react-router-dom";

EditTwoToneIcon;

import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import DeleteForeverTwoToneIcon from "@mui/icons-material/DeleteForeverTwoTone";

import { SaveTwoTone, SettingsApplications } from "@material-ui/icons";

import { validarCNPJ, buscaCep } from "../../../helpers/funcoes.js";
import { calculoCorte, calculoValor, calculoPeso } from "./funcoes.js";

export default function PedidoNew() {
  const [listaModeloCaixa, setlistaModeloCaixa] = useState([]);
  const [listaTipoChapa, setlistaTipoChapa] = useState([]);
  const [listaCliente, setlistaCliente] = useState([]);
  const [listaMateriaPrima, setlistaMateriaPrima] = useState([]);
  const [listaMateriaPrimaFiltrada, setlistaMateriaPrimaFiltrada] = useState(
    []
  );
  const [materiaSelecionada, setMateriaSelecionada] = useState(-1);
  const [clienteSelecionado, setClienteSelecionado] = useState(-1);
  const [codigoMateriaSelecionada, setCodigoMateriaSelecionada] = useState(0);
  const [modeloSelecionado, setModeloSelecionado] = useState(-1);
  const [descricaoModeloSelecionado, setDescricaoModeloSelecionado] =
    useState("");
  const [coeficienteModeloSelecionado, setCoeficienteModeloSelecionado] =
    useState(1);
  const [listaCaixaPedido, setListaCaixaPedido] = useState([]);
  const [indiceCaixaSelecionada, setIndiceCaixaSelecionada] = useState(-1);
  const [tipoChapaSelecionado, setTipoChapaSelecionado] = useState(-1);
  const [codigoTipoChapaSelecionado, setCodigoTipoChapaSelecionado] =
    useState(0);
  const [desativaBotaoSalva, setDesativaBotaoSalva] = useState(false);
  const [clienteDesabilitado, setClienteDesabilitado] = useState(false);
  const [loading, setLoading] = useState(true);
  const [codigoCliente, setCodigoCliente] = useState(0);
  const [cliente, setCliente] = useState("");
  const [aosCuidados, setAosCuidados] = useState("");
  const [cidade, setCidade] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [eMail, setEMail] = useState("");
  const [uf, setUf] = useState("");
  const [ie, setIe] = useState("");
  const [condicaoPgto, setCondicaoPgto] = useState("");
  const [amostra, setAmostra] = useState("SIM");
  const [data, setData] = useState(new Date().toLocaleDateString());
  const [status, setStatus] = useState("Orçamento");
  const [descricaoCaixa, setDescricaoCaixa] = useState("");
  const [desativaValorKg, setDesativaValorKg] = useState(false);
  const [caixaValorKg, setCaixaValorKg] = useState(0);
  const [caixaComprimento, setCaixaComprimento] = useState("0");
  const [caixaLargura, setCaixaLargura] = useState("0");
  const [caixaAltura, setCaixaAltura] = useState("0");
  const [caixaCorteComp, setCaixaCorteComp] = useState(0);
  const [caixaCorteLarg, setCaixaCorteLarg] = useState(0);
  const [caixaGramatura, setCaixaGramatura] = useState(0);
  const [caixaValor, setCaixaValor] = useState(0);
  const [caixaMinimo, setCaixaMinimo] = useState(0);
  const [caixaQtde, setCaixaQtde] = useState(0);
  const [caixaTotal, setCaixaTotal] = useState(0);
  const [valorKgMinimo, setValorKgMinimo] = useState(0);

  const [caixaMsgCliente, setCaixaMsgCliente] = useState("");
  const [caixaMsgEscritorio, setCaixaMsgEscritorio] = useState("");
  const [caixaMsgRejeicao, setCaixaMsgRejeicao] = useState("");

  const { numeroPedido } = useParams("numeroPedido");
  const { user } = useContext(AuthContext);
  const history = useHistory();
  const ListMateria = [];

  useEffect(() => {
    async function loadPedido() {
      await api
        .post("pedido/telanew", {
          id: user.upa_vendedor,
        })
        .then((response) => {
          setlistaModeloCaixa(response.data.modelos);
          setlistaTipoChapa(response.data.tipo);
          setlistaMateriaPrima(response.data.materia);
          setlistaCliente(response.data.cliente);
          setCaixaMsgCliente("");
          setCaixaMsgEscritorio("");
        })
        .catch((err) => {
          toast.error("Erro ao carregar dados.");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    loadPedido();
  }, []);

  useEffect(() => {
    if (numeroPedido !== "0") {
      async function loadData() {
        await api
          .post("/pedido/telabusca", {
            pedido: numeroPedido,
          })
          .then(({ data }) => {
            let json = [];
            if (data.length !== 0) {
              if (data[0].pve_cliente != 0) {
                let indCLIENTE = listaCliente.findIndex(
                  (dados) => dados.codigo == data[0].pve_cliente
                );
                setCodigoCliente(data[0].pve_cliente);
                setClienteDesabilitado(true);
                setClienteSelecionado(indCLIENTE);
              } else {
                setCodigoCliente(0);
                setClienteDesabilitado(false);
                setClienteSelecionado(-1);
              }
              setStatus(data[0].pve_status);
              setCliente(data[0].pve_clientenome);
              setCidade(data[0].pve_clientecidade);
              setData(data[0].pve_emissao);
              setTelefone(data[0].pve_clientetelefone);
              setEMail(data[0].pve_clienteemail);
              setAmostra(data[0].pve_amostra);
              setAosCuidados(data[0].pve_clientecontato);
              setCondicaoPgto(data[0].pve_condicao_pagamento);
              setCnpj(data[0].pve_clientecnpj);
              setCep(data[0].pve_clientecep);
              setEndereco(data[0].pve_clienteendereco);
              setNumero(data[0].pve_clientenumero);
              setBairro(data[0].pve_clientebairro);
              setUf(data[0].pve_clienteuf);
              setIe(data[0].pve_clienteie);

              setCaixaMsgCliente(data[0].pve_obs);
              setCaixaMsgEscritorio(data[0].pve_msg_interna);
              for (let i = 0; i < data.length; i++) {
                json.push({
                  pveDescricao: data[i].pve_descricao,
                  pveCaixacomprimento: data[i].pve_caixacomprimento,
                  pveCaixalargura: data[i].pve_caixalargura,
                  pveCaixaaltura: data[i].pve_caixaaltura,
                  pveCaixaaba: data[i].pve_caixaaba,
                  pveChapaModelo: data[i].pve_chapa_modelo,
                  pveCodTipo: data[i].pve_cod_tipo,
                  pveCodChapa: data[i].pve_cod_chapa,
                  pveCaixaCorteLarg: data[i].pve_caixariscadort,
                  pvecaixaCorteComp: data[i].pve_caixaimpressorat,
                  pveCaixariscadort: data[i].pve_caixariscadort,
                  pveCaixaimpressorat: data[i].pve_caixaimpressorat,
                  pveCaixagramatura: data[i].pve_caixagramatura,
                  pveUnitario: data[i].pve_unitario,
                  pvenewpreco: data[i].pve_original,
                  pvemsgreprova: data[i].pve_msg_reprova,
                  pveQtde: data[i].pve_qtde,
                  pveTotal: data[i].pve_total,
                  pveStatus: data[i].pve_status,
                  pveVendedor: user.upa_vendedor,
                  pveChapaMinimo: data[i].pve_chapa_minimo,
                  pveChapaPeso: data[i].pve_chapa_peso,
                  pveEmissao: "",
                  pvePedidoEm: "",
                  pvePedido: "",
                  pveClientenome: "",
                  pveClientecidade: "",
                  pveClientecnpj: "",
                  pveClientecep: "",
                  pveClienteendereco: "",
                  pveClientenumero: "",
                  pveClientebairro: "",
                  pveClienteuf: "",
                  pveClienteie: "",
                  pveObs: "",
                  pveClientecontato: "",
                  pveCondicaoPagamento: "",
                });
              }
              setListaCaixaPedido(json);
            } else {
              toast.error("Orcamento nao encontrado");
              history.push("/vendedor/orcamentos");
              history.go();
            }
          })
          .catch((err) => {
            toast.error("Erro ao buscar dados.");
          });
      }
      loadData();
    }
  }, [loading]);

  function selecionaTipoChapa(e) {
    setTipoChapaSelecionado(e.target.value);
    setCaixaGramatura(listaTipoChapa[e.target.value].gramatura);
    //
    let pesoCaixa = calculoPeso(
      caixaCorteLarg,
      caixaCorteComp,
      listaTipoChapa[e.target.value].gramatura
    ).peso;
    let precoQuilo = listaTipoChapa[e.target.value].valores[0].preco_kg;
    let quiloMinimoVenda = listaTipoChapa[e.target.value].valores[0].kg_inicio;
    //
    setCaixaValor(precoQuilo * pesoCaixa * coeficienteModeloSelecionado);
    setMateriaSelecionada(-1);
    setCaixaMinimo(quiloMinimoVenda / pesoCaixa);
    setCodigoTipoChapaSelecionado(listaTipoChapa[e.target.value].codigo);
    setCaixaValorKg(precoQuilo);
    setValorKgMinimo(precoQuilo);
    //
    filtraMateriaPrima(
      caixaCorteLarg,
      caixaCorteComp,
      listaTipoChapa[e.target.value].gramatura
    ).then(() => {
      setlistaMateriaPrimaFiltrada(ListMateria);
    });
  }

  function calculaValorCaixa(e) {
    let valorKiloUtilizado;
    let calcValorUnitarioCaixa;
    let calcPesoUnitarioCaixa;

    console.log("1.INICIO CALCULO ...");

    // COMPARA VALOR DIGITADO DO KILO COM O VALOR MINIMO A SER VENDIDO
    if (parseFloat(e.target.value) < parseFloat(valorKgMinimo)) {
      toast.warning(
        "Valor do KG abaixo do minimo , minimo = " + parseFloat(valorKgMinimo)
      );
      valorKiloUtilizado = valorKgMinimo;
      setCaixaValorKg(valorKgMinimo);
      console.log("2.VALOR ABAIXO ...");
    } else {
      valorKiloUtilizado = e.target.value;
      setCaixaValorKg(e.target.value);
      console.log("2.VALOR ACIMA ...");
    }

    // CALCULA VALOR DA CAIXA
    if (materiaSelecionada === -1) {
      calcValorUnitarioCaixa = calculoValor(
        caixaCorteLarg,
        caixaCorteComp,
        listaTipoChapa[tipoChapaSelecionado].gramatura,
        valorKiloUtilizado,
        coeficienteModeloSelecionado
      ).valor;
      console.log("3.CALCULO SEM MATÉIRA -> " + calcValorUnitarioCaixa);
    } else {
      calcPesoUnitarioCaixa = calculoPeso(
        listaMateriaPrimaFiltrada[materiaSelecionada].comprimento,
        listaMateriaPrimaFiltrada[materiaSelecionada].largura,
        listaMateriaPrimaFiltrada[materiaSelecionada].gramatura
      ).peso;
      let quantidade =
        parseInt(
          listaMateriaPrimaFiltrada[materiaSelecionada].comprimento /
            caixaCorteComp
        ) *
        parseInt(
          listaMateriaPrimaFiltrada[materiaSelecionada].largura / caixaCorteLarg
        );
      console.log(
        "Peso.:" +
          calcPesoUnitarioCaixa +
          " - VALOR KG - " +
          valorKiloUtilizado +
          " - QUANTIDADE " +
          quantidade +
          " - coeficiente : " +
          coeficienteModeloSelecionado
      );
      calcValorUnitarioCaixa =
        valorKiloUtilizado *
        ((calcPesoUnitarioCaixa / quantidade) * coeficienteModeloSelecionado);
      console.log("3.CALCULO COM MATÉIRA -> " + calcValorUnitarioCaixa);
    }
    let valorNew = calcValorUnitarioCaixa.toFixed(2);
    console.log("4..CALCULO REALIZADO." + valorNew);

    setCaixaValor(parseFloat(valorNew));
    if (caixaQtde != 0) {
      setCaixaTotal(calcValorUnitarioCaixa.toFixed(2) * caixaQtde);
    }
  }

  function selecionaModelo(e) {
    setModeloSelecionado(e.target.value);
    setMateriaSelecionada(-1);
    setTipoChapaSelecionado(-1);
    setCaixaCorteLarg(0);
    setCaixaCorteComp(0);

    if (e.target.value >= 0) {
      setDescricaoModeloSelecionado(
        listaModeloCaixa[e.target.value].mca_descricao
      );
      setCoeficienteModeloSelecionado(
        listaModeloCaixa[e.target.value].mca_coeficiente
      );

      let resultCorte = calculoCorte(
        listaModeloCaixa[e.target.value],
        caixaComprimento,
        caixaLargura,
        caixaAltura
      );
      setCaixaCorteLarg(resultCorte.largura);
      setCaixaCorteComp(resultCorte.comprimento);
    }
  }

  function editCpnj(e) {
    if (validarCNPJ(e.target.value) === false) {
      toast.warning("Atenção CNPJ inválido.");
    }
  }

  async function pedBuscaCep(e) {
    let dados = await buscaCep(e);
    if (dados !== false) {
      setEndereco(dados.logradouro);
      setBairro(dados.bairro);
      setCidade(dados.localidade);
      setUf(dados.uf);
    }
  }

  //------------------------------------------------//

  async function filtraMateriaPrima(largura, comprimento, gramatura) {
    for (let i = 0; i < listaMateriaPrima.length; i++) {
      if (
        listaMateriaPrima[i].comprimento >= comprimento &&
        listaMateriaPrima[i].largura >= largura &&
        listaMateriaPrima[i].gramatura == gramatura
      ) {
        let perda =
          100 *
          (1 -
            (parseInt(listaMateriaPrima[i].comprimento / comprimento) *
              comprimento *
              (parseInt(listaMateriaPrima[i].largura / largura) * largura)) /
              (listaMateriaPrima[i].largura *
                listaMateriaPrima[i].comprimento));
        ListMateria.push({
          codigo: listaMateriaPrima[i].codigo,
          comprimento: listaMateriaPrima[i].comprimento,
          largura: listaMateriaPrima[i].largura,
          descricao: listaMateriaPrima[i].descricao,
          descricao_tipo: listaMateriaPrima[i].descricao_tipo,
          gramatura: listaMateriaPrima[i].gramatura,
          perca: 0,
          perda: perda,
          preco: listaMateriaPrima[i].preco,
        });
      }

      ListMateria.sort((x, y) => {
        if (x.perda < y.perda) {
          return -1;
        } else if (x.perda > y.perda) {
          return 1;
        } else {
          return 0;
        }
      });
      await Promise.resolve(ListMateria);
    }
  }

  async function salvaPedido(e) {
    e.preventDefault();
    let dataAtual = new Date().toLocaleDateString().substr(0, 10);
    //
    if (cliente.length <= 3) {
      toast.warning("Nome do cliente inválido.");
      return;
    }
    //
    if (cnpj.length > 0 && validarCNPJ(cnpj) === false && codigoCliente === 0) {
      toast.warning("Operação impossivél CNPJ inválido.");
      return;
    }
    //
    if (uf.length <= 1) {
      toast.warning("Nome do estado inválido.");
      return;
    }
    for (var i = 0; i < listaCaixaPedido.length; i++) {
      listaCaixaPedido[i].pveObs = caixaMsgCliente;
      listaCaixaPedido[i].pveMsgInterna = caixaMsgEscritorio;
      listaCaixaPedido[i].pveEmissao = dataAtual;
      listaCaixaPedido[i].pvePedidoEm = dataAtual;
      listaCaixaPedido[i].pvePedido = numeroPedido;
      listaCaixaPedido[i].pveClientenome = cliente;
      listaCaixaPedido[i].pveClientecidade = cidade;
      listaCaixaPedido[i].pveClientetelefone = telefone;
      listaCaixaPedido[i].pveClienteemail = eMail;
      listaCaixaPedido[i].pveAmostra = amostra;
      listaCaixaPedido[i].pveClientecontato = aosCuidados;
      listaCaixaPedido[i].pveCondicaoPagamento = condicaoPgto;
      listaCaixaPedido[i].pveClientecnpj = cnpj;
      listaCaixaPedido[i].pveClientecep = cep;
      listaCaixaPedido[i].pveClienteendereco = endereco;
      listaCaixaPedido[i].pveClientenumero = numero;
      listaCaixaPedido[i].pveClientebairro = bairro;
      listaCaixaPedido[i].pveClienteuf = uf;
      listaCaixaPedido[i].pveClienteie = ie;
      listaCaixaPedido[i].pveCliente = codigoCliente;
    }

    let salvo = false;
    let listaCaixaSolicitado;
    let listaCaixaOrcamento = listaCaixaPedido.filter(
      (e) => e.pveStatus === "Orçamento"
    );

    if (listaCaixaOrcamento.length != 0) {
      listaCaixaSolicitado = listaCaixaPedido
        .filter((e) => e.pveStatus === "Solicitado")
        .map((e) => {
          return {
            ...e,
            pvePedido: 0,
          };
        });
    } else {
      listaCaixaSolicitado = listaCaixaPedido.filter(
        (e) => e.pveStatus === "Solicitado"
      );
    }

    setDesativaBotaoSalva(true);

    if (listaCaixaOrcamento.length != 0) {
      await api
        .post("pedido/telasave", listaCaixaOrcamento)
        .then((response) => {
          salvo = true;
        })
        .catch((err) => {
          toast.error("Erro ao salvar dados.");
        });
    }

    if (listaCaixaSolicitado.length != 0) {
      await api
        .post("pedido/telasave", listaCaixaSolicitado)
        .then((response) => {
          salvo = true;
        })
        .catch((err) => {
          toast.error("Erro ao salvar dados.");
        });
    }
    if (salvo === true) {
      toast.success("Pedido salvo com sucesso.");
      history.push("/vendedor/orcamentos");
      history.go();
    }
  }

  function selecionaMateriaPrima(e) {
    setMateriaSelecionada(e.target.value);
    if (e.target.value == -1) {
      if (tipoChapaSelecionado >= 0) {
        let calcKg = calculoPeso(
          caixaCorteLarg,
          caixaCorteComp,
          listaTipoChapa[tipoChapaSelecionado].gramatura
        ).peso;

        setCaixaGramatura(listaTipoChapa[tipoChapaSelecionado].gramatura);

        let precoKg = listaTipoChapa[tipoChapaSelecionado].valores[0].preco_kg;
        let minimoKg =
          listaTipoChapa[tipoChapaSelecionado].valores[0].kg_inicio;

        setCaixaValor(precoKg * calcKg * coeficienteModeloSelecionado);
        setCaixaValorKg(precoKg);
        setCaixaMinimo(minimoKg / calcKg);
        setDesativaValorKg(false);
        setCodigoTipoChapaSelecionado(
          listaTipoChapa[tipoChapaSelecionado].codigo
        );
      }
    } else {
      let calcKg = calculoPeso(
        listaMateriaPrimaFiltrada[e.target.value].comprimento,
        listaMateriaPrimaFiltrada[e.target.value].largura,
        listaMateriaPrimaFiltrada[e.target.value].gramatura
      ).peso;

      let quantidade =
        parseInt(
          listaMateriaPrimaFiltrada[e.target.value].comprimento / caixaCorteComp
        ) *
        parseInt(
          listaMateriaPrimaFiltrada[e.target.value].largura / caixaCorteLarg
        );
      //setDesativaValorKg(true);
      setCodigoMateriaSelecionada(
        listaMateriaPrimaFiltrada[e.target.value].codigo
      );
      //
      // NOVA REGRA PARA PREÇO APLICADA EM 19/01/2023
      //
      let calKgTotal = 0;
      if (caixaQtde === 0) {
        calKgTotal = calcKg * 1;
      } else {
        calKgTotal = (calcKg / quantidade) * caixaQtde;
      }

      if (listaTipoChapa[tipoChapaSelecionado].valores.length == 1) {
        setCaixaMinimo(0);
        setCaixaValorKg(listaMateriaPrimaFiltrada[e.target.value].preco);
        setValorKgMinimo(listaMateriaPrimaFiltrada[e.target.value].preco);
        setCaixaValor(
          (listaMateriaPrimaFiltrada[e.target.value].preco *
            calcKg *
            coeficienteModeloSelecionado) /
            quantidade
        );
      } else {
        let indexMenKG = 1;
        let kgMinimo =
          listaTipoChapa[tipoChapaSelecionado].valores[1].kg_inicio;
        for (
          let i = 1;
          i < listaTipoChapa[tipoChapaSelecionado].valores.length;
          i++
        ) {
          if (
            listaTipoChapa[tipoChapaSelecionado].valores[i].kg_inicio < kgMinimo
          ) {
            indexMenKG == i;
          }
        }
        let precoKg =
          listaTipoChapa[tipoChapaSelecionado].valores[indexMenKG].preco_kg;
        let minimoKg =
          listaTipoChapa[tipoChapaSelecionado].valores[indexMenKG].kg_inicio;
        for (
          let i = 1;
          i < listaTipoChapa[tipoChapaSelecionado].valores.length;
          i++
        ) {
          if (
            calKgTotal >=
              listaTipoChapa[tipoChapaSelecionado].valores[i].kg_inicio &&
            calKgTotal <= listaTipoChapa[tipoChapaSelecionado].valores[i].kg_fim
          ) {
            precoKg = listaTipoChapa[tipoChapaSelecionado].valores[i].preco_kg;
            minimoKg =
              listaTipoChapa[tipoChapaSelecionado].valores[i].kg_inicio;
          }
        }
        setCaixaMinimo(minimoKg / (calcKg / quantidade));
        setCaixaValorKg(precoKg);
        setValorKgMinimo(precoKg);
        setCaixaValor(
          (precoKg * calcKg * coeficienteModeloSelecionado) / quantidade
        );
      }
      //
      // FIM NOVA REGRA PARA PREÇO APLICADA EM 19/01/2023
      //
    }
  }

  function calculaTotal(e) {
    setCaixaQtde(e.target.value);
    if (materiaSelecionada === -1) {
      let calKgUnitario = calculoPeso(
        caixaCorteLarg,
        caixaCorteComp,
        listaTipoChapa[tipoChapaSelecionado].gramatura
      ).peso;
      let precoKg = listaTipoChapa[tipoChapaSelecionado].valores[0].preco_kg;

      setCaixaValorKg(precoKg);
      setValorKgMinimo(precoKg);
      setCaixaValor(calKgUnitario * precoKg);
      setCaixaTotal((calKgUnitario * precoKg).toFixed(2) * e.target.value);
    } else {
      let calcKg = calculoPeso(
        listaMateriaPrimaFiltrada[materiaSelecionada].comprimento,
        listaMateriaPrimaFiltrada[materiaSelecionada].largura,
        listaMateriaPrimaFiltrada[materiaSelecionada].gramatura
      ).peso;
      let quantidade =
        parseInt(
          listaMateriaPrimaFiltrada[materiaSelecionada].comprimento /
            caixaCorteComp
        ) *
        parseInt(
          listaMateriaPrimaFiltrada[materiaSelecionada].largura / caixaCorteLarg
        );

      //
      // NOVA REGRA PARA PREÇO APLICADA EM 19/01/2023
      //
      let calKgTotal = 0;
      if (e.target.value === 0) {
        calKgTotal = calcKg * 1;
      } else {
        calKgTotal = (calcKg / quantidade) * e.target.value;
      }

      if (listaTipoChapa[tipoChapaSelecionado].valores.length == 1) {
        let valorCaixa =
          (listaMateriaPrimaFiltrada[materiaSelecionada].preco *
            calcKg *
            coeficienteModeloSelecionado) /
          quantidade;
        setCaixaMinimo(0);
        setCaixaValorKg(listaMateriaPrimaFiltrada[materiaSelecionada].preco);
        setValorKgMinimo(listaMateriaPrimaFiltrada[materiaSelecionada].preco);
        setCaixaValor(valorCaixa);
        setCaixaTotal(valorCaixa.toFixed(2) * e.target.value);
      } else {
        let indexMenKG = 1;
        let kgMinimo =
          listaTipoChapa[tipoChapaSelecionado].valores[1].kg_inicio;
        for (
          let i = 1;
          i < listaTipoChapa[tipoChapaSelecionado].valores.length;
          i++
        ) {
          if (
            listaTipoChapa[tipoChapaSelecionado].valores[i].kg_inicio < kgMinimo
          ) {
            indexMenKG == i;
          }
        }

        let precoKg =
          listaTipoChapa[tipoChapaSelecionado].valores[indexMenKG].preco_kg;
        let minimoKg =
          listaTipoChapa[tipoChapaSelecionado].valores[indexMenKG].kg_inicio;

        for (
          let i = 1;
          i < listaTipoChapa[tipoChapaSelecionado].valores.length;
          i++
        ) {
          if (
            calKgTotal >=
              listaTipoChapa[tipoChapaSelecionado].valores[i].kg_inicio &&
            calKgTotal <= listaTipoChapa[tipoChapaSelecionado].valores[i].kg_fim
          ) {
            precoKg = listaTipoChapa[tipoChapaSelecionado].valores[i].preco_kg;
            minimoKg =
              listaTipoChapa[tipoChapaSelecionado].valores[i].kg_inicio;
          }
        }
        let valorCaixa =
          (precoKg * calcKg * coeficienteModeloSelecionado) / quantidade;
        setCaixaMinimo(minimoKg / (calcKg / quantidade));
        setCaixaValorKg(precoKg);
        setValorKgMinimo(precoKg);
        setCaixaValor(valorCaixa);
        setCaixaTotal(valorCaixa.toFixed(2) * e.target.value);
      }
      //
      // FIM NOVA REGRA PARA PREÇO APLICADA EM 19/01/2023
      //
    }
  }

  function apagaCaixaPedido(item) {
    if (item.pveStatus !== "Orçamento") {
      toast.warning("Operação impossivél, item não pode ser alterado.");
    } else {
      const operaregistros = listaCaixaPedido.filter((lista) => lista !== item);
      setListaCaixaPedido(operaregistros);
    }
  }

  function statusCaixaPedido(item, index) {
    let newStatus;
    if (
      listaCaixaPedido[index].pveStatus === "Reprovado" ||
      listaCaixaPedido[index].pveStatus === "Orçamento"
    ) {
      if (listaCaixaPedido[index].pveStatus === "Reprovado") {
        newStatus = "Aceito";
      } else {
        newStatus = "Solicitado";
      }
      listaCaixaPedido.splice(index, 1);
      setListaCaixaPedido([
        ...listaCaixaPedido,
        {
          pveDescricao: item.pveDescricao,
          pveCaixacomprimento: item.pveCaixacomprimento,
          pveCaixalargura: item.pveCaixalargura,
          pveCaixaaltura: item.pveCaixaaltura,
          pveChapaModelo: item.pveChapaModelo,
          pveCodTipo: item.pveCodTipo,
          pveCodChapa: item.pveCodChapa,
          pveCaixaCorteLarg: item.pveCaixaCorteLarg,
          pvecaixaCorteComp: item.pvecaixaCorteComp,
          pveCaixariscadort: item.pveCaixariscadort,
          pveCaixaimpressorat: item.pveCaixaimpressorat,
          pveCaixagramatura: item.pveCaixagramatura,
          pveUnitario: item.pveUnitario,
          pvenewpreco: item.pvenewpreco,
          pveMsgReprova: item.pveMsgReprova,
          pveQtde: item.pveQtde,
          pveTotal: item.pveTotal,
          pveStatus: newStatus,
          pveVendedor: item.pveVendedor,
          pveChapaMinimo: item.pveChapaMinimo,
          pveChapaPeso: item.pveChapaPeso,
          pveEmissao: "",
          pvePedidoEm: "",
          pvePedido: "",
          pveClientenome: "",
          pveClientecidade: "",
          pveClientecnpj: "",
          pveClientecep: "",
          pveClienteendereco: "",
          pveClientenumero: "",
          pveClientebairro: "",
          pveClienteuf: "",
          pveClienteie: "",
          pveObs: "",
          pveMsgInterna: "",
        },
      ]);
    } else {
      toast.error("Status não pode ser alterado.");
    }
  }

  function editaCaixaPedido(item, index) {
    if (item.pveStatus !== "Orçamento") {
      toast.warning("Operação impossivél, item não pode ser alterado.");
    } else {
      let indTIPO = listaModeloCaixa.findIndex(
        (dad1) => dad1.mca_descricao == item.pveChapaModelo
      );

      let IcodTIPO = listaTipoChapa.findIndex(
        (dad2) => dad2.codigo == item.pveCodTipo
      );

      if (item.pveCodTipo == 0) {
        IcodTIPO = -1;
      }
      setMateriaSelecionada(-1);
      setIndiceCaixaSelecionada(index);
      setDescricaoCaixa(item.pveDescricao);
      setCaixaComprimento(item.pveCaixacomprimento);
      setCaixaLargura(item.pveCaixalargura);
      setCaixaAltura(item.pveCaixaaltura);
      setCaixaValorKg(item.pveChapaPeso);
      setModeloSelecionado(indTIPO);
      setTipoChapaSelecionado(IcodTIPO);
      setCodigoTipoChapaSelecionado(item.pveCodTipo);
      setCodigoMateriaSelecionada(item.pveCodChapa);
      setCaixaCorteLarg(item.pveCaixaCorteLarg);
      setCaixaCorteComp(item.pvecaixaCorteComp);
      setCaixaGramatura(item.pveCaixagramatura);
      setCaixaValor(item.pveUnitario);
      setCaixaQtde(item.pveQtde);
      setCaixaTotal(item.pveTotal);
      setCaixaMinimo(item.pveChapaMinimo);
      if (item.pveMsgReprova == null) {
        setCaixaMsgRejeicao("");
      } else {
        setCaixaMsgRejeicao(item.pveMsgReprova);
      }

      if (item.pveCodChapa > 0) {
        filtraMateriaPrima(
          item.pveCaixaCorteLarg,
          item.pvecaixaCorteComp,
          item.pveCaixagramatura
        ).then(() => {
          setlistaMateriaPrimaFiltrada(ListMateria);
          let IcodMATERIA = ListMateria.findIndex(
            (dad3) => dad3.codigo == item.pveCodChapa
          );
          setMateriaSelecionada(IcodMATERIA);
        });
      }
    }
  }

  function inserirCaixaPedido(e) {
    if (descricaoCaixa.length <= 3) {
      toast.warning("Descrição da caixa inválido.");
      return;
    }
    //
    if (caixaComprimento <= 0 || isNaN(caixaComprimento) === true) {
      toast.warning("Comprimento da caixa inválida.");
      return;
    }
    if (caixaLargura <= 0 || isNaN(caixaLargura) === true) {
      toast.warning("Largura da caixa inválida.");
      return;
    }
    //
    if (caixaAltura <= 0 || isNaN(caixaAltura) === true) {
      toast.warning("Altura da caixa inválida.");
      return;
    }
    //
    if (caixaGramatura <= 0 || isNaN(caixaGramatura) === true) {
      toast.warning("Gramatura da caixa inválida.");
      return;
    }
    //
    if (
      caixaCorteLarg <= 0 ||
      caixaCorteLarg > 2400 ||
      isNaN(caixaCorteLarg) === true
    ) {
      toast.warning("Largura do corte da caixa inválida.");
      return;
    }

    //

    if (
      caixaCorteComp <= 0 ||
      caixaCorteComp > 3200 ||
      isNaN(caixaCorteComp) === true
    ) {
      toast.warning("Comprimento do corte da caixa inválida.");
      return;
    }

    //
    if (caixaValor <= 0 || isNaN(caixaValor) === true) {
      toast.warning("Valor da caixa inválida.");
      return;
    }
    //
    if (caixaQtde <= 0 || isNaN(caixaQtde) === true) {
      toast.warning("Quantidade da caixa inválida.");
      return;
    }
    //
    if (caixaTotal <= 0 || isNaN(caixaTotal) === true) {
      toast.warning("Valor total da caixa inválido.");
      return;
    }
    //
    if (parseInt(caixaQtde) < parseInt(caixaMinimo)) {
      toast.warning(
        "Quantidade da caixa abaixo do minimo, minimo = " +
          parseInt(caixaMinimo)
      );
      return;
    }
    if (modeloSelecionado == -1) {
      toast.warning("Selecione um modelo de caixa.");
      return;
    }

    if (indiceCaixaSelecionada >= 0) {
      listaCaixaPedido.splice(indiceCaixaSelecionada, 1);
    }
    setIndiceCaixaSelecionada(-1);

    setListaCaixaPedido([
      ...listaCaixaPedido,
      {
        pveDescricao: descricaoCaixa,
        pveCaixacomprimento: caixaComprimento,
        pveCaixalargura: caixaLargura,
        pveCaixaaltura: caixaAltura,
        pveChapaModelo: descricaoModeloSelecionado,
        pveCodTipo: codigoTipoChapaSelecionado,
        pveCodChapa: codigoMateriaSelecionada,
        pveCaixaCorteLarg: caixaCorteLarg,
        pvecaixaCorteComp: caixaCorteComp,
        pveCaixariscadort: caixaCorteLarg,
        pveCaixaimpressorat: caixaCorteComp,
        pveCaixagramatura: caixaGramatura,
        pveUnitario: caixaValor,
        pvenewpreco: 0,
        pveQtde: caixaQtde,
        pveTotal: caixaTotal,
        pveStatus: "Orçamento",
        pveVendedor: user.upa_vendedor,
        pveChapaMinimo: caixaMinimo,
        pveChapaPeso: caixaValorKg,
        pveMsgReprova: caixaMsgRejeicao,
        pveEmissao: "",
        pvePedidoEm: "",
        pvePedido: "",
        pveClientenome: "",
        pveClientecidade: "",
        pveClientecnpj: "",
        pveClientecep: "",
        pveClienteendereco: "",
        pveClientenumero: "",
        pveClientebairro: "",
        pveClienteuf: "",
        pveClienteie: "",
        pveObs: "",
        pveMsgInterna: "",
      },
    ]);
    setDescricaoCaixa("");
    setCaixaLargura("0");
    setCaixaComprimento("0");
    setCaixaAltura("0");
    setCaixaCorteLarg(0);
    setCaixaCorteComp(0);
    setCaixaGramatura(0);
    setCaixaValor(0);
    setCaixaQtde(0);
    setCaixaTotal(0);
    setCaixaMinimo(0);
    setCodigoTipoChapaSelecionado(0);
    setCodigoMateriaSelecionada(0);

    setModeloSelecionado(-1);
    setTipoChapaSelecionado(-1);
    setMateriaSelecionada(-1);
  }

  function alteraMedidas(e, campo) {
    if (campo == "comprimento") {
      setCaixaComprimento(e);
    }
    if (campo == "largura") {
      setCaixaLargura(e);
    }
    if (campo == "altura") {
      setCaixaAltura(e);
    }
    setModeloSelecionado(-1);
    setTipoChapaSelecionado(-1);
    setMateriaSelecionada(-1);
    setCaixaMinimo(0);
    setCaixaQtde(0);
    setCaixaTotal(0);
    setCaixaGramatura(0);
    setCaixaCorteLarg(0);
    setCaixaCorteComp(0);
    setCaixaValorKg(0);
    setCaixaValor(0);
  }

  function selecionaCliente(e) {
    setClienteSelecionado(e.target.value);
    if (e.target.value == -1) {
      setCodigoCliente(0);
      setClienteDesabilitado(false);
      setCliente("");
      setCnpj("");
      setIe("");
      setCep("");
      setEndereco("");
      setNumero("");
      setBairro("");
      setCidade("");
      setUf("");
      setTelefone("");
      setEMail("");
      setAosCuidados("");
    } else {
      setCodigoCliente(listaCliente[e.target.value].codigo);
      setClienteDesabilitado(true);
      setCliente(listaCliente[e.target.value].razao_social);
      setCnpj(listaCliente[e.target.value].cnpj_cpf);
      setIe(listaCliente[e.target.value].ins_estadual);
      setCep(listaCliente[e.target.value].cep);
      setEndereco(listaCliente[e.target.value].endereco);
      setNumero(listaCliente[e.target.value].numero);
      setBairro(listaCliente[e.target.value].bairro);
      setCidade(listaCliente[e.target.value].cidade);
      setUf(listaCliente[e.target.value].estado);
      setTelefone(listaCliente[e.target.value].telefone);
      setEMail(listaCliente[e.target.value].e_mail);
      setAosCuidados(listaCliente[e.target.value].contato);
    }
  }

  function apertaTecla(e) {
    if (e.key == "Enter" || e.charCode == 13) {
      e.preventDefault();
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Grid container display="flex" justifyContent="center" mt={10} lg={12}>
      <Grid
        component={Paper}
        xs={12}
        mt={1}
        sm={12}
        md={12}
        p={2}
        lg={11}
        display="flex"
      >
        <form onSubmit={salvaPedido} onKeyPress={apertaTecla}>
          <Grid
            xs={12}
            mt={1}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent={"center"}
          >
            <Typography variant="h4">Orçamento</Typography>
          </Grid>
          <Grid xs={12} mt={1} sm={12} md={12} lg={12} display="flex">
            <Grid xs={12} mt={2} mb={2} mr={2} sm={12} md={12} lg={12}>
              <InputLabel>Selecione um cliente cadastrado</InputLabel>
              <NativeSelect
                fullWidth
                value={clienteSelecionado}
                onChange={selecionaCliente}
              >
                <option value="-1">Selecione um cliente</option>
                {listaCliente.map((item, index) => {
                  return (
                    <option key={item.codigo} value={index}>
                      {item.razao_social}
                    </option>
                  );
                })}
              </NativeSelect>
            </Grid>
          </Grid>
          <Grid xs={12} mt={1} sm={12} md={12} lg={12} display="flex">
            <Grid xs={12} mb={1} mr={2} sm={2} md={2} lg={1}>
              <TextField
                label="Data"
                type="text"
                fullWidth
                variant="standard"
                disabled={true}
                value={data}
                onChange={(e) => setData(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={6}>
              <TextField
                label="Cliente*"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={cliente}
                onChange={(e) => setCliente(e.target.value)}
              />
            </Grid>

            <Grid xs={12} mb={1} mr={2} sm={2} md={2} lg={2}>
              <TextField
                label="CNPJ*"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={cnpj}
                onChange={(e) => setCnpj(e.target.value)}
                onBlur={(e) => editCpnj(e)}
              />
            </Grid>

            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={2}>
              <TextField
                label="IE"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={ie}
                onChange={(e) => setIe(e.target.value)}
              />
            </Grid>

            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={2}>
              <TextField
                label="CEP"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={cep}
                onBlur={(e) => pedBuscaCep(e)}
                onChange={(e) => setCep(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid xs={12} mt={1} sm={12} md={12} lg={12} display="flex">
            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={4}>
              <TextField
                label="Endereço"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={1}>
              <TextField
                label="Numero"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={numero}
                onChange={(e) => setNumero(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={2}>
              <TextField
                label="Bairro"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={bairro}
                onChange={(e) => setBairro(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={4}>
              <TextField
                label="Cidade"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={cidade}
                onChange={(e) => setCidade(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={1}>
              <TextField
                label="UF*"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={uf}
                onChange={(e) => setUf(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid xs={12} mt={1} sm={12} md={12} lg={12} display="flex">
            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={5}>
              <TextField
                label="Telefone"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
              />
            </Grid>

            <Grid xs={12} mb={1} mr={2} sm={5} md={5} lg={5}>
              <TextField
                label="E Mail"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={eMail}
                onChange={(e) => setEMail(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={12} md={12} lg={4}>
              <TextField
                label="Aos cuidados"
                type="text"
                fullWidth
                variant="standard"
                disabled={clienteDesabilitado}
                value={aosCuidados}
                onChange={(e) => setAosCuidados(e.target.value)}
              />
            </Grid>
          </Grid>
          <Grid
            xs={12}
            mt={1}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
          >
            <Grid xs={12} mb={1} mr={2} sm={12} md={12} lg={8}>
              <TextField
                label="Caixa"
                type="text"
                fullWidth
                variant="standard"
                value={descricaoCaixa}
                onChange={(e) => setDescricaoCaixa(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={3} md={3} lg={3}>
              <TextField
                label="Comprimento"
                type="text"
                fullWidth
                variant="standard"
                value={caixaComprimento}
                onChange={(e) => alteraMedidas(e.target.value, "comprimento")}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={3} md={3} lg={3}>
              <TextField
                label="Largura"
                type="text"
                fullWidth
                variant="standard"
                value={caixaLargura}
                onChange={(e) => alteraMedidas(e.target.value, "largura")}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={3} md={3} lg={3}>
              <TextField
                label="Altura"
                type="text"
                fullWidth
                variant="standard"
                value={caixaAltura}
                onChange={(e) => alteraMedidas(e.target.value, "altura")}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={3} md={3} lg={3}>
              <InputLabel>Amostra</InputLabel>
              <NativeSelect
                fullWidth
                value={amostra}
                onChange={(e) => setAmostra(e.target.value)}
              >
                <option value="SIM">SIM</option>
                <option value="NÃO">NÃO</option>
              </NativeSelect>
            </Grid>
          </Grid>

          <Grid xs={12} mt={2} mb={2} mr={2} sm={12} md={12} lg={12}>
            <InputLabel>Modelo de Caixa</InputLabel>
            <NativeSelect
              fullWidth
              value={modeloSelecionado}
              onChange={selecionaModelo}
            >
              <option value="-1">Selecione um modelo de caixa</option>
              {listaModeloCaixa.map((item, index) => {
                return (
                  <option key={item.mca_codigo} value={index}>
                    {item.mca_descricao}
                  </option>
                );
              })}
            </NativeSelect>
          </Grid>

          <Grid xs={12} mt={2} mb={2} mr={2} sm={12} md={12} lg={12}>
            <InputLabel>Tipo Chapa</InputLabel>
            <NativeSelect
              fullWidth
              value={tipoChapaSelecionado}
              onChange={selecionaTipoChapa}
            >
              <option value="-1">Selecione um tipo de chapa</option>
              {listaTipoChapa.map((item, index) => {
                return (
                  <option key={item.codigo} value={index}>
                    {item.gramatura + " - " + item.descricao_tipo}
                  </option>
                );
              })}
            </NativeSelect>
          </Grid>

          <Grid xs={12} mt={2} mb={2} mr={2} sm={12} md={12} lg={12}>
            <InputLabel>Matéria-Prima</InputLabel>
            <NativeSelect
              fullWidth
              value={materiaSelecionada}
              onChange={selecionaMateriaPrima}
            >
              <option value="-1">Selecione a matéria-prima</option>
              {listaMateriaPrimaFiltrada.map((item, index) => {
                return (
                  <option key={item.codigo} value={index}>
                    {item.gramatura +
                      " - " +
                      item.descricao +
                      "(" +
                      item.descricao_tipo +
                      ")"}
                  </option>
                );
              })}
            </NativeSelect>
          </Grid>
          <Grid
            xs={12}
            mt={1}
            sm={12}
            md={12}
            lg={12}
            display="flex"
            justifyContent="center"
          >
            <Grid xs={12} mb={1} mr={2} sm={3} md={3} lg={3}>
              <TextField
                label="Corte Comprimento"
                type="text"
                fullWidth
                variant="standard"
                disabled={true}
                value={caixaCorteComp}
                onChange={(e) => setCaixaCorteComp(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={3}>
              <TextField
                label="Corte Largura"
                type="text"
                fullWidth
                variant="standard"
                disabled={true}
                value={caixaCorteLarg}
                onChange={(e) => setCaixaCorteLarg(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={3}>
              <TextField
                label="Gramatura"
                type="text"
                fullWidth
                variant="standard"
                disabled={true}
                value={caixaGramatura}
                onChange={(e) => setCaixaGramatura(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={3}>
              <TextField
                label="Valor R$"
                type="text"
                fullWidth
                variant="standard"
                disabled={true}
                value={caixaValor.toFixed(2)}
                onChange={(e) => setCaixaValor(e.target.value)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={3}>
              <TextField
                label="Minimo"
                type="text"
                fullWidth
                disabled={true}
                variant="standard"
                value={caixaMinimo.toFixed(0)}
              />
            </Grid>
            <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={3}>
              <TextField
                label="Quantidade"
                type="text"
                fullWidth
                variant="standard"
                value={caixaQtde}
                onChange={calculaTotal}
              />
            </Grid>

            <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={3}>
              <TextField
                label="Valor KG"
                type="text"
                fullWidth
                variant="standard"
                disabled={desativaValorKg}
                value={caixaValorKg}
                onChange={(e) => setCaixaValorKg(e.target.value)}
                onBlur={calculaValorCaixa}
              />
            </Grid>

            <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={3}>
              <TextField
                label="Total R$"
                type="text"
                fullWidth
                variant="standard"
                disabled={true}
                value={caixaTotal.toFixed(2)}
                onChange={(e) => setCaixaTotal(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={12}>
            <TextField
              label="Condição de Pagamento"
              type="text"
              fullWidth
              variant="standard"
              value={condicaoPgto}
              onChange={(e) => setCondicaoPgto(e.target.value)}
            />
          </Grid>

          <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={12}>
            <TextField
              label="Mensagem interna para o escritório"
              type="text"
              fullWidth
              variant="standard"
              value={caixaMsgEscritorio}
              onChange={(e) => setCaixaMsgEscritorio(e.target.value)}
            />
          </Grid>

          <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={12}>
            <TextField
              label="Mensagem para o cliente"
              type="text"
              fullWidth
              variant="standard"
              value={caixaMsgCliente}
              onBlur={inserirCaixaPedido}
              onChange={(e) => setCaixaMsgCliente(e.target.value)}
            />
          </Grid>

          <Grid xs={12} mb={1} mr={2} sm={12} md={3} lg={12}>
            <TextField
              label="Mensagem enviada pelo escritório"
              type="text"
              fullWidth
              variant="standard"
              disabled={true}
              value={caixaMsgRejeicao}
            />
          </Grid>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell>DESCRICAO</TableCell>
                <TableCell>STATUS</TableCell>
                <TableCell>COMP</TableCell>
                <TableCell>LARG</TableCell>
                <TableCell>ALT</TableCell>
                <TableCell>GRAM</TableCell>
                <TableCell>PRECO</TableCell>
                <TableCell>PR INICIO</TableCell>
                <TableCell>QTDE</TableCell>
                <TableCell>TOTAL</TableCell>
                <TableCell>#</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {listaCaixaPedido.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.pveDescricao}</TableCell>
                    <TableCell>{item.pveStatus}</TableCell>
                    <TableCell>{item.pveCaixacomprimento}</TableCell>
                    <TableCell>{item.pveCaixalargura}</TableCell>
                    <TableCell>{item.pveCaixaaltura}</TableCell>
                    <TableCell>{item.pveCaixagramatura}</TableCell>
                    <TableCell data-label="PRECO">
                      {item.pveUnitario.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell data-label="PRECO">
                      {item.pvenewpreco.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell data-label="QTableCellE">
                      {item.pveQtde}
                    </TableCell>
                    <TableCell data-label="TOTAL">
                      {item.pveTotal.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                      })}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        className="action"
                        style={{
                          backgroundColor: "#b22222",
                          color: "#fafafa",
                        }}
                        onClick={() => apagaCaixaPedido(item)}
                      >
                        <DeleteForeverTwoToneIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        className="action"
                        style={{
                          backgroundColor: "#547899",
                          color: "#fafafa",
                        }}
                        onClick={() => editaCaixaPedido(item, index)}
                      >
                        <EditTwoToneIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        className="action"
                        style={{
                          backgroundColor: "#547899",
                          color: "#fafafa",
                        }}
                        onClick={() => statusCaixaPedido(item, index)}
                      >
                        <SettingsApplications />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Grid xs={12} mt={1} mb={1} mr={2} sm={12} md={12} lg={12}>
            <Button
              sx={{ width: "100%" }}
              size="large"
              variant="contained"
              disabled={desativaBotaoSalva}
              type="submit"
            >
              <SaveTwoTone /> SALVAR PEDIDO
            </Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
}
