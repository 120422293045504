import { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../contexts/auth';
import { toast } from 'react-toastify';
import api from '../../services/api';
import Moment from 'moment';
import { useReactToPrint } from 'react-to-print';
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
} from '@mui/material';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';

import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import Spinner from '../../components/Spinner';

export default function Newpedido(props) {
  const ptBR = Moment.locale('pt-br');
  const componentPrint = useRef();

  let datadia1 = new Date();
  let datadia2 = new Date();

  datadia2.setDate(datadia1.getDate() + 5);
  let dataHoje = Moment().add(7, 'days');
  const [lista, setLista] = useState([]);
  const [entrega, setEntrega] = useState(dataHoje);
  const [pedidoNum, setPedidoNum] = useState('');
  const [obs, setObs] = useState('');
  const [loading, setLoading] = useState(true);
  const [atualiza, setAtualiza] = useState(false);

  const { user } = useContext(AuthContext);
  const { onClose } = props;

  async function alteraQtde(index, event) {
    const newLista = [...lista];
    let dataAtual = new Date().toLocaleDateString().substr(0, 10);
    newLista[index].qtde = event.target.value;
    newLista[index].pvePedido = 0;
    newLista[index].pveVendedor = 0;
    newLista[index].pveCliente = user.upa_cliente;
    newLista[index].pveProduto = lista[index].codigo_sequencia;
    newLista[index].pvePedidoEm = dataAtual;
    newLista[index].pveEmissao = dataAtual;
    newLista[index].pveEntrega = '';
    newLista[index].pvePdCliente = '';
    newLista[index].pveObs = '';
    newLista[index].pveQtde = event.target.value;
    newLista[index].pveUnitario = lista[index].venda1;
    newLista[index].pveTotal = lista[index].venda1;
    newLista[index].pveDescricao = lista[index].grupo_produto;
    newLista[index].pveStatus = 'Recebido';
    setLista(newLista);
  }

  function mudarData(e) {
    var dataAtual = new Date().toLocaleDateString().substr(0, 10);
    var inicio = Moment(dataAtual, 'DD/MM/YYYY');
    var fim = Moment(e, 'DD/MM/YYYY');
    var calcDias = fim.diff(inicio, 'days');
    if (calcDias < 6) {
      alert('Prazo de entrega não aceito, minimo 6 dias.');
    } else {
      setEntrega(fim);
    }
  }
  async function handValidar(val) {
    for (var i = 0; i < lista.length; i++) {
      let dataNew = entrega.format('DD/MM/YYYY');
      //
      lista[i].pvePedido = val;
      lista[i].pveEntrega = dataNew;
      lista[i].pvePdCliente = pedidoNum;
      lista[i].pveObs = obs;
    }
    let novaLista = lista.filter((Lista) => Lista.qtde != 0);
    await api
      .post('cliente/pedido', { novaLista })
      .then((resp) => {
        toast.success('Pedido salvo com sucesso.');
        props.onClose(true);
      })
      .catch((err) => {
        const { data } = err.response;
        toast.error('Falha ao salvar (' + data.error + ').');
      });
  }

  async function handSalvar(e) {
    e.preventDefault();
    let valida = false;
    for (var i = 0; i < lista.length; i++) {
      if (parseFloat(lista[i].pveQtde) > 0) {
        valida = true;
      }
    }
    if (valida == true) {
      let response = await api
        .post('cliente/ultpedido', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          handValidar(response.data[0].npedido);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
    } else {
      toast.error('Operação impossível, não existe produto selecionado.');
    }
  }

  useEffect(() => {
    async function loadLista() {
      let response = await api
        .post('cliente/caixas', {
          codigo: user.upa_cliente,
        })
        .then((response) => {
          setLista(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });

      setLoading(false);
    }
    loadLista();
    InactivityTime();
  }, [atualiza]);

  const InactivityTime = () => {
    let time;

    window.onload = resetTimer;
    document.onclick = resetTimer;
    document.onkeydown = resetTimer;
    function recarregar() {
      setAtualiza(!atualiza);
    }
    function resetTimer() {
      clearTimeout(time);
      time = setTimeout(recarregar, 600000);
    }
  };

  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });
  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }
  return (
    <>
      <Modal
        sx={{ overflow: 'scroll' }}
        hideBackdrop
        aria-labelledby="Cadastro de Pedidos "
        aria-describedby="Cadastro de Pedidos"
        {...props}
      >
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={2}
              mb={5}
              ref={componentPrint}
            >
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  flexDirection: 'row',
                  justifyContent: 'center',
                }}
              >
                <Typography variant="h3">Novo Pedido</Typography>
              </Grid>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ display: 'flex' }}
              >
                <Grid sm={12} md={12} lg={2} mr={1} mt={1} mb={1}>
                  <LocalizationProvider dateAdapter={DateAdapter} locale={ptBR}>
                    <DatePicker
                      label="Data da Entrega"
                      value={entrega}
                      onChange={(e) => mudarData(e)}
                      renderInput={(params) => (
                        <TextField size="small" fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid sm={12} md={12} lg={2} mr={1} mt={1} mb={1}>
                  <TextField
                    label="Numero Pedido"
                    size="small"
                    fullWidth
                    type="text"
                    value={pedidoNum}
                    onChange={(e) => setPedidoNum(e.target.value)}
                  />
                </Grid>
                <Grid sm={12} md={70} lg={8} mr={1} mt={1} mb={1}>
                  <TextField
                    label="Observação"
                    size="small"
                    fullWidth
                    type="text"
                    value={obs}
                    onChange={(e) => setObs(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Divider />
              <form onSubmit={handSalvar}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Grupo</TableCell>
                      <TableCell>Descricao</TableCell>
                      <TableCell>Altura</TableCell>
                      <TableCell>Largura</TableCell>
                      <TableCell>Comprimento</TableCell>
                      <TableCell>Tipo</TableCell>
                      <TableCell>Venda</TableCell>
                      <TableCell>Quantidade</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lista.map((item, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell>{item.grupo_produto}</TableCell>
                          <TableCell>{item.descricao}</TableCell>

                          <TableCell data-label="altura">
                            {item.altura}
                          </TableCell>
                          <TableCell data-label="altura">
                            {item.largura}
                          </TableCell>
                          <TableCell data-label="altura">
                            {item.comprimento}
                          </TableCell>
                          <TableCell data-label="altura">{item.obs}</TableCell>
                          <TableCell>
                            {item.venda1.toLocaleString('pt-BR', {
                              style: 'currency',
                              currency: 'BRL',
                            })}
                          </TableCell>
                          <TableCell>
                            <TextField
                              size="normal"
                              margin="normal"
                              variant="outlined"
                              type="text"
                              value={item.qtde}
                              onChange={(e) => alteraQtde(index, e)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  size="large"
                >
                  <Typography variant="h6">SALVAR OPERAÇÃO</Typography>
                </Button>
              </form>
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}
