import React, { useContext, useState } from 'react';
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Button,
} from '@mui/material';
import { AuthContext } from '../../contexts/auth';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import ProductionQuantityLimitsTwoToneIcon from '@mui/icons-material/ProductionQuantityLimitsTwoTone';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';

import { Link } from 'react-router-dom';

const itemsList = [
  {
    text: 'Home',
    icon: <DashboardIcon color="secondary" />,
    to: '/',
    descricao: 'Acesso a Pagina Principal',
    pos: '1',
  },
  {
    text: 'Minhas Caixas',
    icon: <LaunchTwoToneIcon color="secondary" />,
    to: '/cliente/caixas',
    descricao: 'Acesso minhas caixas cadastradas ',
    pos: '2',
    component: <Divider />,
  },

  {
    text: 'Meus Pedidos',
    icon: <BarChartIcon color="secondary" />,
    to: '/cliente/pedidos',
    descricao:
      'Acesso aos ultimos pedidos efetuados, e também fazer um novo pedido ',
    pos: '3',
  },
  {
    text: 'Pedidos em Produção',
    icon: <ProductionQuantityLimitsTwoToneIcon color="secondary" />,
    to: '/cliente/producao',
    descricao: 'Acesso aos meus pedidos que estão sendo produzidos',
    pos: '4',
  },
  {
    text: 'Pedidos Faturados',
    icon: <AssignmentTwoToneIcon color="secondary" />,
    to: '/cliente/ultimos',
    descricao: 'Acesso aos Ultimos Pedidos Faturados',
    pos: '5',
    component: <Divider />,
  },
  /* {
    text: 'Contas a pagar',
    icon: <AttachMoneyIcon color="secondary" />,
    to: '/cliente/pagar',
    descricao: 'Acesso as minhas faturas em aberto.',
    pos: '6',
  },*/
  {
    text: 'Notas Fiscais',
    icon: <ListAltTwoToneIcon color="secondary" />,
    to: '/cliente/notas',
    descricao: 'Acesso as notas fiscais emitidas para os pedidos faturados ',
    pos: '7',
  },

  {
    text: 'Visitas ',
    icon: <PeopleIcon color="secondary" />,
    to: '/cliente/agenda',
    descricao:
      'Acesso as visitas agendadas pelo consultor de vendas, também é possível solicitar o agendamento de uma visita de seu consultor de vendas',
    pos: '8',
  },
];

function ItensListCliente() {
  const [listaItem, setListaItem] = useState('');
  const { signOut } = useContext(AuthContext);

  const handleListItemClick = () => setListaItem(1);

  return (
    <>
      <List>
        {itemsList.map((item, index) => {
          return (
            <>
              <Tooltip arrow title={item.descricao} placement="right">
                <ListItem
                  index={index}
                  component={Link}
                  to={item.to}
                  selected={''}
                  onClick={() => handleListItemClick(1)}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>

                  <ListItemText primary={item.text} />
                </ListItem>
              </Tooltip>
              {item.component}
            </>
          );
        })}
        <Divider />
        <ListItem component={Button} onClick={() => signOut()}>
          <Tooltip arrow title="Sair " placement="right">
            <ListItemIcon>
              <ExitToAppTwoToneIcon color="secondary" />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </>
  );
}

export default ItensListCliente;
