import React, { useState, useContext } from 'react';

import api from '../../services/api';
import {
  Modal,
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
} from '@mui/material';
import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/auth';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function ModalConfirm(props) {
  const { codigo, onClose } = props;

  const [values, setValues] = useState({ motivo: '', usuario: '', senha: '' });
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [errorField, setErrorField] = useState({
    usuario: false,
    senha: false,
    motivo: false,
  });

  const { user } = useContext(AuthContext);

  function aprova() {
    api
      .post('imagens/aprova', {
        codigo: codigo,
        historico: values.motivo,
        usuario: user.upa_codigo,
        nome: user.upa_nome,
        email: user.upa_email,
      })
      .then(() => {
        toast.success('Layout APROVADO com sucesso!!');
      })
      .catch((err) => {
        toast.error('Erro ao modificar Status do Layout');
      });

    setLoadingBtn(false);
    onClose();
  }

  function handleSubimitLogin() {
    setLoadingBtn(true);
    if (values.usuario && values.senha) {
      api
        .post('usuario', {
          email: values.usuario,
          senha: values.senha,
        })
        .then((response) => {
          if (response.data.length !== 0) {
            aprova();
          } else {
            toast.error('Usuario ou senha Invalidos');
          }
        })
        .catch((err) => {
          toast.error('Erro na busca do Usuario');
        });
    } else {
      toast.error('Preencha os campos de Usuario e senha ');
      setErrorField({ usuario: true, senha: true });
    }
  }

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleBlur = (prop) => (event) => {
    if (!values.usuario || !values.senha) {
      setErrorField({ ...errorField, [prop]: true });
    } else {
      setErrorField({ [prop]: false });
    }
  };

  const body = (
    <Grid sx={style}>
      <Typography variant="h3">
        Deseja <strong style={{ color: '#009966' }}>APROVAR</strong> este layout
        ?
      </Typography>

      <Grid sm={6} md={6} lg={12} mt={5} mb={5}>
        <TextField
          label="Motivo"
          type="text"
          multiline
          rows={3}
          variant="outlined"
          value={values.motivo}
          onChange={handleChange('motivo')}
          fullWidth
        />
      </Grid>
      <Grid lg={12}>
        <TextField
          label="Usuario"
          variant="outlined"
          type="text"
          error={errorField.usuario}
          onChange={handleChange('usuario')}
          onBlur={handleBlur('usuario')}
          value={values.usuario}
          fullWidth
        ></TextField>
      </Grid>

      <Grid mt={4} lg={12}>
        <TextField
          variant="outlined"
          label="Senha"
          type="password"
          error={errorField.senha}
          onBlur={handleBlur('senha')}
          onChange={handleChange('senha')}
          value={values.senha}
          fullWidth
        />
      </Grid>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {loadingBtn ? (
          <CircularProgress />
        ) : (
          <button
            style={{
              marginTop: '25px',
              width: '100%',
              height: '100px',
              borderRadius: '15px',
              border: 'none',
              background: '#009966',
              color: '#fafafa',
              fontSize: '2.5rem',
              fontWeight: '600',
              cursor: 'pointer',
            }}
            type="button"
            onClick={handleSubimitLogin}
          >
            Aprovar
          </button>
        )}
      </div>
    </Grid>
  );

  return (
    <div>
      <Modal {...props}>{body}</Modal>
    </div>
  );
}
