import { useState, useEffect, useRef } from 'react';

import { toast } from 'react-toastify';
import api from '../../services/api';
import Moment from 'moment';

import { useReactToPrint } from 'react-to-print';
import {
  Modal,
  Grid,
  IconButton,
  Grow,
  Typography,
  TextField,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';
import Spinner from '../../components/Spinner';

export default function Newpedido(props) {
  const componentPrint = useRef();
  const [lista, setLista] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = props;

  useEffect(() => {
    async function loadLista() {
      if (id) {
        setLoading(true);
        let response = await api

          .post('cliente/buscapedido', {
            codigo: id,
          })
          .then((response) => {
            setLista(response.data);
          })
          .catch((err) => {
            toast.error('Falha na operação. (' + err + ')');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
    loadLista();
  }, [id]);

  const pageStyle = `
  @page {
    size: auto;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;

  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95vw',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    height: '90vh',
    p: 2,
    zIndex: 11111,
  };
  console.log(loading);

  return (
    <>
      <Modal
        sx={{ overflow: 'scroll' }}
        hideBackdrop
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        {...props}
      >
        <Grid sx={style}>
          <Grid mb={3}>
            <IconButton onClick={props.onClose}>
              <CancelSharpIcon color="warning" />
            </IconButton>
            <IconButton onClick={handlePrint}>
              <LocalPrintshopRoundedIcon />
            </IconButton>
          </Grid>
          <Grow in>
            <Grid sm={12} md={12} lg={12} mt={2} mb={5} ref={componentPrint}>
              <Grid
                sm={12}
                md={12}
                lg={12}
                mt={2}
                mb={5}
                sx={{ textAlign: ' center' }}
              >
                <Typography variant="h3">Dados do Pedido</Typography>
              </Grid>

              {loading || lista.length == 0 ? (
                <Spinner />
              ) : (
                <>
                  <form>
                    <Grid
                      sm={12}
                      md={12}
                      lg={12}
                      mt={2}
                      mb={5}
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        flexDirection: 'row',
                      }}
                    >
                      <Grid sm={12} md={12} lg={12} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Cliente"
                          size="small"
                          type="text"
                          value={lista[0].razao_social}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={2} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Pedido"
                          size="small"
                          type="text"
                          value={lista[0].codigo_venda}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={2} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Emissão"
                          size="small"
                          type="text"
                          value={Moment(lista[0].emissao).format('DD/MM/YYYY')}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={1.5} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Valor"
                          size="small"
                          type="text"
                          value={lista[0].vendas.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                          })}
                        />
                      </Grid>
                      <Grid sm={12} md={2} lg={3} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Observação"
                          size="small"
                          type="text"
                          value={lista[0].obs}
                        />
                      </Grid>
                      <Grid sm={12} md={12} lg={3} mr={1} mt={1} mb={1}>
                        <TextField
                          fullWidth
                          label="Vendedor"
                          size="small"
                          type="text"
                          value={lista[0].nome_funcionario}
                        />
                      </Grid>

                      <Divider />

                      <Grid xs>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Caixa</TableCell>
                              <TableCell>Quantidade</TableCell>
                              <TableCell>Venda</TableCell>
                              <TableCell>Largura</TableCell>
                              <TableCell>Comprimento</TableCell>
                              <TableCell>Altura</TableCell>
                              <TableCell>Corte</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lista.map((item, index) => {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{item.descricao}</TableCell>
                                  <TableCell>
                                    {item.qtde.toLocaleString('pt-BR', {
                                      style: 'decimal',
                                    })}
                                  </TableCell>
                                  <TableCell>
                                    {item.venda_unitario.toLocaleString(
                                      'pt-BR',
                                      {
                                        style: 'currency',
                                        currency: 'BRL',
                                      }
                                    )}
                                  </TableCell>
                                  <TableCell>{item.largura}</TableCell>
                                  <TableCell>{item.comprimento}</TableCell>
                                  <TableCell>{item.altura}</TableCell>
                                  <TableCell>{item.corte}</TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Grid>
                    </Grid>
                  </form>
                </>
              )}
            </Grid>
          </Grow>
        </Grid>
      </Modal>
    </>
  );
}
