import React, { useState, useEffect, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Grow,
  TextField,
  NativeSelect,
  Table,
  TableContainer,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  InputLabel,
  IconButton,
  Box,
  Collapse,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useReactToPrint } from 'react-to-print';
import LocalPrintshopRoundedIcon from '@mui/icons-material/LocalPrintshopRounded';

import { toast } from 'react-toastify';
import { AuthContext } from '../../contexts/auth';
import api from '../../services/api';
import Spinner from '../../components/Spinner';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import AdapterMoment from '@mui/lab/AdapterMoment';
import ScreenSearchDesktopTwoToneIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';
import moment from 'moment';

function HistoricoVendas() {
  const componentPrint = useRef();
  const [open, setOpen] = useState(false);

  const [lista, setLista] = useState([]);
  const [codigoCliente, setCodigoCliente] = useState(0);
  const [clientes, setClientes] = useState();
  const [dataInicio, setDataInicio] = useState();
  const [customerSelect, setCustomerSelect] = useState(-1);

  const [dataFim, setDataFim] = useState();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function carregaClientes() {
      const response = await api
        .post('vendedor', {
          codigo: user.upa_vendedor,
        })
        .then((response) => {
          setClientes(response.data);
        })
        .catch((err) => {
          toast.error('Falha na operação. (' + err + ')');
        });
      setLoading(false);
    }

    carregaClientes();
  }, []);

  async function carregaHistorico() {
    setLoading(true);
    await api
      .post('vendedor/historicoproduto', {
        vendedor: user.upa_vendedor,
        dataInicial: moment(dataInicio).format('DD/MM/YYYY'),
        dataFinal: moment(dataFim).format('DD/MM/YYYY'),
        cliente: codigoCliente,
        faturado: 'SIM',
      })
      .then((response) => {
        setLista(response.data);
        console.log(response.data);
        if (response.data.length == 0) {
          toast.error('Não Existe registros para o periodo selecionado');
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Falha na operação. (' + err + ')');
      });
    setLoading(false);
  }
  const pageStyle = `
  @page {
    size: a4;
    margin: 10mm;
  }

  @media all {
    .pagebreak {
      display: block;
      page-break-after: none;
     
    }
  }
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
    
  
    }
  }

  
`;
  const handlePrint = useReactToPrint({
    pageStyle: pageStyle,
    content: () => componentPrint.current,
  });

  function handleSelectChange(e) {
    setCustomerSelect(e.target.value);
    setCodigoCliente(e.target.value);
  }

  if (loading) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  moment.locale('pt-br');
  return (
    <>
      <Grow in>
        <Grid container mt={0} alignItems={'center'} justifyContent={'center'}>
          <Grid
            xs={12}
            sm={10}
            md={10}
            lg={10}
            mt={0}
            mb={3}
            p={0}
            mr={0}
            ml={0}
            display="flex"
            flexWrap="wrap"
            component={Paper}
            sx={{ textAlign: 'center' }}
          >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              mt={0}
              mb={5}
              sx={{ textAlign: 'center' }}
            >
              <Typography variant="h4">Insira os dados</Typography>
            </Grid>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={2.5}
                sx={{ textAlign: 'center' }}
              >
                <DatePicker
                  value={dataInicio}
                  label="Data Inicial"
                  onChange={(e) => setDataInicio(e)}
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>

              <Grid
                xs={12}
                sm={12}
                md={12}
                lg={2.5}
                mr={3}
                ml={1}
                sx={{ textAlign: 'center' }}
              >
                <DatePicker
                  value={dataFim}
                  label="Data Final"
                  onChange={(e) => setDataFim(e)}
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </LocalizationProvider>
            <Grid xs={12} sm={12} md={12} lg={5} mr={1} ml={1}>
              <InputLabel shrink fullWidth>
                Selecione o Cliente
              </InputLabel>
              <NativeSelect
                value={customerSelect}
                onChange={handleSelectChange}
                loading={loading}
                fullWidth
              >
                <option key="-1" value={0}>
                  Todos os Clientes
                </option>
                {clientes.length !== 0 ? (
                  clientes.map((item, i) => {
                    return (
                      <option key={i} value={item.codigo}>
                        {item.razaoSocial}
                      </option>
                    );
                  })
                ) : (
                  <option>Sem Clientes</option>
                )}
              </NativeSelect>
            </Grid>
            <Grid xs={12} sm={12} md={12} lg={1} mr={1} ml={1}>
              <IconButton
                color="warning"
                onClick={carregaHistorico}
                size="large"
              >
                <ScreenSearchDesktopTwoToneIcon fontSize="inherit" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid
            xs={10}
            sm={10}
            md={10}
            lg={10}
            mt={1}
            mb={0}
            component={Paper}
            sx={{ textAlign: 'center' }}
            ref={componentPrint}
          >
            <Divider>
              <Typography variant="h4">Histórico de Vendas</Typography>
            </Divider>
            <Grid mb={3}>
              <IconButton onClick={handlePrint}>
                <LocalPrintshopRoundedIcon />
              </IconButton>
            </Grid>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell colSpan={1}>
                    <Typography variant="h6">Razão Social</Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography variant="h6">Descrição </Typography>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Typography variant="h6">Quantidade</Typography>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">Preço</Typography>
                  </TableCell>
                  <TableCell colSpan={3}>
                    <Typography variant="h6">Total</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              {lista.map((row, index) => (
                <Row key={index} row={row} />
              ))}
            </Table>
          </Grid>
        </Grid>
      </Grow>
    </>
  );
}

function Row(props) {
  const { row } = props;
  const emissao = moment(row.emissao).format('DD/MM/YYYY');

  return (
    <>
      <TableBody>
        <TableRow>
          <TableCell colSpan={1}>{row.razao_social}</TableCell>{' '}
          <TableCell colSpan={1}>{row.grupo_produto}</TableCell>
          <TableCell colSpan={1}>{row.qtde} </TableCell>
          <TableCell colSpan={1}>
            {row.preco.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </TableCell>
          <TableCell colSpan={3}>
            {row.total.toLocaleString('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            })}
          </TableCell>
        </TableRow>
      </TableBody>
    </>
  );
}

export default HistoricoVendas;
