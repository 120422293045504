import React from 'react';
import { useContext } from 'react';
import {
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Divider,
  Tooltip,
  Button,
  Box,
} from '@mui/material';
import { AuthContext } from '../../contexts/auth';

import DashboardIcon from '@mui/icons-material/Dashboard';
import TimelineIcon from '@mui/icons-material/Timeline';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import AssignmentTwoToneIcon from '@mui/icons-material/AssignmentTwoTone';
import ManageSearchTwoToneIcon from '@mui/icons-material/ManageSearchTwoTone';

import ProductionQuantityLimitsTwoToneIcon from '@mui/icons-material/ProductionQuantityLimitsTwoTone';

import { Link } from 'react-router-dom';

const itemsList = [
  {
    text: 'Home',
    icon: <DashboardIcon color="secondary" />,
    to: '/vendedor/principal',
    descricao: 'Acesso a página principal ',
    pos: '1',
  },
  {
    text: 'Clientes',
    icon: <PeopleAltTwoToneIcon color="secondary" />,
    to: '/vendedor/clientes',
    descricao: 'Acesso aos clientes cadastrados  ',
    pos: '2',
  },
  {
    text: 'Emissão de Pedidos',
    icon: <ManageSearchTwoToneIcon color="secondary" />,
    to: '/vendedor/consultapedido',
    descricao: 'Consulta pedidos e emissãso de pedidos  ',
    pos: '5',
  },
  {
    text: 'Pedidos em  Produção ',
    icon: <ProductionQuantityLimitsTwoToneIcon color="secondary" />,
    to: '/vendedor/meuspedidos',
    descricao: 'Acesso aos meus pedidos que estão em produção ',
    pos: '3',
  },
  {
    text: 'Orçamentos',
    icon: <AssignmentTwoToneIcon color="secondary" />,
    to: '/vendedor/orcamentos',
    descricao: 'Acesso emissão de orçamentos  ',
    pos: '4',
  },

  {
    text: 'Historico de Vendas',
    icon: <TimelineIcon color="secondary" />,
    to: '/vendedor/historicos',
    descricao: 'Acesso  aos meu historicos  ',
    pos: '7',
  },
  {
    text: 'Agenda',
    icon: <AssignmentIndIcon color="secondary" />,
    to: '/vendedor/agenda',
    descricao: 'Acesso minhas agenda ',
    pos: '8',
  },
];
function ItensListVendas() {
  const { user, signOut } = useContext(AuthContext);
  return (
    <>
      <List>
        {itemsList.map((item, index) => {
          return (
            <ListItem index={index} component={Link} to={item.to}>
              <Tooltip arrow title={item.descricao} placement="right">
                <ListItemIcon>{item.icon}</ListItemIcon>
              </Tooltip>
              <ListItemText primary={item.text} />
            </ListItem>
          );
        })}

        <Divider />

        <ListItem component={Button} onClick={() => signOut()}>
          <Tooltip arrow title="Sair " placement="right">
            <ListItemIcon>
              <ExitToAppTwoToneIcon color="secondary" />
            </ListItemIcon>
          </Tooltip>
          <ListItemText primary="Sair" />
        </ListItem>
      </List>
    </>
  );
}

export default ItensListVendas;
